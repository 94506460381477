//引入axios
import axios from 'axios'

//创建实例
const api=axios.create({
    baseURL:'https://admin.shuzijz.cn/api/index',
    timeout:3000
})
//axios拦截器
api.interceptors.request.use(config =>{
    //config 请求的信息
    return config
},err=>{
    //抛出错误信息
    return Promise.reject(err)
})

api.interceptors.response.use(res=>{
    // console.log(res)
    return res
},err=>{
     //抛出错误信息
     return Promise.reject(err)
})
export default api