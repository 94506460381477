<template>
  <div class="container" style="height: 2.8125rem;width: 100%;">
    <el-carousel trigger="click" :initial-index="index">
      <el-carousel-item  height="100%" v-for="(item,i) in swiperList" :key="i" >
        <div class="txt">
          <h3>{{ item.title }}</h3>
          <h5>{{item.subTitle}}</h5>
          <button style="width: .8333rem;height: .2188rem;border-radius:.0208rem;background-image: linear-gradient(to right, #FF6A00 ,#FF8C1F);">
          <p><span>立即咨询 </span>   <img src="../assets/index/swiper-jiantou.png" alt=""></p>
          </button>
        </div>
        <img class="swiperbg" :src="item.imgUrl"  alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {

data() {
  return {
    swiperList:[
      {
        title:'行业领先的营销传播技术服务商',
        subTitle:'助力企业传递品牌故事，快速建立品牌与关键',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper1-bg.png')
      },
      {
        title:'行业领先的营销传播技术服务商',
        subTitle:'助力企业传递品牌故事，快速建立品牌与关键',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper1-bg.png')
      },
      {
        title:'精准人群观察',
        subTitle:'提供三大核心全场景解决方案',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper3-bg.png')
      },
      {
        title:'行业领先的营销传播技术服务商',
        subTitle:'助力企业传递品牌故事，快速建立品牌与关键',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper1-bg.png')
      },
      {
        title:'根据品牌定位和营销需求',
        subTitle:'提供多种类型的专业服务支持',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper-5.png')
      },
      {
        title:'用数据和策略重新定义营销',
        subTitle:'',
        button:'立即咨询',
        imgUrl:require('../assets/index/swiper-6.png')
      }
    ],
    // currentIndex:0
  }
},
props:['index']
  
}
</script>

<style scoped>
.container{
  /* background-position:left bottom !important; */
}
/deep/ .el-carousel-item{
  /* background-image: url(../assets/index/swiper1-bg.png);
  background-size: 10rem  2.8125rem;
  background-position: left bottom; */
  position: relative;
}
/deep/  .el-carousel el-carousel--horizontal{
  height: 2.8125rem;
  background-position:left bottom !important;
}
/deep/ .el-carousel__container{
  height: 2.8125rem;
  background-position:left bottom !important;
}
.swiperbg{
  width: 100%;
  /* height: 3.1875rem; */
  position: absolute;
  left: 0;
  bottom: 0;

}
.txt{
  position: absolute;
  left: 1.875rem;
  z-index: 21;
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.txt h3{
  position: absolute;
  top: .7292rem;
  font-size: .2396rem;
  line-height: .1302rem;
  font-weight: bold;
  animation-name: swiperH3; /*执行动画的名字*/
  animation-duration: .3s; /**/
  animation-delay: .5s;  /*页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in; /*执行速率*/
  animation-iteration-count: 1; /*动画播放次数*/
  /* animation-direction: alternate; 是否轮流反复播放 */
}
.txt h5{
  position: absolute;
  font-size: .1042rem;
  line-height: .1458rem;
  font-weight: 600;
  color: #222;
  top: 1.1719rem;
  animation-name: swiperH5; /*执行动画的名字*/
  animation-duration: .6s; /**/
  animation-delay: 1s;  /*页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in; /*执行速率*/
  animation-iteration-count: 1; /*动画播放次数*/
  /* animation-direction: alternate; 是否轮流反复播放 */
}
.txt button{
  position: absolute;
  top: 1.6302rem;
  border: none;
  font-size: 0.083rem;
  color: white;

  animation-name: swiperBtn; /*执行动画的名字*/
  animation-duration: .8s; /**/
   animation-delay: 2s;/* 页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in; /*执行速率*/
  animation-iteration-count: 1; /*动画播放次数*/
  /* animation-direction: alternate; 是否轮流反复播放 */
}
@keyframes swiperBtn{
        from{
           top:2.1302rem ;
           opacity: 0.5;
        }
        to{
          top: 1.6302rem;
          /* opacity: 1; */
        }
        }
@keyframes swiperH3{
        from{
          top: .5292rem;
           opacity: 0.5;
        }
        to{
          top: .7292rem;
          /* opacity: 1; */
        }
        }
@keyframes swiperH5{
        from{
           top:2.1302rem ;
           opacity: 0.5;
        }
        to{
          top: 1.6302rem;
          /* opacity: 1; */
        }
        }
.txt button p{
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 .0521rem;
  align-items: center;
}
.txt button p img{
  width: .0833rem;
  height: .0833rem;

}
</style>