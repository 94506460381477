<template>
  <div class="dingbu" style="width: 100%;position: relative;">
    <div ref="myNav" class="myNav">
      <navView @gotosection="gotobox" :suoyin="suoyin" />
    </div>
    <!-- 轮播图 -->
    <div class="twos bannBox" :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }">
      <div class="txt">
		<div>
			<h3 class="navTitleM" v-html="bannerTitle.replace('的','的<br/>')"> </h3>
			<h3 class="navTitlePc">{{ bannerTitle }} </h3>
			<p class="h5">{{ bannerRemark }}</p>
		</div>
		<div>
			<button @click="showForm">
			<p><span>立即咨询 </span></p>
			</button>
		</div>
      </div>
      <!-- <img :src="bannerImage" style="position: absolute;left: 0;top: 0; width: 100%;height:100%;" alt="" /> -->
    </div>
    <!-- 侧边栏 -->
    <sliderView />
    <!-- 第三部分 -->
    <section style="padding-bottom: 0.4167rem">
      <div class="containerThree">
        <h4 style="color: black;font-weight: bold;">专业可靠的产品</h4>
        <!-- <h4>{{name1}}</h4> -->
        <main class="mian-three">
          <!-- <div class="block1"  v-for="(item,index) in list1" :key="index" :ref="'testerf' + index +1"   >

            <div class="left"  v-if="item.id!='2'"  :class="{ boxleftTo20: true, boxtestenterX: 'testShow'+ item.id}">
              <p class="line" ></p>

              <h3>{{item.title}}</h3> -->
          <!-- <h3>一站式影视宣发平台</h3> -->

          <!-- <p class="gray" >
                {{item.content}} -->
          <!-- 独家业内人群资源及精准推送技术，提供重点人群覆盖、院线排片推荐、票房价值驱动的一站式解决方案 -->
          <!-- </p>
              <button class="detail"  @click="gotoService(index)" >
               <p><span>查看详情 </span></p>
             </button>
             </div>
            <div class="right"  v-if="item.id !='2'"  :class="{ boxtranslateXright: true, boxtestenterX: 'testShow'+ item.id}">
              <img    src="../assets/index/three-2.png" style="" alt="" />
            </div>
            <div class="rightTwo" v-if="item.id=='2'"  :class="{ boxleftTo20: true, boxtestenterX: 'testShow'+ item.id }">
              <img
                src="../assets/index/three-2.png"
                class="imgRight"
                style="position: absolute; left: -35rpx"
                alt=""
              />
            </div>
            <div class="left"   v-if="item.id=='2'"  :class="{ boxtranslateXright: true, boxtestenterX: 'testShow'+ item.id}">
              <p class="line"></p>

              <h3>{{item.title}}</h3>

              <p class="gray" style="">
                {{item.content}}
              </p>
              <button class="detail"  @click="gotoService(index)" >
               <p><span>查看详情 </span></p>
             </button>
            </div>
          </div> -->
          <!-- <div class="block1"  ref="testref1">
                <div class="left"  :class="{ boxleftTo20: true, boxtestenterX: testShow1 }">
                  <p class="line"></p>

                  <h3 >精准PR整合传播</h3>

                  <p class="gray">
                  提供多种类型的专业服务支持
                  </p>
                  <button class="detail"  @click="gotoService(0)" >
                  <p><span>查看详情 </span></p>
                </button>
                </div>
                <div class="right" :class="{ boxtranslateXright: true, boxtestenterX: testShow1 }">
                  <img src="../assets/index/three-3.png" alt="" />
                </div>
              </div> -->
          <div class="block1" ref="testref1">
            <div class="left" :class="{ boxleftTo20: true, boxtestenterX: testShow1 }">
              <p class="line"></p>

              <h3 style="color: black;font-weight: bold;">{{ list1[0].title }}</h3>

              <p class="gray">
                {{ list1[0].content }}
              </p>
              <!-- @click="gotoService(0)" -->
              <button class="detail" @click="gotoService(0)">
                <p><span>查看详情 </span></p>
              </button>
            </div>
            <div class="right" :class="{ boxtranslateXright: true, boxtestenterX: testShow1 }">
              <img :src="list1[0].image" alt="" />
            </div>
          </div>

          <!-- 第二块 -->
          <!-- <div class="block1"  ref="testref2" >
            <div class="rightTwo" :class="{ boxleftTo20: true, boxtestenterX: testShow2 }">
              <img
                src="../assets/index/three-2.png"
                class="imgRight"
                style="position: absolute; left: -35rpx"
                alt=""
              />
            </div>
            <div class="left"  :class="{ boxtranslateXright: true, boxtestenterX: testShow2 }" >
              <p class="line"></p>

              <h3>一站式影视宣发平台</h3>

              <p class="gray">
               独家业内人群资源及精准推送技术，提供重点人群覆盖、院线排片推荐、票房价值驱动的一站式解决方案
              </p>
              <button class="detail"  @click="gotoService(1)" >
               <p><span>查看详情 </span></p>
             </button>
            </div>
          </div> -->
          <div class="block1" ref="testref2">
            <div class="rightTwo pc" :class="{ boxleftTo20: true, boxtestenterX: testShow2 }">
              <img :src="list1[1].image" class="imgRight" style="position: absolute; left: -35rpx" alt="" />
            </div>
            <div class="left" :class="{ boxtranslateXright: true, boxtestenterX: testShow2 }">
              <p class="line"></p>

              <h3 style="color: black;font-weight: bold;">{{ list1[1].title }}</h3>

              <p class="gray">
                {{ list1[1].content }}
              </p>
              <button class="detail" @click="gotoService(1)">
                <p><span>查看详情 </span></p>
              </button>
            </div>
            <div class="right mobile" :class="{ boxtranslateXright: true, boxtestenterX: testShow1 }">
              <img :src="list1[1].image"   alt="" />
            </div>
          </div>
          <!-- 第三块  -->
          <!-- <div class="block1" ref="testref3">
            <div class="left"  :class="{ boxleftTo20: true, boxtestenterX: testShow3 }"  >
              <p class="line"></p>

              <h3 >AI智能广告投放平台</h3>

              <p class="gray">
               以真实设备为基础构建的DMP人群库，配合独立研发的程序化广告平台，助力广告效果达成
              </p>
              <button class="detail"  @click="gotoService(2)" >
               <p><span>查看详情 </span></p>
             </button>
            </div>
            <div class="right"   :class="{ boxtranslateXright: true, boxtestenterX: testShow3 }"  >
              <img src="../assets/index/three-3.png" alt="" />
            </div>
          </div> -->
          <div class="block1" ref="testref3">
            <div class="left" :class="{ boxleftTo20: true, boxtestenterX: testShow3 }">
              <p class="line"></p>

              <h3 style="color: black;font-weight: bold;">{{ list1[2].title }}</h3>

              <p class="gray">
                {{ list1[2].content }}
              </p>
              <button class="detail" @click="gotoService(2)">
                <p><span>查看详情 </span></p>
              </button>
            </div>
            <div class="right" :class="{ boxtranslateXright: true, boxtestenterX: testShow3 }">
              <img :src="list1[2].image" alt="" />
            </div>
          </div>
        </main>
      </div>
    </section>
    <!-- 第四部分 -->
    <section class="sectionFour">
      <div class="containerFour">
        <h3 style="color: black;font-weight: bold;">{{ name2 }}</h3>
        <div class="content pc">

          <el-carousel :autoplay="false" arrow="never" ref="carousel" indicator-position="none" class="swiperFour"
            @change="changeSwiperFour">
            <el-carousel-item class="el-car-item">

              <div class="box1" v-for="(item, i) in list2" :key="i" :class="boxActive == i ? 'box1hover' : ''"
                @mouseleave="removeAnimation" @mouseenter="changebgColor(i)"
                style="padding: .1458rem .1458rem 0;box-sizing: border-box;cursor: pointer;position: relative;">
                <h5 style="font-size:.1042rem;line-height: .1563rem;margin: 0;
                   margin-bottom: .125rem;">{{ item.title }}</h5>
                <p style="font-size: .0833rem;margin: 0;
                  text-overflow: ellipsis;overflow: hidden;white-space: wrap;">{{ item.content }}</p>

              </div>

            </el-carousel-item>
          </el-carousel>
          <button class="consult" @mouseenter="changeConsultBtn" @click="gotosence"
            :style="consultStyleActive ? consultStyle : ''" @mouseleave="changeConsultBtnLeave">
            <p>
              <span :style="consultStyleActive ? 'color:white' : 'color:#ff7300;'">查看更多案例 </span>

            </p>
          </button>
          <div class="sjBox">
            <div class="sjk" :class="issjkhover ? 'sjkhover' : ''">
              <div class="sjk-box">

                <el-carousel class="sj-swiper" autoplay :interval="interval" arrow="never" indicator-position="none">
                  <el-carousel-item v-for="item in list2[boxActive].image" :key="item">
                    <img :src="item" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
          </div>
        </div>

        <div class="content mobile" style="padding-left: 0;">

          <el-carousel :autoplay="false" arrow="never" ref="carousel" indicator-position="none" class="swiperFour"
                       @change="changeSwiperFour">
            <el-carousel-item class="el-car-item">

              <div class="box1" v-for="(item, i) in list2" :key="i" :class="boxActive == i ? 'box1hover' : ''"
                   @mouseleave="removeAnimation" @mouseenter="changebgColor(i)"
                   style="padding: .1458rem .1458rem 0;box-sizing: border-box;cursor: pointer;position: relative;">
                <h5 style="font-size:12px;line-height:30px;margin: 0;
                   margin-bottom: .125rem;text-align: center">{{ item.title }}</h5>
                <p style="font-size: 12px;margin: 0;
                  text-overflow: ellipsis;overflow: hidden;white-space: wrap;">{{ item.content }}</p>

              </div>

            </el-carousel-item>
          </el-carousel>
          <button class="consult" @mouseenter="changeConsultBtn" @click="gotosence"
                  :style="consultStyleActive ? consultStyle : ''" @mouseleave="changeConsultBtnLeave">
            <p>
              <span :style="consultStyleActive ? 'color:white' : 'color:#ff7300;'">查看更多案例 </span>

            </p>
          </button>
          <div class="sjBox">
            <div class="sjk" :class="issjkhover ? 'sjkhover' : ''">
              <div class="sjk-box">

                <el-carousel class="sj-swiper" autoplay :interval="interval" arrow="never" indicator-position="none">
                  <el-carousel-item v-for="item in list2[boxActive].image" :key="item">
                    <img :src="item" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 第五部分 -->
    <section>

      <div class="containerFive">

        <p class="title" style="font-weight: bold;color: black;font-weight: bold;">{{ name3 }}</p>
        <div class="content">

          <div class="left">
            <img :src="img3" alt="">
            <!-- <img src="../assets/index/five-r.png" alt=""> -->
          </div>
          <ul class="right" style="width:3.724rem;height: 100%;">
            <li style="margin-bottom: .3125rem;" v-for="(a, i) in list3" :key="i">
              <div class="r-l-img">
                <img :src="a.image" alt="">
              </div>
              <div class="r-r-txt">
                <p class="h6">{{ a.title }}</p>
                <p style="width: 3.724rem;">{{ a.content }}</p>
              </div>
            </li>
            <!-- <li style="margin-top: .3125rem;">
         <div class="r-l-img">
          <img src="../assets//index/five-right-2.png" alt="">
         </div>
         <div class="r-r-txt">
          <p class="h6">技术可靠</p>
          <p style="width: 3.424rem;">9年+设备数据与技术沉淀，每日亿级数据高并发处理经验，确保产品稳定可靠</p>
         </div>
         </li> -->
            <!-- <li style="margin-top: .3125rem;">
         <div class="r-l-img">
          <img src="../assets//index/five-right-2.png" alt="">
         </div>
         <div class="r-r-txt">
          <p class="h6">产品成熟</p>
          <p style="width: 3.424rem;">多年全行业产品化服务能力，可为不同需求、不同领域的客户提供专业的解决方案</p>
         </div>
         </li> -->
            <!-- <li style="margin-top: .3125rem;">
         <div class="r-l-img">
          <img src="../assets/index/five-right-3.png" alt="">
         </div>
         <div class="r-r-txt">
          <p class="h6">专业服务</p>
          <p style="width: 3.224rem;">国内首批引入chatgpt、midjourney工具提供客户服务的科技公司，搭配专业运营人员，提供完善的全流程服务</p>
         </div>
         </li> -->
          </ul>
        </div>
      </div>
    </section>
    <!-- 第六部分 -->
    <section class="sectionSix">

      <div class="containerSix">
        <p class="title" style="font-weight: bold;color:black;">众多企业信赖之选</p>
        <div id="content" style="">
          <img src="../assets/index/zzc.png"
            style="position: absolute;z-index: 99; top: 0;left: 0;width: 100%;height: 100%;" alt="">
          <div class="box">

            <waterfallView :imageData="list4" />
            <waterfallView :imageData="list4" />
            <waterfallView :imageData="list4" />
            <waterfallView :imageData="list4" />
            <waterfallView :imageData="list4" />
          </div>

        </div>/



      </div>
    </section>
    <!-- 第七部分 -->
    <section>
      <div class="containerSeven">
        <div class="content">
          <p class="title">联系我们</p>
          <p class="txt pc">留下您的联系方式，您的专属顾问会在1个工作日内联系您</p>

          <el-form :inline="true" class="demo-form-inline " :model="ruleForm" :rules="rules" ref="ruleFormRef">
            <div class="pc" style="width: 94%;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="name" required>
                <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
              </el-form-item>
              <el-form-item label=" " prop="mobile" required>
                <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
              </el-form-item>
            </div>


              <div class="mobile" style="width: 94%;margin: 0 auto; margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="name" required>
                <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
              </el-form-item>
              </div>
              <div class="mobile" style="width: 94%;margin: 0 auto;margin-bottom: .0521rem;">
              <el-form-item label=" " prop="mobile" required>
                <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
              </el-form-item>
            </div>
            <div class="pc"
              style="width: 94%;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="company" required>
                <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item label=" " prop="zhiwei" style="margin-left: .0821rem;">
                <el-input class="ipt" v-model="ruleForm.zhiwei" placeholder="职位"></el-input>
              </el-form-item>
            </div>

              <div
                    class="mobile"  style="width: 94%;margin: 0 auto; ;margin-bottom: .0521rem;">
                  <el-form-item class="formItem" label=" " prop="company" required>
                      <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
                  </el-form-item>
              </div>
              <div
                      class="mobile"  style="width: 94%;margin: 0 auto; ;margin-bottom: .0521rem;">
                  <el-form-item label=" " prop="zhiwei" >
                      <el-input class="ipt" v-model="ruleForm.zhiwei" placeholder="职位"></el-input>
                  </el-form-item>
              </div>
            <div class="formItem-last-box mobile" style="height: 150px;width: 94%;margin: 0 auto;box-sizing: border-box;padding-left: 20px;">
              <el-form-item class="formItem-last" style="position: relative;width: calc(100% - 3px) !important;">
                <el-input type="textarea" class="textArea" style="font-size: 12px !important;padding-left: 10px;box-sizing: border-box"  rows="5" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                  v-model="ruleForm.remark">
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
            <div class="formItem-last-box pc">
              <el-form-item class="formItem-last" style="position: relative;">
                <el-input type="textarea" class="textArea" rows="5" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                  v-model="ruleForm.remark">
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
          </el-form>
          <button @click="submitFormModal('ruleFormRef')">提交</button>
        </div>
      </div>
    </section>
    <!--  第八部分-->
    <bottomView @gotosection="gotobox" />
    <!-- 立即咨询表单 -->
    <formView />

  </div>
</template>

<script>
import waterfallView from '@/components/waterfall.vue'
import { mapState, mapMutations, mapActions } from "vuex";
import { submitForm } from '@/api/form'
import { homes } from '@/api/home'
import bottomView from "../components/bottom.vue";
import navView from "../components/nav.vue";
import swiperView from "../components/swiper.vue";
import formView from '../components/form.vue'
import sliderView from "../components/slider.vue";
import axios from 'axios';
export default {
  data() {
    /**
   * 校验手机号
   */
    const validatePhone = (rule, value, callback) => {
      const regExp = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (!regExp.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }


    return {
      ruleFormRef: {},
      imageData: [
        {
          title: 'image-1',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-2',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-3',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-4',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-5',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-6',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-7',
          imgUrl: require('../assets/index/swiper-6.png')
        },
        {
          title: 'image-8',
          imgUrl: require('../assets/index/swiper-6.png')
        }
      ],
      homeList: [],
      interval: 2000,
      index: 0,
      dataList: [
        {
          index: 0,
          title: '影视宣发',
          img: 'https://img0.baidu.com/it/u=1192667473,1952693205&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200',
          txt: '与全国百家发行公司深度合作，联动全量影迷 数据，提供专业的影视行业数字化营销与互动策略'
        },
        {
          index: 1,
          title: 'TO B营销',
          img: 'https://nimg.ws.126.net/?url=http%3A%2F%2Fdingyue.ws.126.net%2F2023%2F0612%2Fcdc07c25j00rw4v5w0004d0005e0038p.jpg&thumbnail=660x2147483647&quality=80&type=jpg',
          txt: '以ABM营销为核心，依赖于企业人群数据库指导To B领域品牌宣传'
        },
        {
          index: 2,
          title: 'To C营销',
          img: 'https://img.duoziwang.com/2019/06/09221645710024.jpg',
          txt: '科学的用户洞察策略，根据实时数据反馈与多维筛选对比，满足不同行业的营销需求'
        }
      ],
      ruleForm: {
        name: '',
        mobile: '',
        company: '',
        zhiwei: '',
        remark: ''
      },
      formRef: {},
      rules: {
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },

        ],
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      },
      boxActive: 0,
      swiperFourActive: 0,
      btnActive: '',
      consultStyle: {
        "background": '#ff7300',

      },
      consultStyleActive: 0,
      btnUrl: require('../assets/index/swiper-jiantou.png'),
      btnUrlActive: require('../assets/index/swiper-jiantou-white.png'),

      index: 0,
      current: 1,
      currentScroll: 0,
      testShow1: false,
      testShow2: false,
      testShow3: false,
      testref1: false,
      testref2: false,
      testref3: false,
      shows: false,
      issjkhover: true,
      suoyin: 0,
      name1: '',
      list1: [],
      name2: '',
      list2: [],
      name3: '',
      img3: '',
      list3: [],
      name4: '',
      list4: [],
      bannerTitle: "",
      bannerRemark: '',
      bannerImage: ''
    };
  },
  watch: {
    'ruleForm.mobile': {
      handler(newVal) {
        if (!newVal) {
          this.ruleForm.mobile = ''
          return false
        }
        // 实时把非数字的输入过滤掉
        this.ruleForm.mobile = newVal.match(/\d/gi) ? newVal.match(/\d/gi).join('') : ''
      },
      deep: true,
    },



  },
  components: {
    bottomView,
    navView,
    swiperView,
    formView,
    sliderView,
    waterfallView
  },
  computed: {
    ...mapState(['navList'])
  },
  created() {
    this.getHomeList()//获取首页数据
    this.getBannerList()
    this.suoyin = sessionStorage.getItem('i')

    this.getDataSource();
    this.index = this.$route.params.index

  },

  methods: {
    gotojingzhun() {
      // location.href('#jingzhun')
    },
    ...mapActions(['showDialog']),
    showForm() {
      this.showDialog(true)
    },
    getBannerList() {
      axios.get('https://admin.shuzijz.cn/api/index/banner?id=1').then(res => {
        console.log(res, 'banner的数据');
        this.bannerTitle = res.data.data.title
        this.bannerRemark = res.data.data.remark
        this.bannerImage = res.data.data.image

      })
    },
    submitFormModal(ruleFormRef) {
      this.$refs[ruleFormRef].validate(async (valid) => {
        if (valid) {
          // var params = new URLSearchParams();
          // params.append('name', this.ruleForm.name);
          // params.append('mobile', this.ruleForm.mobile);
          // params.append('company', this.ruleForm.company);
          // params.append('position', this.ruleForm.zhiwei);
          // params.append('remark', this.ruleForm.remark);
          //https://www.shuzijz.cn/api/edm/v1/sender/template

          var params = {
            options: {
              name: this.ruleForm.name,
              mobile: this.ruleForm.mobile,
              company: this.ruleForm.company,
              province: "",
              position: this.ruleForm.zhiwei,
              remark: this.ruleForm.remark
            },
            key: 'szjz_website_leads'
          }
          axios.post('https://www.shuzijz.cn/api/edm/v1/sender/template', params)
            // axios({
            //   method: "post",
            //   url: 'https://www.shuzijz.cn/api/edm/v1/sender/template',
            //   data: params
            // })
            .then(res => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                });
              }
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.$refs.ruleFormRef.resetFields()
              }
            }).catch(err => {
              console.log(err, 'err')
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    async getHomeList() {
      const { data: res } = await homes()
      console.log(res, 'homeList');

      if (res.code == 1) {
        this.homeList = res.data.content
        this.name1 = res.data.content[0].name
        this.list1 = res.data.content[0].main
        this.name2 = res.data.content[1].name
        this.list2 = res.data.content[1].main
        this.name3 = res.data.content[2].name
        this.img3 = res.data.content[2].main[0].image
        this.list3 = res.data.content[2].main.filter((item, index) => index > 0)
        this.name4 = res.data.content[3].name
        this.list4 = res.data.content[3].main[0].image
        console.log(this.list4, 'list4')
      }

    },
    gotobox(i, b, index) {
      // console.log(i,b,index,'index页的i,b,index');
      var s = document.getElementsByTagName('section')
      // console.log(i,'iii');
      if (this.$route.name == b) {
        var top = s[i].offsetTop - this.$refs.myNav.offsetHeight;
        window.scrollTo({
          top: top,
          behavior: "smooth"
        });
        // s[i].scrollIntoView({ behavior: "smooth", block: "start", });
      } else {
        sessionStorage.setItem('i', index)
        this.$router.push({
          name: b,
          params: { i: i }
        })

        // console.log(i,b,index,'i,b,index');
      }

    },
    getDataSource() {
      let newDataList = [];
      let current = 0;
      if (this.dataList && this.dataList.length > 0) {
        // if(this.dataList ){
        for (let i = 0; i <= this.dataList.length - 1; i++) {
          if (i % 3 !== 0 || i === 0) {
            if (!newDataList[current]) {
              newDataList.push([this.dataList[i]]);
            } else {
              newDataList[current].push(this.dataList[i]);
            }
          } else {
            current++;
            newDataList.push([this.dataList[i]]);
          }
        }
      }
      this.dataList = [...newDataList];
      // console.log(this.dataList);
    },
    // change(type) {
    //   // console.log(type);
    //   this.$refs["carousel"][type]();
    //   this.issjkhover=true
    // },
    changebgColor(e) {//鼠标进入box
      this.boxActive = e
      this.issjkhover = true
      // console.log(this.list2);
    },
    removeAnimation() {//鼠标移出box
      this.issjkhover = false
    },
    changeSwiperFour(e) {
      // console.log(e);
      this.swiperFourActive = e
      this.boxActive = 0
    },
    //鼠标进入左侧box
    pervNextEnter() {
      this.btnActive = 'background:rgba(0,0,0,.1);color:white!important;'
    },
    // 鼠标移开左侧box
    pervNextmove() {
      this.btnActive = 'background-color: #ccc!important;opacity:0.2;color:white !important;'
    },
    changeConsultBtn() {//进入到立即咨询按钮

      this.consultStyleActive = 1
    },
    changeConsultBtnLeave() {//离开立即咨询按钮
      this.consultStyleActive = 0
    },
    handleScrollbox() {//左右出场动画

      this.currentScroll = window.pageYOffset //表示当前滚动的位置
      this.$nextTick(() => {
        if (this.$refs.testref1) {
          if (this.currentScroll >= this.$refs.testref1.offsetTop - 1240) {
            //当前滚动位置到达testref的时候，显示div（100作为调整用）

            this.testShow1 = true
          }

          if (this.currentScroll >= this.$refs.testref2.offsetTop - 740) {

            this.testShow2 = true
          }
          if (this.currentScroll >= this.$refs.testref3.offsetTop - 740) {

            this.testShow3 = true
          }
        }

      })
    },

    gotoService(i) {//切换到服务营销
      sessionStorage.setItem('i', 1)
      this.$router.push({
        name: 'service',
        params: { i: i }
      })
    },
    gotosence() {//跳转至应用场景
      sessionStorage.setItem('i', 2)
      this.$router.push({
        name: 'sence',
      })
    },
    showTxt(t) {//点击表单里面的选项
      this.ruleForm.remark += t
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.handleScrollbox();
      }, 500);
    })

    window.addEventListener('scroll', this.handleScrollbox, true)
    if (this.$route.params.i) {
      var s = document.getElementsByTagName('section')
      // console.log(s,'sss');
      let m = this.$route.params.i
      setTimeout(() => {
        s[m].scrollIntoView({ behavior: "smooth", block: "start", });
      }, 500);
    }

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollbox, true)
  },
};
</script>

<style scoped>
@media screen and (max-width: 1200px) {
  .h5 {
    font-size: 15.1rem;
  }
}

@media screen and (max-width: 900px) {
  .containerThree{
        margin-left: 0.95rem !important;
      }
  .navTitleM{
    display: block;
  }
  .navTitlePc{
    display:none;
  }
  .myNav {
    background-color: #fff;
    height: auto !important;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
  }
  .mobile{
    display: block !important;
  }

  .el-textarea /deep/ .el-textarea__inner {
    font-size: 12px !important;
    width: 95% !important;
  }
  .pc{
    display: none !important;
  }
  .sjBox{
    display: none;
  }

}

@media screen and (min-width: 900px) {
  .navTitleM{
    display: none;
  }
  .navTitlePc{
    display:block;
  }

  .myNav {
    background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
  }
  .mobile{
    display: none !important;
  }
  .pc{
    display: block;
  }
  .sjBox{
    display: block;
  }
}

#TestContainer {
  margin: 0 auto;
  width: 7.2917rem;
  background-color: pink;
  /* //添加上多列布局 */
  column-fill: balance;
  /* column-count: 1; */
  column-gap: 1;
  z-index: 99;
  column-span: all;
  height: 1.5625rem;
}

#TestContainer .box img {
  width: 100%;
}

/* 瀑布流 */
.div {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 5px;


}

.div::after {
  content: "";
  flex-grow: 99999;
}

.img-wrapper {
  flex-grow: 1;
  margin: 5px;
  position: relative;
  overflow: hidden;
  height: 210px;
}

.img {
  object-fit: cover;
  background-size: cover;
  background-position: center;

}

.img img {
  opacity: 0;
  min-width: 100%;
  height: 210px;
}

.info {
  position: absolute;
  bottom: 0px;
  color: #ffffff;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 44px;
  height: 0px;
}

.div:hover .info {
  height: 44px;
  transition: all 0.5s;
}

/*  */
* {
  font-size: 0;
  margin: 0;
  padding: 0;
  /* font-size: 0; */
}

/* 第二部分 */
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items:stretch;
  left: 1.875rem;
  position: absolute;
}

.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: .2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: .1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: .3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: .078125rem
    /* 15/192 */
  ;
}

.bannBox .txt button {
  outline: none;
  /* position: absolute; */
  border: 1px solid #FF6A00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius: 5px;
  padding: 0.052083rem
    /* 10/192 */
    0.1875rem
    /* 36/192 */
  ;
  margin-top: .3125rem;
}

.detail {
  border: 1px solid #FF6A00;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  margin-top: .1542rem;
  /* width: .8333rem;
  height: .2188rem; */
  border-radius: .0208rem;
  padding: .052083rem
    /* 10/192 */
    .1875rem
    /* 36/192 */
  ;
}

.detail p {
  margin: 0;
  padding: 0 .0521rem;
  font-size: .0833rem;
}

.detail p span {
  color: #FF6A00 !important;
  font-size: .0833rem;
}

.detail:hover {
  color: white;
  background-image: linear-gradient(to right, #FF6A00, #FF8C1F);
}

.detail:hover span {
  color: white !important;
}

.bannBox .txt button:hover {
  color: white;
  background-image: linear-gradient(to right, #FF6A00, #FF8C1F);
}

.bannBox .txt button p {
  margin: 0;
  padding: 0 .0521rem;
  font-size: .0833rem;
}

.bannBox .txt button p span {
  font-size: .0833rem;
}

/* 立即咨询按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    ;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }

}

@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    ;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: .9292rem;
    opacity: 0.5;
  }

  to {
    top: .7292rem;
    opacity: 1;
  }
}

@keyframes swiperH3 {
  from {
    top: .9292rem;
    opacity: 0.5;
  }

  to {
    top: .7292rem;
    opacity: 1;
  }
}

/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    ;
    opacity: 1;
  }
}

@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    ;
    opacity: 1;
  }
}

/* 第三部分 */
.containerThree {
  padding-top: 0.4167rem;
}

.containerThree h4 {
  font-size: 0.2083rem;
  line-height: 0.2917rem;
}

.containerThree .mian-three {
  width: 7.2917rem;
  margin: 0.3125rem auto 0;
}

.mian-three .block1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.3438rem;
  text-align: left;
  /* border: 1px solid red; */
  margin-top: 0.4167rem;
  font-size: .0781rem;
}

.block1 .left {
  width: 3.1875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line {
  margin-bottom: 0.125rem;
  width: 0.3125rem;
  height: 0.0313rem;
  border-radius: 0.0156rem;
  background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
}

.left h3 {
  margin-bottom: 0.125rem;
  font-size: 0.1667rem;
  line-height: 0.2344rem;
  color: #222;
  font-weight: bold;
  /* transition: all 1s linear; */
  cursor: pointer;
}

/* .mian-three .block1 .left h3:hover{
  font-size: .1979rem;
  background-color: #eee;

} */
.left .gray {
  color: #666666;
  font-size: 0.0938rem;
  /* line-height: 0.1458rem; */
  width: 80%;
}

.right {
  width: 3.9417rem;
  height: 2.6438rem;
  position: relative;
}

.right img {
  width: 100%;
  position: absolute;
  right: -5px;
}

.rightTwo {
  width: 3.9417rem;
  height: 2.6438rem;
  position: relative;
}

.rightTwo .imgRight {
  left: -0.2823rem;
  width: 100%;
  height: 100%;
}

/* 第四部分 */
.sectionFour {
  background-image: url(../assets/index/four-bg.png);
  /* height: 3.5938rem; */
  /* width: 10rem; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 3.5938rem;
  /* padding: 0.4167rem 1.875rem 0.276rem; */
  box-sizing: border-box;
  padding-top: .4167rem;
}

.containerFour {
  width: 7.2917rem;
  height: 2.3854rem;
  margin: 0 auto;
  text-align: left;
  /* position: relative; */
}

.containerFour h3 {
  font-size: 0.2083rem;
  /* line-height: 0.2917rem; */
  margin: 0;
  text-align: center;
}

.containerFour .content {
  /* width: 7.2917rem;
  height: 2.7854rem; */
  box-sizing: border-box;
  margin-top: 0.224rem;
  padding: 0.3281rem 0 0.6979rem 0.2604rem;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  position: relative;
}

.swiperFour {
  box-sizing: border-box;
  width: 4.99rem;
  height: 1.7854rem;
  overflow: hidden;
  position: relative;
  /* padding: .3281rem 0 .2604rem .6979rem; */
}

.swiperFour .box1 {
  border-radius: 0.0625rem;
  display: inline-block;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  backdrop-filter: blur(2px);
  /* width: 1.3125rem;
  height: 1.4115rem; */
  margin: 0 0.0521rem;
  padding: .145833rem .145833rem 0rem .145833rem !important;
}

.swiperFour .box1hover {
  color: white;
  background-image: linear-gradient(to right, #ed6d00, #f39800);
}

.sjBox {
  width: 2rem;
  height: 2.9rem;
  padding: 0.1615rem 0.7344rem 0.3646rem 0.3698rem;
  box-sizing: border-box;
  position: absolute;
  left: 5.3083rem;
  top: -0.2304rem;
  /* opacity: 0.45; */
}

.sjk {
  background-image: url(../assets/sence/sjk.png);
  height: 2.6563rem;
  width: 1.7385rem;

  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0.2rem 0;
  position: absolute;
  top: 0;
  left: 0;

}

.sjkhover {
  opacity: 0;
  animation: topMove 1.2s ease-out 0s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes topMove {
  from {
    transform: translateY(10%);
    opacity: 0.1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes topMove {
  from {
    transform: translateY(10%);
    opacity: 0.1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.sjk .sjk-box {
  position: absolute;
  top: 0.08rem;
  left: 0.24rem;
  width: 1.15rem;
  height: 2.33rem;
  border-radius: 0.14rem;
  overflow: hidden;
}

.sjk .sjk-box img {
  width: 100%;
  height: 100%;
}

.sjk .sjk-box .sj-swiper {
  /* background-color: red; */
  width: 1.15rem;
  height: 2.33rem;
}

.sjk .sjk-box /deep/ .el-carousel__item {
  width: 1.15rem;
  background-color: blue;
  height: 2.33rem;
}

.sjk .sjk-box /deep/ .el-carousel__container {
  width: 1.15rem;
  height: 2.33rem;
}

.consult {
  position: absolute;
  left: 0.3104rem;
  bottom: 0.4969rem;
  border: 1px solid #ff7300;
  color: #ff7300;
  font-size: 0.0833rem;
  /* width: 0.8333rem;
  height: 0.2188rem; */
  padding: .052083rem
    /* 10/192 */
    .1875rem
    /* 36/192 */
  ;
  border-radius: 0.0208rem;
  background-color: transparent;
  /* background-image: linear-gradient(to right, #ff6a00, #ff8c1f); */
}

.consult p {
  margin: 0;
  color: #ff7300;
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0 0.0521rem;
  /* align-items: center; */
}

.consult p span {
  color: #ff7300;
  font-size: .0833rem;
}

.consult p img {
  width: 0.0833rem;
  height: 0.0833rem;
}

.sectionFour /deep/ .el-carousel__container {
  height: 1.4375rem;
  width: 100%;
}

.prev {
  position: absolute;
  left: 0.15rem;
  z-index: 23;
  width: .2292rem;
  height: .2292rem;
  top: 0.7604rem;
  border-radius: 50%;

  width: 0.2604rem;
  text-align: center;
  background-color: #eee;
  border: none;

  color: white !important;
  z-index: 29;
}

.iconbtn {
  font-size: .0833rem;
  width: .0725rem;
  height: .0725rem;
  line-height: .2292rem;
  color: white !important;
}

.next {
  position: absolute;
  left: 4.02rem;
  top: 0.7604rem;
  width: .2292rem;
  height: .2292rem;
  border-radius: 50%;
  z-index: 23;
  width: 0.2604rem;
  text-align: center;
  border: none;
  background-color: #eee;
}

/* 第五部分 */
.containerFive {
  padding-top: .3958rem;

}

.containerFive>.title {
  font-size: .2083rem;
  line-height: .2917rem;
  margin: 0;
  font-weight: 500;
  color: #222;
}

.containerFive .content {
  margin: 0 auto;
  margin-top: .25rem;
  width: 7.2917rem;
  /* height: 2.3229rem; */
  display: flex;

  justify-content: space-between;
  align-items: center;

}

.containerFive .content .left {
  width: 3.2135rem;
  height: 2.3229rem;
}

.containerFive .content .left img {
  width: 100%;
  height: 100%;
}

.containerFive .content .right {
  padding: .1563rem 0 0;
  box-sizing: border-box;
}

.containerFive .content .right li {
  height: .4375rem;
  /* background-color: red; */
  display: flex;
  align-items: flex-start;
}

.containerFive .content .right li .r-l-img {
  width: .1667rem;
  height: .1667rem;
  position: relative;
  margin-right: .0833rem;
}

.containerFive .content .right li .r-l-img img {
  width: .1667rem;
  height: .1667rem;
  position: absolute;
  left: 0;
  top: 0;
  /* width: 100%;
  height: 100%; */
}

.containerFive .content .right .r-r-txt {
  display: flex;
  flex-direction: column;
  width: 2.974rem;
  align-items: flex-start;
  justify-content: space-around;
}

.containerFive .content .right .r-r-txt .h6 {
  margin: 0;
  font-size: .125rem;
  line-height: .1198rem;
  color: #222;
  font-weight: 500;
  margin-bottom: .0365rem;
}

.containerFive .content .right .r-r-txt p {
  margin: 0;
  font-size: .0833rem;
  /* line-height: .1146rem; */
  color: #666;
  text-align: left;
}

ul {
  list-style: none;
}

/* 第六部分 */
.sectionSix {
 background-image: url(../assets/index/six-bg.png);
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: .3958rem;
  box-sizing: border-box;
  height: 3.9365rem;
  /* border: 1px solid red; */
}

.containerSix {
  position: relative;
}

.containerSix .title {
  font-size: .2083rem;
  line-height: .2917rem;
  color: #222;
  margin-bottom: .4427rem;
}

.containerSix #content {
  width: 7.2917rem;
  height: 2.5844rem;
  /* box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12); */
  border-radius: 4px;
  border: none;
  overflow: hidden;
  position: absolute;
  top: 0.6302rem;
  /* left: 1.8854rem; */
  left: 50%;
  transform: translateX(-50%);
  bottom: .4219rem;
  right: auto;
  border-radius: 1px;

}

.containerSix #content .box {
  display: flex;
  animation: 35s move infinite linear;
  position: absolute;
  height: 2rem;
}

.containerSix #content .box img {
  height: 1.9844rem;
  float: left;
}

@-webkit-keyframes move {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes move {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

/* 第七部分 */
.containerSeven {
  background-image: url(../assets/index/seven-bg.png);
  /* height: 3.0313rem; */
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  padding: .2083rem 2.3958rem;
  box-sizing: border-box;
}

.containerSeven .content {
  width: 5.2083rem;
  padding-top: .2083rem;
  /* width: 1001px; */
  /* height:2.6042rem; */
  background: #FFFFFF;

  z-index: 23;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  border-radius: .0417rem;
  position: relative;
  border: 1px solid rgba(212, 214, 218, 0.5);
}

.containerSeven .title {
  color: #222;
  font-size: .1458rem;
  line-height: .2083rem;
  margin-bottom: .0833rem;
}

.containerSeven .txt {
  color: #999;

  font-size: .0833rem;
  line-height: .1146rem;
  margin-bottom: .2083rem;
}

.containerSeven /deep/ .el-form-item__content {
  line-height: .2083rem;
  font-size: .0729rem;
}

.containerSeven /deep/ .el-input__inner {
  width: 2.3125rem;
  height: .2292rem;
  font-size: .0929rem;
}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0 !important;
}

/deep/ .textArea {
  width: 4.7917rem;
  font-size: .0729rem;
  position: relative !important;
  padding-bottom: 0.2rem;
  /* border: 1px solid red; */
  /* height: 0.56rem; */
}

/deep/ .ipt {
  width: 2.3125rem;
  height: .2292rem;

}

/deep/ .el-form-item__label {
  padding: 0 !important;

}

/deep/ .el-form-item {
  margin-bottom: .1146rem;
  /* width: 2.4716rem ; */
}

/deep/ .el-form-item__label {
  line-height: .2083rem !important;
}

/deep/ [data-v-64762e48] .el-form-item__label {
  line-height: 0.29rem !important;
}

/deep/ .el-form--inline .el-form-item__label {
  line-height: 0.29rem;
}

/deep/ .formItem {

  display: inline-block;
}

/deep/ [data-v-64762e48] .el-form-item {
  margin-left: 0.05rem !important;
}

.tips {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0.01rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 0.2rem;
  box-sizing: border-box;
}

.tips span {
  height: .0929rem;
  line-height: .0969rem;
  background-color: #bfbfbf;
  border-radius: .0521rem;
  color: white;
  padding: .0104rem .0417rem;
  font-size: .0625rem;
  margin: 0.02rem 0.05rem;
}

/deep/ .formItem-last {
  width: 94%;
  /* width: 4.7917rem; */
  /* position: relative; */
  /* height: 1rem; */
  border: 1px solid #eee;
  border-radius: .0521rem;
}

/deep/ .containerSeven[data-v-7eb2bc79] .el-form-item__content {
  height: 100% !important;
}

/deep/ .el-form--inline .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-47a68dd8] .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-21a084a6] .el-form-item__content {
  height: 100% !important;
}
/deep/ .el-textarea__inner {
  border: none !important;
  font-weight: 400;
  width: 99% !important;
  font-size: .0729rem !important;
  color: #999999 !important;
  letter-spacing: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0 0.05rem !important;
}

/* input框的字体样式 */
::v-deep .el-input__inner {
  font-size: .0729rem !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #999999;
  /* line-height: .1042rem; */
  letter-spacing: 0px;
  padding: 0 10px !important;
  /* font-weight: 600; */
  /* &::placeholder {
        color: #fff;
    } */

  /* &::-webkit-input-placeholder { */
  /* WebKit browsers 适配谷歌 */
  /* color: #fff;
    } */

  /* &:-moz-placeholder { */
  /* Mozilla Firefox 4 to 18 适配火狐 */
  /* color: #fff;
    } */

  /* &::-moz-placeholder { */
  /* Mozilla Firefox 19+ 适配火狐 */
  /* color: #fff;
    } */

  /* &:-ms-input-placeholder { */
  /* Internet Explorer 10+  适配ie*/
  /* color: #fff;
    } */
}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0 !important;
}

.containerSeven .content button {
  /* width: 2.0833rem;
  height: .25rem; */
  padding: 0.052083rem
    /* 10/192 */
    0.1875rem
    /* 36/192 */
  ;
  border: 1px solid #ff6a00;
  color: #ff6a00;
  background-color: transparent;
  border-radius: .0208rem;
  font-size: .0833rem;
  font-family: MicrosoftYaHei;

  line-height: .125rem;
  position: absolute;
  bottom: .15rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: 2.0833rem;
}

.containerSeven .content button:hover {
  border: none;
  background: #FD7D10 linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%);
  color: #FFFFFF;
}

.boxleftTo20 {
  transform: translateX(-20%);

  opacity: 0;
}

/* //滚动到指定位置时回到原点 */
.boxtestenterX {
  transform: translateX(0%) !important;
  opacity: 1 !important;
  transition: all 1s ease;
}

/* //开始位置在右侧120%位置 */
.boxtranslateXright {
  transform: translateX(10%);
  opacity: 0;
}

/* .boxtop20{
  transform: translateY(-20%);
  opacity: 0;
} */
/* .boxbottom20{
  transform: translateY(20%);
  opacity: 0;
}
.boxtestenterY{
  transform: translateY(0%) !important;
  opacity: 1 !important;
  transition: all 2s ease 1s;
} */
/deep/ .el-textarea__inner {
  resize: none;
}

.bannBox {
  background-size: cover !important;
}

.swiperFour .box1 {
  width: calc(33.3% - 0.0521rem - 0.0521rem);
}

.swiperFour .el-carousel__item {
  display: flex;
}


/* 手机端适应代码 */
@media screen and (max-width: 767px) {
  .container {
    height: auto;
    padding: 0 15px;
  }
.formItem-last-box{
    width: 94%;
    margin: 0 auto;
    margin-bottom: .0521rem;
}
  .sectionSix{
    height: 4.5rem;
  }
  .containerSix #content, .containerSix #content .box{
    height: 3rem;
  }

  .bannBox {
    margin-top: 60px;
    min-height: 6rem !important;
    background-size: cover !important;
  }

  .bannBox .txt {
    left: 15px;
    width: 50%;
  }
    .bannBox  .title{

    }
  .bannBox .txt h3,
  .containerThree h4,
  .containerFour h3,
  .containerFive>.title,
  .containerSix .title,
  .containerSeven .title {
    font-size: 18px;
    line-height: normal;
  }

  .left h3,
  .containerFive .content .right .r-r-txt .h6 {
    font-size: 16px;
    line-height: normal;
  }

  .left .gray,
  .containerFive .content .right .r-r-txt p,
  .containerFive .content .right li,
  .containerSeven .txt,
  .bannBox .txt .h5,
  .bannBox .txt button p span,
  .detail span {
    font-size: 14px !important;
    line-height: normal;
  }



  .mian-three .block1,
  .containerFour .content,
  .containerFive .content {
    flex-direction: column;
    height: auto;
  }

  .containerFive .content .right li {
    height: auto;
    justify-content: center;
  }

  .block1 .left,
  .containerFive .content .right {
    width: 100% !important;
  }

  .containerFive .content .right .r-r-txt {
    width: auto !important;
  }

  .block1 .right,
  .containerFive .content .left {
    width: 80%;
    height: auto
  }

  .right img {
    position: relative;
  }


  .containerFive .content .right li .r-l-img img,
  .containerFive .content .right li .r-l-img {
    width: 20px;
    height: 20px;
  }

  .swiperFour {
    width: 100%;
    height: auto !important;
  }


  .sjBox {
    position: relative;
    top: 0;
    left: 0;
  }

  .sectionFour,
  .containerFour {
    height: auto
  }
  .containerFour {
    padding-bottom: 0.3rem;
  }
  .line {
    width: 28px;
    height: 3px;
    border-radius: 5px;
  }

  .swiperFour .box1 h5 {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .swiperFour .box1 p {
    font-size: 12px !important;
    line-height: normal !important;
  }

  .containerSeven .title {
    font-size: 16px !important;
    line-height: normal !important;
      padding-bottom: 10px;
  }

  .containerSeven .txt {
    font-size: 14px !important;
    line-height: normal !important;
  }

  /* .el-form>div{
    display: block!important;
  } */
  .containerSeven .content {
    width: 80%;
    margin: 0 auto;
  }

  .containerSeven /deep/ .el-form-item__content {
    flex: 1
  }

  .containerSeven /deep/ .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .containerSeven /deep/ .el-input__inner {
    width: 100%;
    padding: 10px;
    height: 30px;
    font-size: 14px !important;
  }

  .containerSeven {
    padding: 15px 0 !important;

  }

  .ipt {
    width: 100%;
    height: auto !important;
  }

  /deep/ .el-form-item__label {
    width: 20px
  }

  .tips span {
    font-size: 12px !important;
    line-height: normal !important;
    height: auto !important;
      padding: 3px 4px;
  }

  /deep/ .textArea,
  /deep/ .formItem-last {
    width: 100%;
  }

  .tips {
    bottom: 18px;
    left: 10px;
  }

  .containerSeven .content button {
    padding: 5px 15px;
    height: auto !important;
    line-height: normal !important;
    position: relative;
    left: auto;
    bottom: auto;
    transform: translateX(0%);
    margin: 15px 0 0 0;
    font-size: 14px !important;
  }

  .sectionFour /deep/ .el-carousel__container {
    height: 5em;
    width: 100%;
    min-height: 160px;
    margin-bottom: 20px;
  }

  .consult,
  .consult p span {
    font-size: 14px !important;
    line-height: normal !important;
    bottom: 0.3rem;
  }
  .consult{
    left: 50%;
    transform: translate(-50%,0.25rem);
  }
  .r-r-txt p {
    width: auto !important;
  }

  .containerFive .content .right li .r-l-img {
    margin-right: 20px;
  }

}

.formItem-last-box {
  margin-left: 10px;
}

</style>