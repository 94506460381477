<template>
  <div class="swiper">
    <swiper ref="mySwiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in list2" :key="item.url + index" style="padding: 0 3px;">
      
          <img :src="item"/>
       
      </swiper-slide>
    </swiper>

    <!-- </div> -->
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination" id="pagination" slot="pagination"></div> -->

    <!-- 左右箭头 -->
    <div class="swiper-button-next" slot="button-next" @click="next"></div>
    <div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
  </div>
</template>
  <script>
import {
  Swiper,
  SwiperSlide,
  Navigation,
  Pagination,
} from "vue-awesome-swiper";
import "../../node_modules/swiper/css/swiper.min.css";
Swiper.use([Navigation, Pagination]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["list2"],

  data() {
    return {
      swiperOption: {
        // pagination:'.swiper-pagination',
        // 衔接滑动
        loop: true,
        // autoplay: {
        //     delay: 150000,
        //     // 如果设置为true，当切换到最后一个slide时停止自动切换。
        //     stopOnLastSlide: false,
        //     // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
        //     disableOnInteraction: false
        // },
        // freeMode: true,
        // slidesPerView:auto,
        // spaceBetween:-15,
        // 自动滑动
        autoplay: {
          delay: 2000,
          // 如果设置为true，当切换到最后一个slide时停止自动切换。
          stopOnLastSlide: false,
          // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
          disableOnInteraction: false
        },
        // 切换效果 "coverflow"（3d流）
        centeredSlides:true,
        effect: "coverflow",
        // 设置slider容器能够同时显示的slides数量
        slidesPerView: 4,
        // 居中幻灯片。设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
        centeredSlides: true,
        // 设置为true则点击slide会过渡到这个slide。
        //   slideToClickedSlide: true,
        coverflowEffect: {
          // slide做3d旋转时Y轴的旋转角度
          rotate: 30,
          // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
          stretch: 7,
          // slide的位置深度。值越大z轴距离越远，看起来越小。
          // depth: 100,
          // // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
          // modifier: 1,
          // 是否开启slide阴影
          slideShadows: false,
        },
        spaceBetween: 10,
        // 使用前进后退按钮来控制Swiper切换。
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
      },
    };
  },
  mounted() {
    this.initSwiper();
  },
  computed: {
    mySwiper() {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    //   enter () {
    //     this.mySwiper.autoplay.stop()
    //   },
    //   leave () {
    //     this.mySwiper.autoplay.start()
    //   },
    prev(){
        this.mySwiper.slidePrev();
      },
      next(){
        this.mySwiper.slideNext();
      },
      initSwiper() {
      this.swiper = new Swiper(this.$refs.swiperContainer, this.swiperOptions);
      this.swiper.on('slideChange', this.onSlideChange);
    },
    onSlideChange() {
      const activeIndex = this.swiper.realIndex;
      const slides = this.$refs.swiperContainer.querySelectorAll('.swiper-slide');
      
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove('active-slide');
      }
      
      slides[activeIndex].classList.add('active-slide');
    }
  },
};
</script>
  <style scoped >
 .swiper-slide-active{
  transform: translate3d(-0.511027px, 0px, -7.30038px) rotateX(0deg) rotateY(0.37186deg) scale(1) !important;
 }
.swiper {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-slide {
}
.swiper-slide {
  /* width: 1.941rem !important;
    margin:0 .1521rem !important; */
  /* text-align: left; */
  /* margin: 0 left; */
}
.swiper-button-prev {
}
img {
  /* width: 1.8219rem !important; */
  /* width: 1.941rem !important; */
  width: 1.831rem !important;
  /* width: 100%; */
  /* height: 3.1115rem; */
  height: auto!important;
  border-radius: 0.2538rem;
  /* object-fit: cover !important; */
  /* width: 1.8333rem;
    height: 2.8854rem;
    border-radius: .0938rem; */
}
.swiper-pagination {
  /* width: 1.3021rem; */
  height: 0.23rem;
  display: flex;
  justify-content: space-around;
  padding-top: 0.1042rem;
  box-sizing: border-box;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#pagination >>> .swiper-pagination-bullet {
  background: linear-gradient(270deg, #ff8c1f 0%, #ff6a00 100%) !important;
  border-radius: 0.0833rem !important;
  opacity: 0.2 !important;
  width: 0.0781rem !important;
  height: 0.0781rem !important;
  border-radius: 50% !important;
  margin: 0 2px;
}
#pagination >>> .swiper-pagination-bullet-active {
  width: 0.4323rem !important;
  height: 0.0781rem !important;
  background: linear-gradient(270deg, #ff8c1f 0%, #ff6a00 100%) !important;
  border-radius: 0.0833rem !important;
  opacity: 1 !important;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: -99px;
    right: auto;
    background: #f5f7fa;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: -60px;
    left: auto;
    background: #f5f7fa;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
}

.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 24px;
  color: black;
}
.swiper-container{
  margin-left: -36px !important;
}
.swiper-button-prev:hover,.swiper-button-next:hover{
  background: #FF6A00;
}
.swiper-button-prev:hover:after,.swiper-button-next:hover:after{
  color:white;
}
</style>