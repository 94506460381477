import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navList: [
      {
        id:0,
        name: "首页",
        path: "index",
        navsList: [
          // {
          //   id:0,
          //   imgUrl: require("../assets/index/navs-1.png"),
          //   txt: "专业可靠的产品",
          //   path:'index'
          // },
          // {
          //   id:1,
          //   imgUrl: require("../assets/nav/navs-1-1.png"),
          //   txt: "全方位场景解决方案",
          //   path:'index'
          // },
          // {
          //   id:2,
          //   imgUrl: require("../assets/nav/navs-1-3.png"),
          //   txt: "领先的技术与服务",
          //   path:'index'
          // },
          // {
          //   id:3,
          //   imgUrl: require("../assets/nav/navs-1-4.png"),
          //   txt: "众多企业的信赖之选",
          //   path:'index'
          // },
          // {
          //   id:4,
          //   imgUrl: require("../assets/nav/navs-1-5.png"),
          //   txt: "联系我们",
          //   path:'index'
          // },
        ],
      
      },
      {
        id:1,
        name: "营销服务",
        path: "service",
        navsList: [
          {
            id:0,
            imgUrl: require("../assets/nav/navs-2-1.png"),
            txt: "精准PR整合传播",
            path:'service'
          },
          {
            id:1,
            imgUrl: require("../assets/nav/navs-2-2.png"),
            txt: "智能影视宣发平台",
            path:'service'
          },
          {
            id:2,
            imgUrl: require("../assets/nav/navs-2-3.png"),
            txt: "AI广告投放平台",
            path:'service'
          },
         
        ],
      },
      {
        id:2,
         name: "应用场景",
          path: "sence",
          navsList: [
            {
              id:0,
              imgUrl: require("../assets/nav/navs-3-1.png"),
              txt: "以人群为核心",
              path:'sence'
            },
            {
              id:1,
              imgUrl: require("../assets/nav/navs-3-2.png"),
              txt: "以地理位置为核心",
              path:'sence'
            },
            {
              id:2,
              imgUrl: require("../assets/nav/navs-3-3.png"),
              txt: "以数据分析为核心",
              path:'sence'
            }
          ],
         },
      { 
        id:3,
        name: "技术优势", 
        path: "technology",
        navsList: [
          {
            id:0,
            imgUrl: require("../assets/nav/navs-4-1.png"),
            txt: "独立一方数据源",
            path:'technology'
          },
          {
            id:1,
            imgUrl: require("../assets/nav/navs-4-2.png"),
            txt: "专业投放能力",
            path:'technology'
          },
          {
            id:2,
            imgUrl: require("../assets/nav/navs-4-3.png"),
            txt: "精准人群感知能力",
            path:'technology'
          },
          {
            id:3,
            imgUrl: require("../assets/nav/navs-4-4.png"),
            txt: "跨媒体联合频控",
            path:'technology'
          },
          
        ],
       },
      { 
         id:4,
         name: "服务优势",
         path: "goods",
         navsList: [
          {
            id:0,
            imgUrl: require("../assets/nav/navs-5-2.png"),
            txt: "专业服务",
            path:'goods'
          },
          {
            id:1,
            imgUrl: require("../assets/nav/navs-5-3.png"),
            txt: "案例展示",
            path:'goods'
          }
         
        ],
         },
      {
         id:5,
         name: "关于我们",
         path: "about",
         navsList: [
          // {
          //   id:0,
          //   imgUrl: require("../assets/nav/navs-6-1.png"),
          //   txt: "公司简介",
          //   path:'about'
          // },
          // {
          //   id:1,
          //   imgUrl: require("../assets/nav/navs-6-2.png"),
          //   txt: "数据驱动 营销创新",
          //   path:'about'
          // },
          // {
          //   id:2,
          //   imgUrl: require("../assets/nav/navs-6-3.png"),
          //   txt: "数据安全与隐私协议",
          //   path:'about'
          // },
         
        ],
         },
    ],
    active:0,
    showFormModal:false
  },
  getters: {
  },
  mutations: {
    SHOWDIALOG(state, value) {
      state.showFormModal = value;
    },
  },
  actions: {
    showDialog(context, value) {
      context.commit('SHOWDIALOG', value);
    },
  },
  modules: {
  }
})
