<template>
  <div>
    <div ref="myNav" class="myNav">
      <navView @gotosection="gotobox" :suoyin="suoyin" />
    </div>
    
    <div class="twos bannBox" :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }">
      <div class="txt">
        <h3 class="pc">{{ bannerTitle }} </h3>
        <h3 class="mobile" v-html="bannerTitle.replace('限','限<br/>')"></h3>
        <!-- <p class="h5" v-if="bannerRemark != ''">{{ bannerRemark }}</p> -->
		<p class="h5">&nbsp</p>
        <button @click="showForm">
          <p><span>立即咨询 </span></p>
        </button>
      </div>
      <!-- <img :src="bannerImage" style="position: absolute;left: 0;top: 0; width: 100%;height:100%;" alt="" /> -->
    </div>
    <!-- 第三部分 -->
    <section class="threes" name="jingzhun">
      <p class="title" style="color: black; font-weight: bold">{{ name1 }}</p>
      <p class="text">{{ tips1 }}</p>
      <div class="content">
        <ul style="
            width: 7.2917rem;
            height: 0.7875rem;
            display: flex;
            align-items: center;
          ">
          <li @mouseenter="showBorder(index)" v-for="(item, index) in list1" :key="index" :style="isBorder == index
            ? 'border:1px solid  rgba(151,151,151,0.2);box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.14);text-align:center;'
            : ''
            " style="
              line-height: 0.7865rem;
              width: 33.3%;
              height: 0.7865rem;
              position: relative;
              text-align: center;
            ">
            {{ item.title }}
            <!-- 上边框 -->
            <div :class="isBorder == index ? 'isBorder' : ''"></div>
          </li>
        </ul>
        <ol>
          <li v-show="isBorder != 3 && isBorder == index" v-for="(item, index) in list1" :key="index"
          style="transition: all 0.5s;">
            <div class="left" style="transition: all 0.5s;">
              <p class="tit">{{ item.title }}</p>
              <p class="texts">{{ item.content }}</p>
              <!-- 没有 -->
              <button @click="showForm">立即咨询</button>
            </div>
            <div class="right" style="width: 3.3854rem; height: auto">
              <img :src="item.image" style="width: 100%; height: auto" alt="" />
            </div>
          </li>
        
          <li v-if="isBorder == 3">
            <div class="right pc" style="width: 3.3854rem; height: auto;margin-left: 82px;">
              <img :src="list1[3].banner" style="width: 100%; height: auto" mode="widthFix" alt="" />
            </div>
            <ul class="r pc" id="rpc" style="
                width: 4.1021rem;
                height: 1.7708rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
              ">
              <li style="margin-top: -0.2rem;margin-bottom: 0.2rem;"> <p style="font-size: 0.1771rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313233;
    line-height: 0.25rem;">{{ list1[3].tag }}</p></li>
              <li style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.1615rem;
                ">
                <img :src="list1[3].icon" style="
                    width: 0.1615rem;
                    height: 0.1615rem;
                    margin-right: 0.0677rem;
                  " alt="" />
                 
                  <span style="
                    font-size: 0.125rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #313233;
                    line-height: 0.125rem;
                  ">{{ list1[3].ctitle }}</span><span style="
                    font-size: 0.0938rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #626366;
                    line-height: 0.1302rem;
                  ">{{ list1[3].ccontent }}</span>
              </li>
              <li style="
                  display: flex;
                  margin-bottom: 0.1563rem;
                  align-items: flex-start;
                ">
                <div class="d1" style="display: flex; width: 0.871rem">
                  <img :src="list1[3].icon3" style="
                      width: 0.1615rem;
                      height: 0.1615rem;
                      display: inline-block;
                      margin-right: 0.0677rem;
                    " alt="" />
                  <span style="
                      font-size: 0.125rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #313233;
                      line-height: 0.125rem;
                    ">{{ list1[3].cel }}</span>
                </div>
                <div class="d2" style="
                    width: 2.3385rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;
                    text-align: left;
                    margin-left: -0.08rem;
                  ">
                  <span style="
                      font-size: 0.0938rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #626366;
                      line-height: 0.1302rem;
					  width: 2.3rem;
                    ">
                    {{ list1[3].content[0] }}</span>
                  <div style="height: 0.0521rem"></div>
                  <span style="
                      font-size: 0.0938rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #626366;
                      line-height: 0.1302rem;
                    ">
                    {{ list1[3].content[1] }}</span>
                  <div style="height: 0.0521rem"></div>
                  <!-- <span
                style="
                  font-size: 0.0938rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #626366;
                  line-height: 0.1302rem;
                "
                > · 客户指定的5个关键决策人</span> -->
                </div>
              </li>
              <li style="display: flex; margin-bottom: 0.2188rem">
                <img :src="list1[3].icon2" style="
                    width: 0.1615rem;
                    height: 0.1615rem;
                    margin-right: 0.0677rem;
                  " alt="" /><span style="
                    font-size: 0.125rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #313233;
                    line-height: 0.125rem;
                  ">{{ list1[3].ztitle }}</span><span style="
                    font-size: 0.0938rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #626366;
                    line-height: 0.1302rem;
                  ">{{ list1[3].zcontent }}</span>
              </li>
              <li class="allPrice" style="width: 2rem;">
                <div class="price-top" style="width: 1.85rem">
                  <p class="first" style="width: 0.7375rem;text-align: left;">
                    {{ list1[3].data1 }} <span>{{ list1[3].rol1 }}</span>
                  </p>
                  <p class="second" style="text-align: left;">{{ list1[3].con1 }}</p>
                </div>
                <div class="price-bot">
                  <p class="first" style="width: 1rem">
                    {{ list1[3].data2 }} <span>{{ list1[3].rol2 }}</span>
                  </p>
                  <p class="second">{{ list1[3].con2 }}</p>
                </div>
              </li>
            </ul>

            <ul class="r mobile" style="height: 6rem">
              <li style="margin-top: 0.1rem;margin-right: 2.1rem;"> <p style="font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313233;
    ">{{ list1[3].tag }}</p></li>
              <li style="
               justify-content: unset;
                  display: flex;
                  align-items: center;
                  flex-direction:row;
                  margin-bottom: 0.1615rem;
                  height: 0.8rem;
                ">
                <img :src="list1[3].icon" style="
                    display: inline-block;
                    width: 20px;
                    margin-right: 0.0677rem;
                  " alt="" />
                <span style="
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #313233;
                  ">{{ list1[3].ctitle }}</span>
                  <span style="
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #626366;
                  ">{{ list1[3].ccontent }}</span>
              </li>
              <li style="
                  display: flex;
                  align-items: flex-start;
                  flex-direction:row;
                  margin-bottom: 0.1615rem;
                  font-size: 0;
                  justify-content: unset;
                ">
                  <img :src="list1[3].icon3" style="
                     width: 20px;
                    margin-right: 0.0677rem;
                    " alt="" /><span style="
                      font-size: 12px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #313233;
                    ">{{ list1[3].cel }}</span>
                <div class="d2" style="
                    width: 4rem;
                    text-align: left;
                  ">
                  <span style="
                      font-size: 12px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #626366;
                    ">
                    {{ list1[3].content[0] }}</span><br/>
                  <span style="
                      font-size: 12px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #626366;
                    ">
                    {{ list1[3].content[1] }}</span>
                  <!-- <span
                style="
                  font-size: 0.0938rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #626366;
                  line-height: 0.1302rem;
                "
                > · 客户指定的5个关键决策人</span> -->
                </div>
              </li>
              <li style="justify-content: unset;
                  display: flex;
                  align-items: center;
                  flex-direction:row;
                  height: 1rem;">
                <img :src="list1[3].icon2" style="
                    width: 20px;
                    margin-right: 0.0677rem;
                  " alt="" /><span style="
                    font-size: 12px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #626366;
                  ">{{ list1[3].ztitle }}</span><span style="
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #626366;
                    line-height: 0.1302rem;
                  ">{{ list1[3].zcontent }}</span>
              </li>
              <li class="allPrice" style=" flex-direction:row;justify-content: space-between">
                <div class="price-top">
                  <p class="first" style="text-align: center;">
                    {{ list1[3].data1 }} <span>{{ list1[3].rol1 }}</span>
                  </p>
                  <p class="second" style="line-height: 20px">{{ list1[3].con1 }}</p>
                </div>
                <div class="price-bot">
                  <p class="first" >
                    {{ list1[3].data2 }} <span>{{ list1[3].rol2 }}</span>
                  </p>
                  <p class="second" style="line-height: 20px">{{ list1[3].con2 }}</p>
                </div>
              </li>
            </ul>


          </li>
        </ol>
      </div>
    </section>
    <!-- 第四部分 智能影视宣发平台 -->
    <section class="fours">
      <p class="title" style="
          font-size: 0.1875rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: black;
          line-height: 0.2604rem;
        ">
        {{ name2 }}
      </p>

      <p class="txt">
        {{ tips2 }}
      </p>
      <ul class="ul pc">
          <li @mouseover="showBigModal(0)" v-if="hover != 0"  :key="list2[0].title">
            <p>{{ list2[0].title }}</p>
            <img class="banner" :src="list2[0].banner" alt="" />
            <img class="img" src="../assets/service//jiantou.png" alt="" />
          </li>
          <li :class="hover == 0 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[0].title }}</p>
            <p class="fetitle">{{ list2[0].content[0] }}</p>
            <p class="text">{{ list2[0].content[1] }}</p>
            <p class="fetitle">{{ list2[0].content[2] }}</p>
            <p class="text">{{ list2[0].content[3] }}</p>
          </li>
          <li @mouseover="showBigModal(1)" v-if="hover != 1" :key="list2[1].title">
            <p>{{ list2[1].title }}</p>
            <img class="banner" :src="list2[1].banner" alt="" />
            <img class="img" src="../assets/service/jiantou.png" alt="" />
          </li>
          <li :class="hover == 1 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[1].title }}</p>
            <p class="fetitle">{{ list2[1].content[0] }}</p>
            <p class="text">{{ list2[1].content[1] }}</p>
            <p class="fetitle">{{ list2[1].content[2] }}</p>
            <p class="text">{{ list2[1].content[3] }}</p>
          </li>
          <li @mouseover="showBigModal(2)" v-if="hover != 2" :key="list2[2].title">
            <p>{{ list2[2].title }}</p>
            <img class="banner" :src="list2[2].banner" alt="" />
            <img class="img" src="../assets/service/jiantou.png" alt="" />
          </li> 
          <li :class="hover == 2 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[2].title }}</p>
            <p class="fetitle">{{ list2[2].content[0] }}</p>
            <p class="text">{{ list2[2].content[1] }}</p>
            <p class="fetitle" v-if="list2[2].content[2] != ''">
              {{ list2[2].content[2] }}
            </p>
            <p class="text" v-if="list2[2].content[3] != ''">
              {{ list2[2].content[3] }}
            </p>
          </li> 
      </ul>
      <ul class="ul moblie" style="display:none">
          <li @click="showBigModal(0)" v-if="hover != 0"  :key="list2[0].title">
            <p>{{ list2[0].title }}</p>
            <img class="banner" :src="list2[0].banner" alt="" />
            <img class="img" src="../assets/service//jiantou.png" alt="" />
          </li>
          <li :class="hover == 0 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[0].title }}</p>
            <p class="fetitle">{{ list2[0].content[0] }}</p>
            <p class="text">{{ list2[0].content[1] }}</p>
            <p class="fetitle">{{ list2[0].content[2] }}</p>
            <p class="text">{{ list2[0].content[3] }}</p>
          </li>
          <li @click="showBigModal(1)" v-if="hover != 1" :key="list2[1].title">
            <p>{{ list2[1].title }}</p>
            <img class="banner" :src="list2[1].banner" alt="" />
            <img class="img" src="../assets/service/jiantou.png" alt="" />
          </li>
          <li :class="hover == 1 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[1].title }}</p>
            <p class="fetitle">{{ list2[1].content[0] }}</p>
            <p class="text">{{ list2[1].content[1] }}</p>
            <p class="fetitle">{{ list2[1].content[2] }}</p>
            <p class="text">{{ list2[1].content[3] }}</p>
          </li>
          <li @click="showBigModal(2)" v-if="hover != 2" :key="list2[2].title">
            <p>{{ list2[2].title }}</p>
            <img class="banner" :src="list2[2].banner" alt="" />
            <img class="img" src="../assets/service/jiantou.png" alt="" />
          </li> 
          <li :class="hover == 2 ? 'active' : ''" @mouseleave="hideBigModal" v-else key="list2[0].title">
            <p class="head">{{ list2[2].title }}</p>
            <p class="fetitle">{{ list2[2].content[0] }}</p>
            <p class="text">{{ list2[2].content[1] }}</p>
            <p class="fetitle" v-if="list2[2].content[2] != ''">
              {{ list2[2].content[2] }}
            </p>
            <p class="text" v-if="list2[2].content[3] != ''">
              {{ list2[2].content[3] }}
            </p>
          </li> 
      </ul>
      <!-- 轮播 -->
      <div class="containers">
        <ul class="tab">
          <li :class="actives == 0 ? 'actives' : ''" @click="showLocalModal(0)">
            {{ name3 }}
            <div v-if="actives == 0"></div>
          </li>
          <li :class="actives == 1 ? 'actives' : ''" @click="showLocalModal(1)">
            {{ name4 }}
            <div v-if="actives == 1"></div>
          </li>
        </ul>
      </div>
      <!-- swiper轮播 -->
      <div class="swipersBox" v-if="actives == 0">
        <div class="swipers">
          <swiperCardTwoView :swiperList="list3[0].image" />
        </div>
      </div>
      <!-- 上下轮播 -->
      <div class="carousel" v-if="actives == 1">
        <!-- :autoplay="false" -->
        <el-carousel ref="carousel" :initial-index="carousdelActive" @change="carouselChange" direction="vertical"
          indicator-position="none">
          <el-carousel-item v-for="(item, index) in list4" :key="index">
            <div class="box">
              <div class="lefts">
                <p class="titles">
                  {{ item.title }}
                </p>
                <p class="txts">{{ item.cel }}</p>
                <li class="allPrice">
                  <div class="price-top" style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: space-between;
                    ">
                    <p class="first">
                      {{ item.data1 }}
                      <span style="font-size: 0.0729rem; color: #333">{{
                        item.rol1
                      }}</span>
                    </p>
                    <p class="second" style="">{{ item.con1 }}</p>
                  </div>
                  <!-- 分数 -->
                  <div class="price-bot" style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    ">
                    <p class="first" style="width: 1rem">
                      {{ item.data2 }} <span>{{ item.rol2 }}</span>
                    </p>
                    <p class="second">{{ item.con2 }}</p>
                  </div>
                  <!-- 排名 -->
                  <!-- <div v-if="item.datal2 !=''"  class="price-bot" style="display: flex; justify-content: space-between">
                          <p class="first" style="width: 1rem">
                            {{item.data2}}  <span>{{item.rol2}}</span>
                          </p>
                          <p class="second">{{item.con2}}</p>
                        </div> -->
                </li>
              </div>
              <div class="rights" style="">
                <img class="img" :src="item.banner" alt="" />
                <img class="shadowimg" :src="list4[carousdelActive == 2 ? '0' : carousdelActive + 1]
                  .banner
                  " alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="idots pc">
          <button @click="changeCarousel('prev')">
            <img src="../assets/service/shang-jiantou.png" alt="" />
          </button>
          <ul>
            <li @click="changeCarouselIdots(0)" :class="carousdelActive == 0 ? 'activess' : ''"></li>
            <li @click="changeCarouselIdots(1)" :class="carousdelActive == 1 ? 'activess' : ''"></li>
            <li @click="changeCarouselIdots(2)" :class="carousdelActive == 2 ? 'activess' : ''"></li>
          </ul>
          <button @click="changeCarousel('next')">
            <img src="../assets/service/xia-jiantou.png" alt="" />
          </button>
        </div>
      </div>
    </section>
    <!-- AI广告投放平台 -->
    <section class="ai" style="padding-top: 0.5208rem;">
      <div class="title" style="color: black; font-weight: bold">
        {{ name5 }}
      </div>
      <div class="content">
        <ul>
          <li class="ul" :class="a == 0 || a == 3 ? 'active' : ''" @click="changeAI(0)">
            {{ list5[0].show.title }}
          </li>
          <li class="ul" :class="a == 1 || a == 2 ? 'active' : ''" @click="changeAI(1)">
            {{ list5[1].show.title }}
          </li>
        </ul>
        <ol>
          <li class="ol a" v-if="a == 0">
            <div class="left">
              <div class="tit">{{ list5[0].show.title }}</div>
              <p class="txt pc">{{ list5[0].show.content }}</p>
              <p class="txt mobile" style="padding-right: 0">{{ list5[0].show.content }}</p>
              <!-- 按钮 @mouseenter="showModal(3)" -->
              <button class="consult pc" @mouseenter="changeConsultBtn(3)" @click="gotoHidden(3)"
                :style="consultStyleActive ? consultStyle : ''" @mouseleave="changeConsultBtnLeave">
                <p>
                  <span :style="consultStyleActive ? 'color:white' : 'color:#ff7300;'
                    ">
                    {{ list5[0].show.button }}</span>
                </p>
              </button>
            </div>
            <div class="right" style="">
              <img src="../assets/service/ai-2.png" alt="" />
            </div>
          </li>
          <li class="ol b" v-if="a == 1">
            <div class="left">
              <div class="tit">{{ list5[1].show.title }}</div>
              <p class="txt">{{ list5[1].show.content }}</p>
              <!-- 按钮 @mouseenter="showModal(2)" -->
              <button class="consult pc" @mouseenter="changeConsultBtn(2)" @click="gotoHidden(2)"
                :style="consultStyleActive ? consultStyle : ''" @mouseleave="changeConsultBtnLeave">
                <p>
                  <span :style="consultStyleActive ? 'color:white' : 'color:#ff7300;'
                    ">{{ list5[0].show.button }}
                  </span>
                  <!-- <img :src="consultStyleActive ? btnUrl:btnUrlActive" alt="" /> -->
                </p>
              </button>
            </div>
            <div class="right">
              <img :src="list5[1].show.image" alt="" />
            </div>
          </li>
        </ol>
        <!-- 鼠标进入显示的画面 -->
        <div>
          <!-- 托福 -->
          <transition name="showAnimation">
            <!-- @mouseleave="hideModal(1)" -->
            <div class="modal bb" v-if="a == 2"  style="width: 6.25rem; margin: 0 auto">

              <div class="sjkboxmain" style="
                  display: flex;
                  justify-content: space-around;
                  /* align-items: center; */
                  /* height: 2.2708rem; */
                ">
                <div class="sjkbox">
                  <div class="sjbox">
                    <img :src="list5[1].hidden.banner" alt="" />
                  </div>
                </div>
                <ul class="r" style="
                    width: 4.1021rem;
                    height: 1.7708rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                  ">
                  <!-- <li class="txt" style="
                      margin-bottom: 0.224rem;
                      font-size: 0.1458rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #313233;
                      line-height: 0.2083rem;
                    ">
                    托福英语
                  </li> -->
                  <li class="txt">
                    {{ list5[1].hidden.title }}
                  </li>
                  <li style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 0.1615rem;
                    ">
                    <img :src="list5[1].hidden.icon" style="
                        width: 0.1615rem;
                        height: 0.1615rem;
                        margin-right: 0.0677rem;
                      " alt="" /><span style="
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #313233;
                        line-height: 0.125rem;
                      ">{{ list5[1].hidden.ctitle }} &nbsp;</span><span style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1302rem;
                      ">{{ list5[1].hidden.ccontent }}
                    </span>
                  </li>
                  <li style="display: flex; margin-bottom: 0.1563rem">
                    <div class="hh" style="display: flex; width: 0.8938rem">
                      <img :src="list5[0].hidden.icon3" style="
                          width: 0.1615rem;
                          height: 0.1615rem;
                          display: inline-block;
                          margin-right: 0.0677rem;
                        " alt="" /><span style="
                          font-size: 0.125rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #313233;
                          line-height: 0.125rem;
                        ">{{ list5[1].hidden.cel }}
                      </span>
                    </div>
                    <div style="
                        width: 2.3385rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: flex-start;
                        text-align: left;
                      ">
                      <span style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[1].hidden.content[0] }}</span>
                      <div style="height: 0.0521rem"></div>
                      <span v-if="list5[1].hidden.content[1] != ''" style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[1].hidden.content[1] }}</span>
                      <div style="height: 0.0521rem"></div>
                      <span v-if="list5[1].hidden.content[2] != ''" style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[1].hidden.content[2] }}</span>
                    </div>
                  </li>
                  <li style="display: flex; margin-bottom: 0.2188rem">
                    <img :src="list5[1].hidden.icon2" style="
                        width: 0.1615rem;
                        height: 0.1615rem;
                        margin-right: 0.0677rem;
                      " alt="" /><span style="
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #313233;
                        line-height: 0.125rem;
                      ">{{ list5[1].hidden.ztitle }} &nbsp;</span><span style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1302rem;
                      ">{{ list5[1].hidden.zcontent }}
                    </span>
                  </li>
                  <li class="allPrice" style="display: flex;">
                    <div class="price-top">
                      <p class="first" style="width: 0.9375rem">
                        {{ list5[1].hidden.data1 }}
                        <span>{{ list5[1].hidden.rol1 }} </span>
                      </p>
                      <p class="second">{{ list5[1].hidden.con1 }}</p>
                    </div>
                    <div class="price-bot">
                      <p class="first" style="width: 1rem">
                        {{ list5[1].hidden.data2 }}
                        <span>{{ list5[1].hidden.rol2 }} </span>
                      </p>
                      <p class="second">{{ list5[1].hidden.con2 }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
          <!-- 珠宝 -->
          <transition name="showAnimations">
            <!-- @mouseleave="hideModal(0)" -->
            <div class="modal aa" v-if="a == 3">

              <div class="subtxts sjkboxmain">
                <div class="sjkbox">

                  <div class="sjbox">
                    <img :src="list5[0].hidden.banner" alt="" />
                  </div>
                </div>
                <ul class="r">
                  <li class="txt">
                    {{ list5[0].hidden.title }}
                  </li>
                  <li style="
                      
                    ">
                    <img :src="list5[0].hidden.icon" style="
                        width: 0.1615rem;
                        height: 0.1615rem;
                        margin-right: 0.0677rem;
                      " alt="" /><span class="span1" style="
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #313233;
                        line-height: 0.125rem;
                      ">{{ list5[0].hidden.ctitle }} &nbsp;</span><span style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1302rem;
                      ">{{ list5[0].hidden.ccontent }}</span>
                  </li>
                  <li style="display: flex; margin-bottom: 0.1563rem">
                    <div class="hh" style="display: flex; width: 0.8938rem">
                      <img :src="list5[0].hidden.icon3" style="
                          width: 0.1615rem;
                          height: 0.1615rem;
                          display: inline-block;
                          margin-right: 0.0677rem;
                        " alt="" /><span class="span1" style="
                          font-size: 0.125rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #313233;
                          line-height: 0.125rem;
                        ">{{ list5[0].hidden.cel }}</span>
                    </div>
                    <div style="
                        width: 2.3385rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: flex-start;
                        text-align: left;
                      ">
                      <span style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[0].hidden.content[0] }}</span>
                      <div style="height: 0.0521rem"></div>
                      <span style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[0].hidden.content[1] }}</span>
                      <div style="height: 0.0521rem"></div>
                      <span style="
                          font-size: 0.0938rem;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #626366;
                          line-height: 0.1302rem;
                        ">
                        {{ list5[0].hidden.content[2] }}</span>
                    </div>
                  </li>
                  <li style="display: flex; margin-bottom: 0.2188rem">
                    <img :src="list5[0].hidden.icon2" style="
                        width: 0.1615rem;
                        height: 0.1615rem;
                        margin-right: 0.0677rem;
                      " alt="" /><span class="span1" style="
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #313233;
                        line-height: 0.125rem;
                      ">{{ list5[0].hidden.ztitle }} &nbsp;</span>
                    <span  class="span2" style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1302rem;
                      ">{{ list5[0].hidden.zcontent }}</span>
                  </li>
                  <li class="allPrice" style="display: flex;">
                    <div class="price-top">
                      <p class="first" style="width: 0.7375rem">
                        {{ list5[0].hidden.data1 }}
                        <span>{{ list5[0].hidden.rol1 }}</span>
                      </p>
                      <p class="second">{{ list5[0].hidden.con1 }}</p>
                    </div>
                    <div class="price-bot">
                      <p class="first" style="width: 1rem">
                        {{ list5[0].hidden.data2 }}
                        <span>{{ list5[0].hidden.rol2 }}</span>
                      </p>
                      <p class="second">{{ list5[0].hidden.con2 }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </section>
    <bottomView @gotosection="gotobox" />
    <!-- 立即咨询弹框 -->
    <formView />
    <sliderView />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { services } from "@/api/service";
import swiperView from "../components/swiper.vue";
import navView from "../components/nav.vue";
import formView from "../components/form.vue";
import swiperCardTwoView from "../components/swiperCardTwo.vue";
import bottomView from "@/components/bottom.vue";
import sliderView from "@/components/slider.vue";
import axios from "axios";
import {transition} from 'vue';
export default {
  components: {
    swiperView,
    navView,
    formView,
    swiperCardTwoView,
    bottomView,
    sliderView,
    transition,
  },
  data() {
    return {
      
      consultStyleActive: 0,
      btnUrl: require("../assets/index/swiper-jiantou.png"),
      btnUrlActive: require("../assets/index/swiper-jiantou-white.png"),
      consultStyle: {
        background: "#ff7300",
      },
      index: 0,
      suoyin: 0,
      navslist: [
        {
          title: "人群定位",
          list: {
            fittitle: "人群定位",
            text: "充分利用前沿技术手段，实现对网络位置、物理位置的精确识别，高效圈定目标公关人群。",
            imgurl: require("../assets/service/navs-1.png"),
          },
        },
        {
          title: "内容定制",
          list: {
            fittitle: "内容定制",
            text: "根据关键人的特点 和项目需求匹配内容，通过多形式、多渠道展示品牌价值主张和品牌故事",
            imgurl: require("../assets/service/navs-2.png"),
          },
        },
        {
          title: "价值传递",
          list: {
            fittitle: "价值传递",
            text: "将有价值的内容，精准推送给具有极高潜力和影响力的关键用户，塑造品牌美誉度。",
            imgurl: require("../assets/service/navs-3.png"),
          },
        },
        {
          title: "精彩案例",
          list: {
            fittitle: "某电商品牌定向公关",
            text: "",
            imgurl: require("../assets/service/navs-4.png"),
          },
        },
      ],
      isBorder: 0,
      a: 0,
      hover: 0,
      actives: 0,
      carousdelActive: 0,
      carouselList: [
        {
          id: 0,
          titles: "奇迹笨小孩",
          texts:
            "借势双旦期间观影主力军及“千纸鹤”粉丝群体，集合海量优质煤体强势发声，引爆影片热度。",
          imgurl: require("../assets/ceshi1.jpeg"),
          office: "13",
          yi: "亿",
          piaoTxt: "累计票房",
          No: "",
          Nowei: "",
          NoTxt: "",
          code: "9.5",
          codewei: "分",
          pingfen: "猫眼评分",
        },
        {
          id: 1,
          titles: "四海",
          texts:
            "独家行业资源包精准投放，覆盖院线经理等业内核心人员，联动微信生态朋友圈定制化差异传播，主力影片排片上座。",
          imgurl: require("../assets/ceshi2.jpg"),
          office: "5",
          yi: "亿",
          piaoTxt: "累计票房",
          code: "",
          codewei: "",
          pingfen: "",
          No: "1",
          Nowei: "No",
          NoTxt: "内地影视爱情片总场次榜",
        },
        {
          id: 2,
          titles: "这个杀手不太冷静",
          texts:
            "数据赋能直触影视核心受众，定制个性化人群画像，精准覆盖开心麻花粉丝群体，主力影片精准曝光",
          imgurl: require("../assets/ceshi3.webp"),
          office: "10",
          yi: "亿",
          piaoTxt: "累计票房",
          code: "",
          codewei: "",
          pingfen: "",
          No: "2",
          Nowei: "No",
          NoTxt: "22年春节档票房榜",
        },
      ],
      swiperList: [
        {
          id: 0,
          url: "https://img0.baidu.com/it/u=188211079,3502845261&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=702",
        },
        {
          id: 1,
          url: "https://img1.baidu.com/it/u=2989715183,1366455786&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=768",
        },
        {
          id: 2,
          url: "https://bpic.51yuansu.com/pic3/cover/03/16/91/5b5bb385c62a8_610.jpg",
        },
        {
          id: 3,
          url: "https://img2.baidu.com/it/u=2780511291,1959442016&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=684",
        },
        {
          id: 4,
          url: "https://bpic.51yuansu.com/pic3/cover/01/69/71/595f636e68d74_610.jpg",
        },
      ],
      name1: "",
      list1: [],
      name2: "",
      list2: [],
      name3: "",
      list3: [],
      name4: "",
      list4: [],
      name5: "",
      list5: [],
      bannerTitle: "",
      bannerRemark: "",
      bannerImage: "",
      tips1: "",
      tips2: "",
      tips3: "",
      tips4: "",
      tips5: "",
    };
  },
  computed: {
    ...mapState(["navList"]),
  },
  created() {
    this.getBannerList();
    // this.getTips()
    this.$nextTick(() => {
      this.getServiceList();
    });
    this.index = this.$route.params.index;
    this.suoyin = sessionStorage.getItem("i");
    console.log(this.navslist[this.isBorder]);
  },
  mounted() {
    if (this.$route.params.i == 0 || this.$route.params.i) {
      console.log(this.$route.params.i, "this.$route.params.i");


      //  setTimeout(() => {
      //   s[m].scrollIntoView({ behavior: "smooth",block: "start", });
      //  }, 500);

      setTimeout(() => {
        var s = document.getElementsByTagName("section");
        var m = this.$route.params.i;
        console.log("标签实力", s);
        console.log("路由索引", m);
        s &&s[m] && s[m].scrollIntoView({ behavior: "instant", block: "start" });
      }, 300);
    } else {
      console.log(this.$route.params.i, "this.$route.params.i");
      var ss =
        document.getElementsByClassName("container")[0].nextElementSibling;
      console.log(ss, "ssss");

      ss.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  methods: {
   
    getTips() {
      // axios.get('http://sz.yvliang.com/api/index/yingxiao/tips').then(res=>{console.log(res,'tips');})
    },
    getBannerList() {
      axios.get("https://admin.shuzijz.cn/api/index/banner?id=2").then((res) => {
        console.log(res, "banner的数据");
        this.bannerTitle = res.data.data.title;
        this.bannerRemark = res.data.data.remark;
        this.bannerImage = res.data.data.image;
      });
    },
    ...mapActions(["showDialog"]),
    showForm() {
      this.showDialog(true);
    },
    async getServiceList() {
      const { data: res } = await services();
      console.log(res, "services的数据");
      if (res.code == 1) {
        // console.log('数据请求成功');
        this.name1 = res.data.content[0].name;
        this.name2 = res.data.content[1].name;
        this.name3 = res.data.content[2].name;
        this.name4 = res.data.content[3].name;
        this.name5 = res.data.content[4].name;
        this.list1 = res.data.content[0].main;
        this.list2 = res.data.content[1].main;
        this.list3 = res.data.content[2].main;
        this.list4 = res.data.content[3].main;
        this.list5 = res.data.content[4].main;
        this.tips1 = res.data.content[0].tips;
        this.tips2 = res.data.content[1].tips;
        this.tips3 = res.data.content[2].tips;
        this.tips4 = res.data.content[3].tips;
        this.tips5 = res.data.content[4].tips;
        console.log(this.list1, "list1");
      }
    },
    changeCarousel(a) {
      this.$refs["carousel"][a]();
    },
    changeCarouselIdots(i) {
      this.$refs.carousel.setActiveItem(i); // 将tab的下标指定为走马灯的下标
      this.carousdelActive = i; // tab切换的下标
    },
    carouselChange(i) {
      this.carousdelActive = i;
    },
    showLocalModal(i) {
      this.actives = i;
    },
    showBigModal(i) {
      this.hover = i;
    },

    hideBigModal() {
      // this.hover = 0;
    },
    gotobox(i, b, index) {
      // console.log(this.$route.name,'this.$route.name');
      console.log(i, b, index, "service的i,b,index");
      var s = document.getElementsByTagName("section");

      if (this.$route.name == b) {
        var top=s[i].offsetTop-this.$refs.myNav.offsetHeight;
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
        // s[i].scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        sessionStorage.setItem("i", index);
        this.$router.push({
          name: b,
          params: { i: i },
        });
      }
    },
    showBorder(i) {
      this.isBorder = i;
    },

    changeConsultBtn(i) {
      //进入到立即咨询按钮

      this.consultStyleActive = 1;
    },
    gotoHidden(i) {
      this.a = i;
    },
    changeConsultBtnLeave() {
      //离开立即咨询按钮
      this.consultStyleActive = 0;
    },
    changeAI(i) {
      // console.log(i,'iiiii');
      this.a = i;
      this.hideModal(i);
    },
    //  showModal(i){//鼠标进入显示动画
    //   this.a=i

    //  },
    hideModal(i) {
      //鼠标离开动画消失
      this.a = i;
      this.consultStyleActive = 0;
    },
  },
  
};
console.log(document.querySelector("#a1"));

</script>

<style scoped>

  @media screen and (max-width: 900px) {
    .pc {
      display: none !important;
    }
    .txt .pc{
      display: none !important;
    }
    #rpc{
      display: none !important;
    }
      .threes {
          padding-bottom: 10px;
      }
    .mobile{
      display: block !important;
      width: 8rem;
    }
    .mobile li:first-child{
      margin-right: 0 !important;
      margin-bottom: 0.2rem;
      margin-top: 0.3rem !important;
    }
    .d2{
      width: 5.5rem !important;
      margin-left: -0.2rem;
    }
    .d2 span{
      width: 6.8rem;
      display: inline-block;
    }
    .fours .carousel .idots{
    right: 0.042rem !important;
    top: 56% !important;
}
    .fours .carousel .idots button{
      width: 0.8979rem !important;
    height: 0.8979rem !important;
    }
    .fours .carousel .idots button img{
    width: 0.8833rem !important;
    height: 0.8938rem !important;
}
.fours .carousel .idots ul{
  height: 100px !important;
}
.fours .carousel .idots ul .activess{
  border-radius: 0 !important;
}
.fours .carousel .idots ul li{
    width: 14px !important;
    height: 17px !important;
    border-radius: 50% !important;
    margin-bottom: 10px;
    margin-top: 10px;
}
    .myNav {
      background-color: #fff;
    height: auto !important;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
    }
  }

  @media screen and (min-width: 900px) {
    .mobile{
      display: none;
    }
    .pc{
      display: block;
    }
    .myNav {
      background-color: #fff;
      height: 0.375rem;
      position: sticky;
      top: 0;
      z-index: 99;
      left: 0;
      box-sizing: border-box;
    }
  }
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 1.875rem;
  position: absolute;
}

.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: .2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: .1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: .3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: .078125rem
    /* 15/192 */
  ;
}

.bannBox .txt button {
  /* position: absolute; */
  border: 1px solid #FF6A00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius: 5px;
  padding: 0.052083rem
    /* 10/192 */
    0.1875rem
    /* 36/192 */
  ;
  margin-top: .3125rem;
}

.twos .txt button:hover {
  background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
  color: white;
}

/* 立即咨询按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }

  to {
    top: 0.7292rem;
    opacity: 1;
  }
}

@keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }

  to {
    top: 0.7292rem;
    opacity: 1;
  }
}

/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    opacity: 1;
  }
}

@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    opacity: 1;
  }
}

.twos .txt button p {
  margin: 0;
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0 0.0521rem;
  /* align-items: center; */
}

.twos .txt button p img {
  width: 0.0833rem;
  height: 0.0833rem;
}

/*  */
/* .el-carousel {
  width: 6.25rem;
  margin: 0 auto;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .el-carousel__item{
    height: 2.6042rem;
    width: 1.8625rem;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */

/* 第三部分 */
.threes {
  height: 5.2292rem;
  width: 100%;
  padding-top: 0.3385rem;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 249, 245, 0.47) 39%,
      rgba(255, 244, 236, 0.8) 100%);
}

.threes .title {
  font-size: 0.1875rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.2604rem;
  margin-bottom: 0.0729rem;
}

.threes .text {
  width: 3.9063rem;
  height: 0.2917rem;
  font-size: 0.0833rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #626366;
  line-height: 0.1458rem;
  margin: 0 auto;
  margin-bottom: 0.3385rem;
}

.threes .content {
  width: 7.2917rem;
  height: 3.4063rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0.0104rem 0.0885rem 0px rgba(237, 108, 0, 0.1);
  backdrop-filter: blur(0.0625rem);
  margin: 0 auto;
}

.threes .content ul li {
  font-size: 0.125rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.1719rem;
  /* display: flex;
justify-content: space-around;
align-items: center; */
}

.threes .content ol {
  width: 7.2917rem;
  height: 2.9135rem;
}

.threes .content ol li {
  display: flex;
  /* height: 100%; */
  justify-content: space-around;
  align-items: center;
}

.threes .content ol li .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 2.0833rem;
  height: 1.6rem;
}

.threes .content ol li .left .tit {
  font-size: 0.1771rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.25rem;
}

.threes .content ol li .left .texts {
  font-size: 0.1042rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #626366;
  line-height: 0.1458rem;
  width: 1.9896rem;
  text-align: left;
  height: 0.2917rem;
}

.threes .content ol li .left button {
  width: 0.7708rem;
  height: 0.2188rem;
  background: #ffffff;
  border-radius: 0.0208rem;
  border: 1px solid #ff7300;
  color: #ff7300;
  font-size: 0.0833rem;
  font-family: MicrosoftYaHei;
  color: #ff7300;
  line-height: 0.125rem;
}

.threes .content ol li .left button:hover {
  background-color: #ff7300;
  color: white;
}

.threes .content ol li .left .right img {}

.threes .isBorder {
  width: 100%;
  height: 0.0208rem;
  background: linear-gradient(270deg, #ff8c1f 0%, #ff6a00 100%);
  box-shadow: 0px -0.0052rem 0.0417rem 0px rgba(0, 0, 0, 0.14);
  position: absolute;
  top: 0;
  left: 0;
}

/* 第四部分 */
.fours {
  /* width: 9.9948rem; */
  height: auto!important;
  background: #ffffff;
  padding-top: 0.4167rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px;
}

.fours .title{
  margin-bottom: 0.0729rem;
}

.fours .txt {
  width: 4.1667rem;
  height: 0.2865rem;
  margin: 0 auto 0.3385rem auto;
  font-size: 0.0833rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #626366;
  line-height: 0.1458rem;
}

.fours .ul {
  width: 7.2917rem;
  display: flex;
  margin-top: 0.4167rem;
  justify-content: space-between;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.fours .ul li {
  box-sizing: border-box;
  width: 1.75rem;
  height: 1.8438rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 0.0885rem 0px rgba(237, 108, 0, 0.1);
  border-radius: 0.0625rem;
  backdrop-filter: blur(0.0625rem);
  padding-top: 0.3385rem;
  padding-left: 0.1563rem;
  position: relative;
  transition: all 0.6s !important;
}
.ul li{
  width: 100px;
  height: 100px;
  background-color: #ed6d00;
}


/* .ui li:hover{
  background-color: #fdf8f7;
} */

.fours .ul li .banner {
  position: absolute;
  bottom: 0;
  width: 1.1458rem;
  height: 1.1458rem;
  right: 0;
  

}

.fours .ul li .img {
  width: 0.3073rem;
  height: 0.1354rem;
  position: absolute;
  left: 0.1042rem;
  bottom: 0.1563rem;
 

}

.fours .ul li p {
  text-align: left;
  font-size: 0.1354rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.1146rem;
}

.fours .ul .active {
  width: 3.125rem;
  height: 1.8438rem;
  background: linear-gradient(133deg, #ed6d00 0%, #f39800 100%),
    linear-gradient(315deg, #ff8c1f 0%, #ff6a00 100%);
  box-shadow: 0px 0px 0.0521rem 0px rgba(0, 0, 0, 0.06);
  border-radius: 0.0625rem;
  opacity: 0.7;
  padding-top: 0.1615rem;
  padding-left: 0.2865rem;
  position: relative;
  padding-right: 0.2813rem;
  backdrop-filter: blur(0.0104rem);
  transition: all 0.6s;
}

.fours .ul .active .head {
  font-size: 0.125rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.1719rem;
  margin-bottom: 0.1094rem;
}

.fours .ul .active .fetitle {
  font-size: 0.0938rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.1042rem;
  margin-bottom: 0.0729rem;
}

.fours .ul .active .text {
  font-size: 0.0833rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.1146rem;
  margin-bottom: 0.099rem;
}

/*  */
.fours .containers {
  width: 7.2917rem;
  margin-top: 0.625rem;
  margin-left: auto;
  margin-right: auto;
}

.fours .containers .tab {
  height: 0.2656rem;
  width: 2.6rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 0.3125rem;
  margin-left: 0.2083rem;
}

.fours .containers .tab li {
  height: 100%;
  font-size: 0.1458rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.125rem;
  position: relative;
}

.fours .containers .tab li div {
  width: 1.0208rem;
  height: 0.0208rem;
  background: linear-gradient(270deg, #f7a500 0%, #ed6c00 100%);
  border-radius: 0.0313rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.fours .containers .tab .actives {
  height: 100%;
  border-radius: 0.0313rem;
  font-size: 0.1458rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.125rem;
}

.fours .swipers {
  width: 80%;
  height: 4.0rem;
  margin: 0 auto;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
}

.fours .carousel {
  height: 2.3438rem;
  width: 7.2917rem;
  position: relative;
  margin-left:auto ;
  margin-right:auto ;
}

.fours .carousel .el-carousel {
  width: 7.2917rem;
  height: 2.3438rem;
}

.fours .carousel /deep/ .el-carousel__container {
  width: 7.2917rem;
  height: 2.3438rem;
}

.fours .carousel .box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 1.8438rem;
  background: #fffcf9;
  border-radius: 0.0521rem;
  margin-top: 0.2521rem;
}

.fours .carousel .box .lefts {
  width: 2.3438rem;
}

.fours .carousel .box .lefts .titles {
  font-size: 0.125rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.1719rem;
  text-align: left;
  margin-bottom: 0.0833rem;
}

.fours .carousel .box .lefts .txts {
  text-align: left;
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
  margin-bottom: 0.25rem;
}

.fours .carousel .box .rights {
  width: 1.5781rem;
  height: 2.2135rem;

  /* overflow: hidden; */
  position: relative;
}

.fours .carousel .box .rights .img {
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  top: 0;
  border-radius: 0.0521rem;
  left: 0;
}

.fours .carousel .box .rights .shadowimg {
  position: absolute;
  bottom: -0.0521rem;
  width: 1.5781rem;
  height: 2.2135rem;
  z-index: 98;
  right: -0.0521rem;
  border-radius: 0.0521rem;
}

.fours .carousel .idots {
  width: 0.1979rem;
  height: 1.4063rem;
  /* background-color:blue; */
  position: absolute;
  right: 0.1042rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.fours .carousel .idots button {
  width: 0.1979rem;
  height: 0.1979rem;
  background: #ff7a00;
  border-radius: 0.0313rem;
  opacity: 0.27;
  border: none;
  cursor: pointer;
}

.fours .carousel .idots button:hover{
  background: #ff7a00;
  opacity:1;
}

.fours .carousel .idots ul {
  width: 0.0417rem;
  height: 0.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.fours .carousel .idots ul li {
  width: 0.0417rem;
  height: 0.0417rem;
  background: #ff7a00;
  border-radius: 0.0208rem;
  opacity: 0.4;
  cursor: pointer;
}

.fours .carousel .idots ul .activess {
  border-radius: 0;
  opacity: 1;
  background: #ff7a00;
}

.fours .carousel .idots button img {
  width: 0.0833rem;
  height: 0.0938rem;
}

/* ai */
.ai {
  width: 100%;
  height: 5.2rem;
  background: linear-gradient(134deg, #fdf8f7 0%, #fef3ea 48%, #fffcfc 100%);
  /* opacity: 0.45; */
  /* padding-top: .2604rem; */
  /* padding-top: 50px; */
  padding-top: 1.0417rem;
  box-sizing: border-box;
}

.fours .carousel .box .allPrice {
  display: flex;
  height: 0.5rem;
  /* flex-direction: column; */
  justify-content: space-between;
  /* align-items: flex-start; */
}

.fours .carousel .box .allPrice .price-bot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 0.4125rem;
}

.fours .carousel .box .allPrice .price-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 0.4125rem;
}

.fours .carousel .box .first span {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
}

.fours .carousel .box .second {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.1146rem;
  /* margin-bottom: .0521rem; */
}

.ai .title {
  font-size: 0.1875rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.2604rem;
  margin-bottom: 0.3281rem;
}

.ai .content {
  margin: 0 auto;
  width: 7.2917rem;
  /* height: 3.526rem; */
}

.ai .content ul {
  width: 7.2917rem;
  height: 0.4896rem;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-bottom: 0.3125rem;
}

.ai .content ul .ul {
  font-size: 0.1458rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  width: 3.6458rem;
  height: 0.4896rem;
  color: #313233;
  line-height: 330%;
  cursor: pointer;
}

.ai .content ul .active {
  background: linear-gradient(180deg, #ffb576 0%, #ff973f 100%);
  border-radius: 0.0521rem;
  color: white;
}

.ai .content ol {}

.ai .content ol .ol {
  width: 100%;
  height: 100%;
  /* height: ; */
  width: 7.2917rem;
  height: 2.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal {
  overflow: hidden;
}

.showAnimation-enter-active {
  transition: all 1s;
}

.showAnimation-leave-active,
.showAnimation-enter {
  height: 0 !important;
}

.showAnimation-leave,
.showAnimation-enter-active {
  height: 2.414rem;
}

.showAnimations-enter-active {
  transition: all 1s;
}

.showAnimations-leave-active,
.showAnimations-enter {
  height: 0 !important;
}

.showAnimations-leave,
.showAnimations-enter-active {
  height: 2.414rem;
}

.ai .content ol .ol .left {
  width: 50%;
  height: 2rem;
  padding-top: 0.4167rem;
}

.ai .content ol .ol .left .tit {
  font-size: 0.1354rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  /* line-height: 0.1927rem; */
  margin-bottom: 0.1833rem;
  text-align: left;
}

.ai .content ol .ol .left .txt {
  font-size: 0.0833rem;
  text-align: left;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: 0.1458rem; */
  padding-right: 30px;
}

.ai .content ol .ol .right {
  /* width: 3.5417rem;
  height: 2.3438rem; */
  /* flex:1; */
  /* padding-left: 20px; */
  width: 50%;
}

.ai .content ol .ol .right img {
  width: 100%;
  height: auto;
}

/* 立即咨询按钮 */
.consult {
  position: absolute;
  left: 0rem;
  bottom: 0.4969rem;
  border: 1px solid #ff7300;
  color: #ff7300;
  font-size: 0.0833rem;
  width: 0.8333rem;
  height: 0.2188rem;
  border-radius: 0.0208rem;
  background-color: transparent;
  /* background-image: linear-gradient(to right, #ff6a00, #ff8c1f); */
}

.consult p {
  margin: 0;
  color: #ff7300;
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0 0.0521rem;
  /* align-items: center; */
}

.consult p span {
  color: #ff7300;
  font-size: 0.0833rem;
}

.consult p img {
  width: 0.0833rem;
  height: 0.0833rem;
}

/* 鼠标进入显示的画面 */
.sjkbox {
  width: 100%;
    height: 3.2rem;
  /* height: 2.2rem; */
  background-image: url(../assets/index/four-sj.png);
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
}

.sjbox {
  width: 1.268rem;
    height: 81%;
    position: absolute;
    top: 9.5%;
    left: 0.34rem;
    border-radius: 0.1rem;
    overflow: hidden;
}

.sjbox img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.allPrice {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
}

.allPrice .price-top {
  /* width: 1.2rem;
  height: 0.3125rem;
  display: flex;
  align-items: center;
  flex-direction: column; */
}

.price-bot {
  /* width: 1.2rem;
  height: 0.3125rem;
  flex-direction: column; */
}

.allPrice .first {
  /* font-size: 0.2604rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ed6200;
  height: 0.3125rem;
  line-height: 0.3021rem;
  margin-bottom: 0.0573rem; */
  /* width: 0.7375rem; */
  font-size: 0.2083rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  margin-bottom: 0.0521rem;
  color: #ed6200;
  line-height: 0.2448rem;
}

.allPrice .first span {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
}

.allPrice .second {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.1146rem;
}

.ai .content ol .ol ul li {
  width: 100%;
  height: 100%;
}

@keyframes heightshow1 {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    height: 2.3708rem;
    opacity: 1;
  }
}


.modal {
  width: 6.25rem;
  margin: 0 auto
}

.modal .txt {
  margin-bottom: 0.224rem;
  font-size: 0.1458rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.2083rem;
}

.modal .subtxts {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  /* height: 2.2708rem; */
}

.modal ul {
  width: 4.1021rem!important;
  height: auto!important;;
  display: flex!important;;
  flex-direction: column!important;;
  align-items: flex-start!important;;
  justify-content: space-around!important;;
}

.modal ul li {
  display: flex;
  /* align-items: center; */
  margin-bottom: 0.1615rem;
}

.twos {
  background-size: cover !important;
}

/* 手机端适应代码 */
@media screen and (max-width: 767px) {
  ul.ul.moblie {
    display: block !important;
}
ul.ul.pc {
 display: none;
}
    .fours .carousel .box .lefts .titles {
        text-align: center;
    }
  .container {
    height: auto;
    padding: 0 15px;
  }

  .twos {
    margin-top: 60px !important ;
    min-height: 6rem !important;
    background-size: cover !important;
  }

  .twos .txt {
    left: 15px;
    width: 50%;
  margin-top: -0.55rem;
  }

  .twos .txt h3 {
    font-size: 18px;
    line-height: normal;
  }

  .left h3,
  .containerFive .content .right .r-r-txt .h6 {
    font-size: 16px;
    line-height: normal;
  }

  .threes .title {
    font-size: 16px;
    line-height: normal;
  }

  .threes .text,
  .fours .txt {
    font-size: 14px;
    line-height: normal;
    width: 80%;
    height: auto;
  }

  .threes .content {
    width: 90%;
    height: auto;
  }

  .threes .content>ul,
  .threes .content ol,
  .threes,
  .threes .content ol li .left,
  .fours,
  .threes .content ol li .right {
    width: 100% !important;
    height: auto !important;
  }

  .threes .content ul li,
  .threes .content ol li .left .tit {
    font-size: 14px;
    line-height: normal;
  }

  .threes .content ol li {
    flex-direction: column;
  }

  .threes .content ol li .left .texts {
    width: 100% !important;
    height: auto !important;
    line-height: normal;
    font-size: 12px;
    margin: 10px 0;
  }

  .threes .content ol li .left button {
    padding: 0.052083rem
      /* 10/192 */
      0.1875rem
      /* 36/192 */
    ;
    width: auto !important;
    height: auto !important;
    font-size: 14px;
    line-height: normal;
  }

  .threes .content ol li .left {
    padding: 15px;
    box-sizing: border-box;
  }

  .fours .title,
  .ai .title {
    font-size: 16px !important;
    line-height: normal !important;
  }

  .fours .ul,
  .fours .containers {
    width: 80%;
    margin: 15px auto;
    flex-direction: column;
  }

  .fours .ul .active {
    width: 100%;
    height: auto;
  }

  .fours .ul li {
    width: 100%;
    height: 70px;
    padding: 15px 0;
  }

  .fours .ul li p,
  .fours .ul .active .head,
  .ai .content ol .ol .left .tit {
    font-size: 14px;
    line-height: normal;
  }
  .fours .ul li p{
       text-indent: 0.2865rem;
     }
  .fours .ul .active p{
    text-indent: 0rem;
  }
  .fours .ul .active .text,
  .fours .ul .active .fetitle,
  .ai .content ol .ol .left .txt {
    font-size: 12px;
    line-height: normal;
  }

  .fours .ul li .img {
    width: 40px;
    height: auto;
  }

  .bannBox .txt .h5,
  .bannBox .txt button p span {
    font-size: 14px !important;
    line-height: normal;
  }

  .ai .content ul,
  .ai .content ul .ul,
  .ai,
  .ai .content,
  .ai .content ol .ol,
  .ai .content ol .ol .left {
    height: auto !important;
    line-height: normal;
  }

  .ai .content ul .ul {
    padding: 10px 0;
    font-size: 14px;
  }

  .consult {
    height: auto !important;
    position: relative;
    padding: 0.052083rem
      /* 10/192 */
      0.1875rem
      /* 36/192 */
    ;
    width: auto;
    left: auto;
    bottom: auto;
    margin: 15px 0;
  }

  .consult p span {
    font-size: 14px;
  }

  .ai .content ol .ol .left,
  .ai .content ol .ol .right {
    width: 100%;
    height: auto !important;
  }

  .ai .content {
    width: 80%;
  }

  .ai .content ol .ol {
    width: 100%;
    flex-direction: column;
  }

  .fours .containers .tab {
    width: auto;
    justify-content: space-around;
    height: auto;
    margin-left: 0;
  }

  .fours .containers .tab li,
  .fours .containers .tab .actives,
  .fours .carousel .box .lefts .titles {
    line-height: normal;
    font-size: 14px;
  }

  .fours .carousel .box .lefts .txts,
  .fours .carousel .box .second,
  .allPrice .first {
    line-height: normal;
    font-size: 12px;
  }

  .fours .containers .tab li div {
    width: 100%;
    height: 2px;
  }

  .fours .containers .tab li {
    margin-right: 10px;
  }

  .swipersBox[data-v-90b5d9ea] {
    position: relative;
    height: 3.5438rem;
  }

  .fours .carousel {
    width: 80%;
    margin: 0 auto;
  }

  .fours .carousel,
  .fours .carousel .el-carousel,
  .fours .carousel /deep/ .el-carousel__container {
    height: 9.375rem
      /* 1800/192 */
  }

  .fours .carousel .box {
    height: 9.375rem
      /* 1800/192 */
  }

  .fours .carousel .box {
    flex-direction: column;
  }

  .fours .carousel .box .lefts {
    width: 80%;
    height: auto;
  }

  .fours .carousel .box .rights,
  .fours .carousel .box .rights .shadowimg {
    width: 3.15625rem
      /* 606/192 */
    ;
    height: 4.427083rem
      /* 850/192 */
  }

  .modal{
    width: 100%;
  }

  .modal .txt {
    line-height: normal !important;
    font-size: 14px !important;
  }

  .sjkboxmain{
    flex-direction: column;
    height: auto!important;;
  }
  .sjkbox{
    margin-left: 0.75rem
  }

  .modal .r{
    width: 100%!important;
  }

  .modal .r img{
    width:20px!important;
    height: 20px!important;
  }

  .modal .r span{
    font-size: 14px!important;
    line-height: normal!important;
  }

  /* .modal .r li>div:first-child{
    width: 100%!important
  } */

  .allPrice p span{
    font-size: 12px!important;
  }

  .allPrice .first{
    font-size: 20px!important;
    width: 100%!important;
  }

  .allPrice .second{
    font-size: 12px!important;
    width: 100%!important;
  }

  /* .span1{
    width: 100px!important;
  }

   */

   .hh{
    width: auto!important;
   }

   .ai .content ol .ol .right{
    padding-left:0!important;
   }

   .ai .content ul{
    align-items: flex-start!important;
  }
}

.myNav{
  background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
}

</style>