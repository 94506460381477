import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
// 加载 element 组件库
import ElementUI from 'element-ui'
// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
// 全局注册 element 组件库
Vue.use(ElementUI)
import './lib/flexible1'
Vue.config.productionTip = false

import store from './store'
//使用bus总线
const Bus = new Vue()
Vue.prototype.Bus = Bus

import VueAwesomeSwiper from 'vue-awesome-swiper'
import '/node_modules/swiper/css/swiper.min.css'
Vue.use(VueAwesomeSwiper)


import axios from 'axios';
Vue.prototype.$axios=axios;


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

var _hmt = _hmt || [];
window._hmt=_hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?213c711777089f12fdbfd7e4cfc8a2d9";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  if (_hmt) {
      if (to.path) {
          _hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next();
});