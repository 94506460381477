<template>
    <div style="width: 100%;height: 100%;" v-if="showModal==true" class="containerSeven fromBox">
        <div v-if="showModal==true" :value="showModal" class="zzc" @click="hideFormModal"></div>
        <div class="content" style="z-index: 100;position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);">
            <!-- <p @click="hideFormModal" class="x" style=""> x </p> -->
            <div class="closeBtn" @click="hideFormModal">
                <span></span>
                <span></span>
            </div>
            <p class="title">联系我们</p>
            <p class="txt pc">留下您的联系方式，您的专属顾问会在1个工作日内联系您</p>
            <el-form :inline="true" class="demo-form-inline" :model="ruleForm" :rules="rules" ref="ruleFormRef">
                <div class="pc" style="width: 89.5%;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;margin-bottom: .0521rem;">
                    <el-form-item class="formItem" label=" " prop="name" required>

                        <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
                    </el-form-item>
                    <el-form-item label=" " prop="mobile" required>
                        <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
                    </el-form-item>
                </div>
                <div class="pc" style="width: 89.5%;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;margin-bottom: .0521rem;">
                    <el-form-item class="formItem" label=" " prop="company" required>
                        <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
                    </el-form-item>
                    <el-form-item label=" " prop="zhiwei" style="margin-left: .0821rem;">
                        <el-input class="ipt" v-model="ruleForm.zhiwei" placeholder="职位"></el-input>
                    </el-form-item>
                </div>

                <div class="mobile" style="width: 89.5%;margin: 0 auto;;margin-bottom: .0521rem;">
                    <el-form-item class="formItem" label=" " prop="name" required>

                        <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
                    </el-form-item>
                </div>
                <div class="mobile" style="width: 89.5%;margin: 0 auto;;margin-bottom: .0521rem;">

                <el-form-item label=" " prop="mobile" required>
                        <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
                    </el-form-item>
                </div>
                <div class="mobile" style="width: 89.5%;margin: 0 auto;;margin-bottom: .0521rem;">
                <el-form-item class="formItem" label=" " prop="company" required>
                        <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
                    </el-form-item>
                </div>
                <div class="mobile" style="width: 89.5%;margin: 0 auto;;margin-bottom: .0521rem;">

                <el-form-item label=" " prop="zhiwei">
                        <el-input class="ipt" v-model="ruleForm.zhiwei" placeholder="职位"></el-input>
                    </el-form-item>
                </div>

                <div class="formItem-last-box pc">
                    <el-form-item class="formItem-last" style="position: relative;">
                        <el-input style="height: 100%;" type="textarea" class="textArea" rows="3"
                                  placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）" v-model="ruleForm.remark">
                        </el-input>
                        <ul class="tips">
                            <span @click="showTxt('影视宣发\n')">影视宣发</span>
                            <span @click="showTxt('品牌营销\n')">品牌营销</span>
                            <span @click="showTxt('精准PR\n')">精准PR</span>
                        </ul>
                    </el-form-item>
                </div>
                <div class="formItem-last-box mobile" style="width: 89.5%;margin:0 auto;">
                    <el-form-item class="formItem-last" style="position: relative;height: 100px;width: calc(100% - 20px) !important;left: 10px;">
                        <el-input style="height: 100%;font-size: 12px!important;padding-left:10px;box-sizing: border-box;" type="textarea" class="textArea" rows="3"
                                  placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）" v-model="ruleForm.remark">
                        </el-input>
                        <ul class="tips">
                            <span @click="showTxt('影视宣发\n')">影视宣发</span>
                            <span @click="showTxt('品牌营销\n')">品牌营销</span>
                            <span @click="showTxt('精准PR\n')">精准PR</span>
                        </ul>
                    </el-form-item>
                </div>
            </el-form>
            <button @click="submitFormModal('ruleFormRef')">提交</button>
        </div>

    </div>


</template>

<script>
  import {submitForm} from '@/api/form';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import axios from 'axios';

  export default {
    data() {
      return {
        ruleForm: {
          name: '',
          mobile: '',
          company: '',
          zhiwei: '',
          remark: ''
        },
        formRef: {},
        rules: {
          name: [
            {required: true, message: '请输入联系人姓名', trigger: 'blur'},
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          mobile: [
            {required: true, message: '请输入手机号', trigger: 'blur'},

          ],
          company: [
            {required: true, message: '请输入企业名称', trigger: 'blur'}
          ]
        },
        showModal: false
      };
    },
    created() {
      // this.showFormModal=this.showFormModal
    },
    computed: {
      ...mapState(['showFormModal'])
    },
    watch: {
      showFormModal(val) {
        this.showModal = val;
      }
    },
    methods: {
      submitFormModal(ruleFormRef) {
        this.$refs[ruleFormRef].validate(async (valid) => {
          if (valid) {
            var params = {
              options: {
                name: this.ruleForm.name,
                mobile: this.ruleForm.mobile,
                company: this.ruleForm.company,
                province: "",
                position: this.ruleForm.zhiwei,
                remark: this.ruleForm.remark
              },
              key: 'szjz_website_leads'
            };
            axios.post('https://www.shuzijz.cn/api/edm/v1/sender/template', params)
            // axios({
            //   method: "post",
            //   url: 'https://www.shuzijz.cn/api/edm/v1/sender/template',
            //   data: params
            // })
              .then(res => {
                console.log(res);
                if (res.data.code == 0) {
                  this.$message({
                    message: res.data.message,
                    type: 'error'
                  });
                }
                if (res.data.code == 1) {
                  this.$message({
                    message: res.data.message,
                    type: 'success'
                  });
                  this.$refs.ruleFormRef.resetFields();
                }
              }).catch(err => {
              console.log(err, 'err');
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
      // async submitFormData(){
      //   console.log(this.formData,'表单里的数据');
      //   const { data:res} =await submitForm({
      //     formData:this.formData
      //   })
      //   console.log(res,'表单提交的res');
      // },
      ...mapActions(['showDialog']),
      hideFormModal() {
        this.showDialog(false);
      },

      showTxt(t) {
        this.ruleForm.remark += t;
      }

    }
  };
</script>

<style scoped>

    @media screen and (max-width: 900px) {
        .tips {
            height: 20px;
            bottom: 18px;
            left: 10px;
        }
        .pc {
            display: none !important;
        }

        .mobile {
            display: block;
        }
        .el-textarea /deep/ .el-textarea__inner {
            font-size: 12px !important;
        }
        /*el-input__inner*/
        ::v-deep .el-form-item__content .el-input__inner {
            font-size: 12px !important;
        }
    }

    @media screen and (min-width: 900px) {
        .mobile {
            display: none;
        }

        .pc {
            display: block;
        }

    }

    .zzc {
        position: fixed;
        z-index: 99;
        background-color: rgba(0, 0, 0, .2);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    /* 表单 */
    .x {
        position: absolute;
        width: .0825rem;
        height: .0825rem;
        font-size: .133rem;
        color: #999;
        right: 0.1rem;
        top: 0.1rem;
        z-index: 101;
    }

    .content {
        width: 5.5083rem;
        padding-top: .2083rem;
        /* height:2.8042rem; */
        background: #FFFFFF;
        z-index: 23;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        border-radius: .0417rem;
        position: relative;
        border: 1px solid rgba(212, 214, 218, 0.5);
    }

    .title {
        color: #222;
        font-size: .1458rem;
        line-height: .2083rem;
        margin-bottom: .0833rem;
    }

    .txt {
        color: #999;
        /* margin-top: ; */
        font-size: .0833rem;
        line-height: .1146rem;
        margin-bottom: .2083rem;
    }

    /deep/ .el-form-item__content {
        line-height: .2083rem;
        font-size: .0729rem;
    }

    /deep/ .el-input {
        font-size: .0729rem !important;
    }

    /deep/ .el-input__inner {
        width: 2.3125rem;
        height: .2292rem;
        /* font-family: Avenir, Helvetica, Arial, sans-serif!important;  */
        letter-spacing: 0px !important;
    }

    /deep/ .textArea {
        width: 4.7917rem;
        height: 10.5rem;
        /* border: 1px solid red; */
        position: relative;
        padding-bottom: 0.2rem;
    }

    /deep/ .ipt {
        width: 2.3125rem;
        height: .2292rem;

    }

    /deep/ .el-form-item__label {
        padding: 0 !important;

    }

    /deep/ .el-form-item {
        margin-bottom: 15px !important;
        display: flex !important;
        /* width: 2.4716rem ; */
    }

    /deep/ .el-form-item__label {
        line-height: .2083rem !important;
    }

    /deep/ [data-v-64762e48] .el-form-item__label {
        line-height: 0.29rem !important;
    }

    /deep/ .el-form--inline .el-form-item__label {
        line-height: 0.29rem;
    }

    /deep/ .formItem {
        width: 1/4716rem;

        display: inline-block;
    }

    /deep/ .formItem :nth-child(2n) {
        /* margin-left: .1563rem; */
    }

    /deep/ .formItem-last {
        width: 4.6rem;
        position: relative;
        border: 1px solid #DCDFE6;
        border-radius: 0.521rem;
        height: 0.6rem;
    }

    /deep/ .el-form--inline .el-form-item__content {
        height: 100% !important;
        width: 100% !important;
    }

    /deep/ .el-textarea__inner {
        border: none !important;
        /* font-weight: 400; */
        color: #999999 !important;
        font-size: .0729rem !important;
        letter-spacing: 0px;
        font-family: Avenir, Helvetica, Arial, sans-serif !important;
        /* font-family: PingFangSC-Regular, PingFang SC; */
    }

    ::v-deep .el-input__inner {
        font-size: .0729rem !important;
        /* font-family: PingFangSC-Regular, PingFang SC; */
        font-weight: 400;
        color: #999999;
        /* line-height: .1042rem; */
        letter-spacing: 0px;
        padding: 0 10px !important;

    /* input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
        color:#666;/*适配
        font-size:16px;
    } */
    .searchbox-input:-ms-input-placeholder {
        /* Internet Explorer 10+  适配 IE */
        color: #fff;
        font-size: 20px;
    }

    /* font-weight: 600; */
    /* &::placeholder {
        color: #fff;
    } */

    /*  &::-webkit-input-placeholder {
          WebKit browsers 适配谷歌
          /* color: #fff;
      } */

    /* &:-moz-placeholder { */
    /* Mozilla Firefox 4 to 18 适配火狐 */
    /* color: #fff;
} */

    /* &::-moz-placeholder { */
    /* Mozilla Firefox 19+ 适配火狐 */
    /* color: #fff;
} */

    /* &:-ms-input-placeholder { */
    /* Internet Explorer 10+  适配ie*/
    /* color: #fff;
} */
    }
    .tips {
        width: 100%;
        position: absolute;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        bottom: 0.01rem;
        box-sizing: border-box;
        height: 0.2rem;
    }

    .tips span {
        height: .0929rem;
        line-height: .0969rem;
        background-color: #bfbfbf;
        border-radius: .0521rem;
        color: white;
        padding: .0104rem .0417rem;
        font-size: .0625rem;
        margin: 0 0.05rem;

    }

    .content button {
        width: 2.0833rem;
        height: .25rem;

        border-radius: .0208rem;
        font-size: .0833rem;
        font-family: MicrosoftYaHei;
        /* border: 1px solid #FF6A00;
        color: #FF6A00;
        background-color: transparent; */
        border: none;
        color: #FFFFFF;
        background: #FD7D10 linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%);
        line-height: .125rem;
        position: absolute;
        bottom: .20rem;
        left: 50%;
        transform: translateX(-50%);
    }

    /* .content button:hover{
      border: none;
    color: #FFFFFF;
    background: #FD7D10 linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%);
    } */

    .closeBtn {
        display: block;
        width: 12px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        right: .145833rem /* 28/192 */;
        top: .145833rem /* 28/192 */;
    }

    .closeBtn span {
        display: block;
        width: 100%;
        height: 1px;
        transform: rotate(45deg);
        top: 7px;
        position: absolute;
        left: 0;
        background-color: #999999;
    }

    .closeBtn span:last-child {
        transform: rotate(-45deg);
        top: 7px;
    }

    @media screen and (max-width: 767px) {
        .containerSeven .title {
            font-size: 16px !important;
            line-height: normal !important;
        }

        .containerSeven .txt {
            font-size: 14px !important;
            line-height: normal !important;
        }

        /* .el-form>div{
          display: block!important;
        } */
        .containerSeven .content {
            width: 80%;
            margin: 0 auto;
        }

        .containerSeven /deep/ .el-form-item__content {
            flex: 1
        }

        .containerSeven /deep/ .el-form-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        .containerSeven /deep/ .el-input__inner {
            width: 100%;
            padding: 10px;
            height: 30px;
        }

        .containerSeven {
            padding: 15px 0 !important;

        }

        .ipt {
            width: 100% !important;;
            height: auto !important;
        }

        /deep/ .el-form-item__label {
            width: 20px

        }

        .tips span {
            font-size: 12px !important;
            line-height: normal !important;
            height: auto !important;
        }

        /deep/ .textArea, /deep/ .formItem-last {
            width: 100%;
        }

        .tips {
            bottom: 14px;
            left: 10px;
        }

        .containerSeven .content button {
            padding: 5px 15px;
            height: auto !important;
            line-height: normal !important;
            position: relative;
            left: auto;
            bottom: auto;
            transform: translateX(0%);
            margin: 15px 0 0 0;
            font-size: 14px !important;
        }

        .closeBtn {
            right: 10px;
            top: 10px;
        }
    }

    .formItem-last {
        display: inline-block !important;
        width: 89% !important;
        border-radius: 4px;
    }

    .fromBox /deep/ .el-textarea__inner {
        padding: 5px 0 !important;
    }

    .formItem-last-box {
        margin-left: 10px;
    }
    /deep/ .el-textarea__inner {
        resize: none;
    }
</style>