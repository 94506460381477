<template>
  <div>
    <div ref="myNav" class="myNav">
    <navView @gotosection="gotobox" :suoyin="suoyin" :as="as"/>
    </div>
    <!-- 第一部分 -->
    <!-- <div class="ones" style="height: 2.8125rem;width: 100%;position: relative;">
        <div class="txt">
            <h3 style="color: black;">{{bannerTitle}}  </h3>
            <p class="h5" v-if="bannerRemark!=''">{{bannerRemark}}</p>
            <button @click="showForm" style="width: .8333rem;height: .2188rem;border-radius:.0208rem;">
            <p><span>立即咨询 </span> </p>
           
            </button>
          </div>
        <img :src="bannerImage" style="position: absolute;left: 0;top: 0; width: 100%;height:100%;" alt="" />
      </div> -->
    <div class="ones bannBox" :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }">
      <div class="txt">
        <h3>{{ bannerTitle }} </h3>
        <!-- <p class="h5" v-if="bannerRemark != ''">{{ bannerRemark }}</p> -->
		<p class="h5">&nbsp</p>
        <button @click="showForm">
          <p><span>立即咨询 </span></p>
        </button>
      </div>
      <!-- <img :src="bannerImage" style="position: absolute;left: 0;top: 0; width: 100%;height:100%;" alt="" /> -->
    </div>
    <!-- 侧边栏 -->
    <sliderView />
    <!-- 第二部分 -->
    <main class="twos">
      <p class="title" style="color: black;font-weight: bold;">{{ list.name }}</p>
      <div class="content">
        <ul class="dingwei" ref="dingwei" id="dw">
          <li  :class="as == 0 ? 'nav' : ''" @click="gotofebox(0)" @mouseover="gotofebo(0)">
            <img :src="navsList[0].url" alt="">
            <span style=""> {{ list.main[0].title }}</span>
          </li>
          <li  :class="as == 1 ? 'nav' : ''" @click="gotofebox(1)" @mouseover="gotofebo(1)">
            <img :src="navsList[1].url" alt="">
            <span style=""> {{ list.main[1].title }}</span>
          </li>
          <li  :class="as == 2 ? 'nav' : ''" @click="gotofebox(2)" @mouseover="gotofebo(2)">
            <img :src="navsList[2].url" alt="">
            <span style=""> {{ list.main[2].title }}</span>
          </li>
          <li  :class="as == 3 ? 'nav' : ''" @click="gotofebox(3)" @mouseover="gotofebo(3)">
            <img :src="navsList[3].url" alt="">
            <span style=""> {{ list.main[3].title }}</span>
          </li>
        </ul>
        <ol>
          <li class="l" style="  background: #F9FAFA; ">
            <div class="right">
              <img src="../assets/technology/twos-l-01.png" alt="">
              <div class="txt">
                <p class="tit">
                  {{ list.main[0].title }}
                </p>
                <div class="text"> {{ list.main[0].content }}</div>
              </div>
            </div>
            <div class="left">
              <img :src="list.main[0].image" alt="">
            </div>
          </li>
          <li class="l">
            <div class="left pc">
              <img :src="list.main[1].image" alt="">
            </div>
            <div class="right">
              <img src="../assets/technology/twos-l-02.png" alt="">
              <div class="txt">
                <p class="tit">
                  {{ list.main[1].title }}
                </p>
                <div class="text"> {{ list.main[1].content }}</div>
              </div>
            </div>
            <div class="left mobile">
              <img :src="list.main[1].image" alt="">
            </div>
          </li>
          <li class="l" style=" background: #F9FAFA;">
            <div class="right">
              <img src="../assets/technology/twos-l-03.png" alt="">
              <div class="txt">
                <p class="tit">
                  {{ list.main[2].title }}
                </p>
                <div class="text">{{ list.main[2].content }}</div>
              </div>
            </div>
            <div class="left">
              <img :src="list.main[2].image" alt="">
            </div>
          </li>
          <li class="l">
            <div class="left pc">
              <img :src="list.main[3].image" alt="">
            </div>
            <div class="right">
              <img src="../assets/technology/twos-l-04.png" style="" alt="">
              <div class="txt">
                <p class="tit">
                  {{ list.main[3].title }}
                </p>
                <div class="text"> {{ list.main[3].content }}</div>
              </div>
            </div>
            <div class="left mobile">
              <img :src="list.main[3].image" alt="">
            </div>
          </li>

        </ol>
      </div>
    </main>
    <!-- 联系我们 style="height: 3.0313rem;"-->
    <!-- <section >
      <div class="containerSeven">
        <div class="content">
          <p class="title" style="color: black;font-weight: bold">联系我们</p>
          <p class="txt">留下您的联系方式，您的专属顾问会在1个工作日内联系您</p>
          <el-form :inline="true" class="demo-form-inline" :model="formData" ref="formRef" :rules="formRules">
          <el-form-item class="formItem">
            <el-input class="ipt" v-model="formData.username"  placeholder="联系人姓名"></el-input>
            <el-input class="ipt" v-model="formData.mobile" placeholder="手机号码"></el-input>
     
          </el-form-item>
          <el-form-item class="formItem">
            <el-input class="ipt" v-model="formData.title"  placeholder="企业名称"></el-input>
            <el-input class="ipt" v-model="formData.job"  placeholder="职位"></el-input>
          </el-form-item>
          <el-form-item class="formItem-last" style=";">
            <el-input type="textarea" class="textArea" rows="5" style="border: none;" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）" v-model="formData.needs"></el-input>
            <ul class="tips">
              <span @click="showTxt('影视宣发\n')" >影视宣发</span>
                <span  @click="showTxt('品牌营销\n')">品牌营销</span>
                <span  @click="showTxt('精准PR\n')">精准PR</span>
            </ul>


          </el-form-item>
          </el-form>
          <button>提交</button>
        </div>
      </div>
    </section> -->
    <section>
      <div class="containerSeven">
        <div class="content">
          <p class="title pc" >联系我们</p>
          <p class="title mobile" style="padding-bottom: 10px">联系我们</p>
          <p class="txt pc">留下您的联系方式，您的专属顾问会在1个工作日内联系您</p>

          <el-form :inline="true" class="demo-form-inline pc" :model="ruleForm" :rules="rules" ref="ruleFormRef">
            <div
              style="  width: 94%;justify-content: space-between; margin: 0 auto; display: flex;align-items: center;margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="name" required>

                <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
              </el-form-item>
              <el-form-item label=" " prop="mobile" required>
                <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
              </el-form-item>
            </div>
            <div
              style="  width: 94%;margin: 0 auto;justify-content: space-between;  display: flex;align-items: center;margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="company" required>
                <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item label=" " prop="zhiwei" style=" margin-left: .0921rem;">
                <el-input class="ipt" v-model="ruleForm.zhiwei" style="width: 100%;" placeholder="职位"></el-input>
              </el-form-item>
            </div>
            <div class="formItem-last-box">
            <el-form-item class="formItem-last" style="position: relative;">
              <el-input type="textarea" class="textArea" rows="5" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                v-model="ruleForm.remark">
              </el-input>
              <ul class="tips">
                <span @click="showTxt('影视宣发\n')">影视宣发</span>
                <span @click="showTxt('品牌营销\n')">品牌营销</span>
                <span @click="showTxt('精准PR\n')">精准PR</span>
              </ul>
            </el-form-item>
          </div>
          </el-form>
          <el-form :inline="true" class="demo-form-inline mobile" :model="ruleForm" :rules="rules" ref="ruleFormRef">
            <div
                    style="  width: 94%; margin: 0 auto; margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="name" required>

                <el-input class="ipt" v-model="ruleForm.name" placeholder="联系人姓名" label-width="0"></el-input>
              </el-form-item>
            </div>
            <div
                    style="  width: 94%; margin: 0 auto; margin-bottom: .0521rem;">
              <el-form-item label=" " prop="mobile" required>
                <el-input class="ipt" v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
              </el-form-item>
            </div>
            <div
                    style="  width: 94%; margin: 0 auto; margin-bottom: .0521rem;">
              <el-form-item class="formItem" label=" " prop="company" required>
                <el-input class="ipt" v-model="ruleForm.company" placeholder="企业名称"></el-input>
              </el-form-item>
            </div>
            <div
                    style="  width: 94%; margin: 0 auto; margin-bottom: .0521rem;">
              <el-form-item label=" " prop="zhiwei" >
                <el-input class="ipt" v-model="ruleForm.zhiwei" style="width: 100%;" placeholder="职位"></el-input>
              </el-form-item>
            </div>
            <div class="formItem-last-box mobile" style="height: 150px;width: 94%;margin: 0 auto;box-sizing: border-box;padding-left: 20px;">
              <el-form-item class="formItem-last" style="position: relative;width: calc(100% - 3px) !important;">
                <el-input type="textarea" class="textArea" style="padding-left: 10px;box-sizing: border-box" rows="5" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                          v-model="ruleForm.remark">
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
            <div class="formItem-last-box pc">
              <el-form-item class="formItem-last" style="position: relative;">
                <el-input type="textarea" class="textArea" rows="5" placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                          v-model="ruleForm.remark">
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
          </el-form>
          <button @click="submitFormModal('ruleFormRef')">提交</button>
        </div>
      </div>
    </section>

    <!-- 底部 -->
    <bottomView @gotosection="gotobox" />
    <!-- 立即咨询弹框 -->
    <formView />
  </div>
</template>
  
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { technology } from '@/api/technology'
import swiperView from "../components/swiper.vue";
import navView from "../components/nav.vue";
import bottomView from "../components/bottom.vue";
import sliderView from "../components/slider.vue";
import formView from '../components/form.vue'
import axios from 'axios';
export default {
  components: {
    swiperView,
    navView,
    bottomView,
    sliderView,
    formView
  },
  data() {
    return {
      suoyin: 0,
      navsList: [
        {
          url: require('../assets/technology/01.png'),
          text: '独立一方的数据源',
          txt: '以9.47亿自采集的真实设备为基础，对目标TA精准建模。数据毫秒级更新，确保实时有效。设备ID全媒体流通，可实现在任何媒体的精准触达'
        },
        {
          url: require('../assets/technology/02.png'),
          text: '专业投放能力',
          txt: '以网络定向为核心，依赖于自有数据源构建的WiFi设备库，可锁定目标公司、商圈、家庭等人群圈层，通过模型算法和程序化组合投放，精准触达，构建以网络环境为基础的定向方式。'
        },
        {
          url: require('../assets/technology/03.png'),
          text: '精准人群感知能力',
          txt: '基于对地理位置、网络位置的数据构建，可对种子人群进行特征分析及关联设备感知。 实现公司&家庭关联设备、同特征设备的lookalike'
        },
        {
          url: require('../assets/technology/04.png'),
          text: '跨媒体联合频控',
          txt: '跨媒体设备ID打通，实现对目标人群的全局覆盖。智能分析广告投放数据，匹配该设备下的其他媒体触媒习惯，通过人群与媒体组合的优选策略与联合频控，提升人群触达精准度和流量利用率。'
        }
      ],
      as: '',
      ruleForm: {
        name: '',
        mobile: '',
        company: '',
        zhiwei: '',
        remark: ''
      },
      formRef: {},
      rules: {
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },

        ],
        company: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      },
      list: [],
      bannerImage: '',
      bannerRemark: '',
      bannerTitle: ''
    }
  },
  watch: {
    'ruleForm.mobile': {
      handler(newVal) {
        if (!newVal) {
          this.ruleForm.mobile = ''
          return false
        }
        // 实时把非数字的输入过滤掉
        this.ruleForm.mobile = newVal.match(/\d/gi) ? newVal.match(/\d/gi).join('') : ''
      },
      deep: true,
    },



  },
  computed: {
    ...mapState(['navList'])
  },
  created() {
    this.getBannerList()
    this.getTechnologtList()
    this.suoyin = sessionStorage.getItem('i')
    // console.log(this.suoyin,'suoyin');
    window.addEventListener('scroll', function() {
  var isMobile = window.matchMedia('(max-width: 767px)').matches;
  var isMobil = window.innerWidth <= 767;
  var isMobi = typeof window.Touch !== 'undefined';

  if (isMobile && isMobil && isMobi) {
    var scrollDistance = window.scrollY;
    console.log('滚动距离：', scrollDistance);

    var targetHeight = 359; // 指定的高度
    var minScrollDistance = 200; // 当滚动距离小于200时移除类
    var maxScrollDistance = 1600; // 当滚动距离大于800时移除类

    var element = document.getElementById('dw');
    if (scrollDistance >= targetHeight) {
      // 添加固定样式类
      element.style.position = 'fixed';
      element.style.top = '60px';
    } else if(window.innerWidth>700){
      // 移除固定样式类
      element.style.removeProperty('position');
    }
    if (scrollDistance >= maxScrollDistance || scrollDistance <= minScrollDistance) {
      // 移除固定样式类
      element.style.removeProperty('position');
    }
  }
});
  },
  mounted() {
    console.log(this.$route.params, 'params');
    if (this.$route.params.i >= 0) {
      var s = document.getElementsByClassName('l')
      let m = this.$route.params.i
      this.as = this.$route.params.i
      setTimeout(() => {
        this.gotofebox(m)
        // s[m].scrollIntoView({ behavior: "smooth", block: "start", });
      }, 500);
      console.log(this.as, 'as');
    }
  },
  beforeDestroy() {
  },
  methods: {
    getBannerList() {
      axios.get('https://admin.shuzijz.cn/api/index/banner?id=4').then(res => {
        console.log(res, 'banner的数据');
        this.bannerTitle = res.data.data.title
        this.bannerRemark = res.data.data.remark
        this.bannerImage = res.data.data.image

      })
    },
    ...mapActions(['showDialog']),
    showForm() {
      this.showDialog(true)
    },
    async getTechnologtList() {
      const { data: res } = await technology()
      console.log(res, '技术优势的数据');
      this.list = res.data.content[0]
      console.log(this.list);
    },
    gotobox(i, b, index) {
      // console.log(i,b,index,'technology页的i,b,index');
      var s = document.getElementsByClassName('l')
      this.as = i
      
      if (this.$route.name == b) {
        var top=s[i].offsetTop-this.$refs.dingwei.offsetHeight-this.$refs.myNav.offsetHeight;
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
        // s[i].scrollIntoView({ behavior: "smooth", block: "start", });
      } else {
        sessionStorage.setItem('i', index)
        this.$router.push({
          name: b,
          params: { i: i }
        })
      }
    },
    showTxt(t) {
      this.ruleForm.remark += t
    },
    gotofebo(index){
      this.as = index
    },
    gotofebox(index) {
      var pageWidth = window.innerWidth;
      if(pageWidth>1000){
       
      var s = document.getElementsByClassName('l');
      console.log(this.$refs.dingwei.offsetHeight,"dingwei");
      console.log(this.$refs.myNav.offsetHeight,"myNav");
      console.log(s[index].offsetTop,"llllll");
     
    
      var top=s[index].offsetTop-this.$refs.dingwei.offsetHeight-this.$refs.myNav.offsetHeight;
      window.scrollTo({
          top: top,
          behavior: "smooth"
      });
      // console.log(this.as, 'this.assasa');
      // s[index].scrollIntoView({ behavior: "smooth", block: "start", });
      }
       else{
        this.as = index
      var s = document.getElementsByClassName('l');
      // console.log(this.$refs.dingwei.offsetHeight,"dingwei");
      // console.log(this.$refs.myNav.offsetHeight,"myNav");
      // console.log(s[index].offsetTop,"llllll");
      var scrollDistance = window.scrollY;
      var targetHeight = 350; // 指定的高度
      if (scrollDistance >= targetHeight) {
        // 具有固定定位样式的情况下执行的代码
        var distance = 110; // 设定增加的滚动距离
      // var top = s[index].offsetTop - this.$refs.dingwei.offsetHeight - distance;
      var top = s[index].offsetTop - this.$refs.myNav.offsetHeight - distance;
       
      console.log(11111111111111111111);
      } else {
        console.log(222221);
        // 不具有固定定位样式的情况下执行的代码
        var distance = 170; // 设定增加的滚动距离
      var top = s[index].offsetTop - this.$refs.dingwei.offsetHeight - distance;
      
      }
    
      window.scrollTo({
          top: top,
          behavior: "smooth"
      });
      }
    },
    submitFormModal(ruleFormRef) {
      this.$refs[ruleFormRef].validate(async (valid) => {
        if (valid) {
          var params = {
            options: {
              name: this.ruleForm.name,
              mobile: this.ruleForm.mobile,
              company: this.ruleForm.company,
              province: "",
              position: this.ruleForm.zhiwei,
              remark: this.ruleForm.remark
            },
            key: 'szjz_website_leads'
          }
          axios.post('https://www.shuzijz.cn/api/edm/v1/sender/template', params)
            // axios({
            //   method: "post",
            //   url: 'https://www.shuzijz.cn/api/edm/v1/sender/template',
            //   data: params
            // })
            .then(res => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                });
              }
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.$refs.ruleFormRef.resetFields()
              }
            }).catch(err => {
              console.log(err, 'err')
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
  }
}
</script>
  
<style scoped>

  @media screen and (max-width: 900px) {
    .myNav {
    background-color: #fff;
    height: auto !important;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
  }
    .mobile{
      display: block;
    }
    .el-textarea /deep/ .el-textarea__inner {
      font-size: 12px !important;
    }
    .pc{
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    .myNav {
      background-color: #fff;
      height: 0.375rem;
      position: sticky;
      top: 0;
      z-index: 99;
      left: 0;
      box-sizing: border-box;
    }
    .mobile{
      display: none;
    }
    .pc{
      display: block;
    }
  }
/* 第一部分 */

.fixed{
  position: fixed;
  top: 0.3rem;
}
.dwa{
  position: fixed !important;
    top: 0.1rem !important;

}
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 1.875rem;
  position: absolute;
  /* margin-top: 20%; */
}

.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: .2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: .1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: .3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: .078125rem
    /* 15/192 */
  ;
}

.bannBox .txt button {
  /* position: absolute; */
  padding: 0;
  border: 1px solid #FF6A00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius: 5px;
  padding: 0.052083rem
    /* 10/192 */
    0.1875rem
    /* 36/192 */
  ;
  margin-top: .3125rem;
}

.bannBox .txt button:hover {
  color: white;
  background-image: linear-gradient(to right, #FF6A00, #FF8C1F);
}

.bannBox .txt button p {
  margin: 0;
  padding: 0 .0521rem;
  font-size: .0833rem;
}

.bannBox .txt button p span {
  font-size: .0833rem;
}


/* 立即咨询按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    ;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }

}

@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    ;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: .9292rem;
    opacity: 0.5;
  }

  to {
    top: .7292rem;
    opacity: 1;
  }
}

@keyframes swiperH3 {
  from {
    top: .9292rem;
    opacity: 0.5;
  }

  to {
    top: .7292rem;
    opacity: 1;
  }
}

/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    ;
    opacity: 1;
  }
}

@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    ;
    opacity: 1;
  }
}

.twos .txt button p {
  margin: 0;
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0 .0521rem;
  /* align-items: center; */
}

.bannBox .txt button p img {
  width: 0.0833rem;
  height: 0.0833rem;
}

/* 第二部分 */
.twos {
  padding-top: .4167rem;
  font-size: .2083rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: .2917rem;
  margin-bottom: .1719rem;
}

.twos .title {
  margin-bottom: .1719rem;
}

.twos .content {

  width: 7.2917rem;
  margin: 0 auto;
}

.twos ul {
  margin-bottom: .2083rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.twos ul .nav {
  background-image: url(../assets/technology/05-bg.png);
  background-size: cover;
  border: none;
}

.twos ul .nav img {
  display: none;
}

.twos ul li {

  width: 1.4844rem;
  height: .3229rem;
  border-radius: .0208rem;
  border: 1px solid #ECECEC;
  text-align: center;
  vertical-align: middle;
}

.twos ul li img {

  width: .1875rem;
  height: .1875rem;
  vertical-align: middle;
}

.twos ul li span {
  vertical-align: middle;
  font-size: .0938rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202124;
  line-height: .1302rem;
}

.twos ol {
  width: 7.2917rem;
  /* height: 2.6563rem; */

  border-radius: .0625rem;
}

.twos ol li {

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .2083rem;
}

.twos ol li .right {
  text-align: left;
  height: 1.8rem;
  padding-left: .3125rem;
}

.twos ol li .left {
  width: 3.9417rem;
  height: 2.6563rem;
}

.twos ol li .left img {
  width: 100%;
  height: 100%;
}

.twos ol li .right img {
  width: .3229rem;
  height: .3229rem;
  margin-bottom: .2188rem;
}

.twos ol li .right .txt {
  width: 2.5875rem;
}

.twos ol li .right .tit {

  font-size: .1354rem;
  margin-bottom: .1042rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: .1927rem;
}

.twos ol li .right .text {
  width: 2.6875rem;
  height: .375rem;
  font-size: .0833rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: .125rem;
}

/* 联系我们 */
.containerSeven {
  background-image: url(../assets/index/seven-bg.png);
  /* height: 3.0313rem; */
  background-size: cover;

  background-repeat: no-repeat;
  padding: .2083rem 2.3958rem;
  box-sizing: border-box;
}

.containerSeven .content {
  width: 5.2083rem;
  padding-top: .2083rem;
  /* width: 1001px; */
  /* height:2.6042rem; */
  background: #FFFFFF;
  z-index: 23;
  box-sizing: border-box;
  border-radius: .0417rem;
  position: relative;
  padding-bottom: 0.5rem;
  border: 1px solid rgba(212, 214, 218, 0.5);
}

.containerSeven .title {
  color: #222;
  font-size: .1458rem;
  line-height: .2083rem;
  margin-bottom: .0833rem;
}

.containerSeven .txt {
  color: #999;

  font-size: .0833rem;
  line-height: .1146rem;
  margin-bottom: .2083rem;
}

.containerSeven /deep/ .el-form-item__content {
  line-height: .2083rem;
  font-size: .0729rem;
}

.containerSeven /deep/ .el-input__inner {
  width: 2.3125rem;
  height: .2292rem;
}

/deep/ .textArea {
  width: 4.7917rem;
  /* height: .5rem; */
  font-size: .0729rem;
  padding-bottom: 0.2rem;
}

/deep/ .ipt {
  width: 2.3125rem;
  height: .2292rem;

}

/deep/ .el-form-item__label {
  padding: 0 !important;

}

/deep/ .el-form-item {
  margin-bottom: .1146rem;
  /* width: 2.4716rem ; */
}

/deep/ .el-form-item__label {
  line-height: .2083rem !important;
}

/deep/ [data-v-64762e48] .el-form-item__label {
  line-height: 0.29rem !important;
}

/deep/ .el-form--inline .el-form-item__label {
  line-height: 0.29rem;
}

/deep/ .formItem {

  display: inline-block;
}

/deep/ .formItem :nth-child(2n) {
  /* margin-left: .1563rem; */
}

/deep/ .el-textarea__inner {
  resize: none;
}

/deep/ .formItem :nth-child(2n) {
  /* margin-left: .1563rem; */
}

/deep/ .formItem-last {
  width: 94%;
  /* position: relative;
  height: 1rem; */
  border-radius: .0521rem;
  border: 1px solid #eee;
}

/deep/ .containerSeven[data-v-7eb2bc79] .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-47a68dd8] .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-21a084a6] .el-form-item__content {
  height: 100% !important;
}

/deep/ .el-textarea__inner {
  border: none !important;
  /* font-weight: 400; */
  color: #999999 !important;
  font-size:.0729rem !important;
  letter-spacing: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 5px 0.05rem!important;
}

/* input框的字体样式 */
::v-deep .el-input__inner {
  font-size: .0729rem !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #999999;
  /* line-height: .1042rem; */
  letter-spacing: 0px;
  /* font-weight: 600; */
  /* &::placeholder {
        color: #fff;
    } */

  /* &::-webkit-input-placeholder { */
  /* WebKit browsers 适配谷歌 */
  /* color: #fff;
    } */

  /* &:-moz-placeholder { */
  /* Mozilla Firefox 4 to 18 适配火狐 */
  /* color: #fff;
    } */

  /* &::-moz-placeholder { */
  /* Mozilla Firefox 19+ 适配火狐 */
  /* color: #fff;
    } */

  /* &:-ms-input-placeholder { */
  /* Internet Explorer 10+  适配ie*/
  /* color: #fff;
    } */
}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0 !important;
}

.containerSeven .content button {
  width: 2.0833rem;
  height: .25rem;
  border: 1px solid #ff6a00;
  color: #ff6a00;
  background-color: transparent;
  border-radius: .0208rem;
  font-size: .0833rem;
  font-family: MicrosoftYaHei;

  line-height: .125rem;
  position: absolute;
  bottom: .15rem;
  left: 50%;
  transform: translateX(-50%);
}

.containerSeven .content button:hover {
  border: none;
  background: #FD7D10 linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%);
  color: #FFFFFF;
}

.tips {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0.01rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 0.2rem;
  box-sizing: border-box;
}

.tips span {

  height: .0929rem;
  line-height: .0969rem;
  background-color: #bfbfbf;
  border-radius: .0521rem;
  color: white;
  padding: .0104rem .0417rem;
  font-size: .0625rem;
  margin: 0 0.05rem;
}

.bannBox {
  background-size: cover !important;
}

.dingwei {
  position: sticky;
  top: 0.37rem;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .dingwei {
    position: sticky;
    top: 60px;
  }

  .container {
    height: auto;
    padding: 0 15px;
  }

  .bannBox {
    min-height: 6rem !important;
    background-size: cover !important;
    margin-top: 60px;
  }

  .bannBox .txt {
    left: 15px;
    width: 50%;
    margin-top: -0.55rem;
  }

  .bannBox .txt h3 {
    font-size: 18px;
    line-height: normal;
  }

  .twos .title {
    font-size: 16px;
    line-height: normal;
  }

  .bannBox .txt .h5,
  .bannBox .txt button p span,
  .twos ol li .right .tit {
    font-size: 14px !important;
    line-height: normal;
  }

  .twos ul {
    flex-wrap: wrap;
  }

  .twos ul li {
    margin: 0 0px 10px 0;
    width: calc((100% - 20px) / 2);
    min-width: calc((100% - 20px) / 2);
    max-width: calc((100% - 20px) /2);
    height: auto;
    padding: 10px 0;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .twos ul li:nth-child(2n + 2) {
    margin-right: 0;
  }

  .twos ul li img,
  .twos ol li .right img {
    width: 25px;
    height: 25px;
  }

  .twos ul li span {
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }

  .twos ol li {
    flex-direction: column;
    padding: 15px 0;
  }

  .twos ol li .right .txt,
  .twos ol li .right .text,
  .twos ol li .right {
    width: 100%;
    height: auto !important;
    line-height: normal;
  }

  .twos ol li .right .text {
    font-size: 12px;
    line-height: normal;
  }

  .twos ol li .left {
    width: 80%;
    height: auto !important;
  }

  .twos .content {
    width: 90%;
  }

  .twos ol {
    width: 100%;
  }

  .twos ol li .right {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .containerSeven .title {
    font-size: 16px !important;
    line-height: normal !important;
  }

  .containerSeven .txt {
    font-size: 14px !important;
    line-height: normal !important;
  }

  /* .el-form>div{
    display: block!important;
  } */
  .containerSeven .content {
    width: 80%;
    margin: 0 auto;
  }

  .containerSeven /deep/ .el-form-item__content {
    flex: 1
  }

  .containerSeven /deep/ .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .containerSeven /deep/ .el-input__inner {
    width: 100%;
    padding: 10px;
    height: 30px;
    font-size: 14px !important;
  }

  .containerSeven {
    padding: 15px 0 !important;

  }

  .ipt {
    width: 100%;
    height: auto !important;
  }

  /deep/ .el-form-item__label {
    width: 20px
  }

  .tips span {
    font-size: 12px !important;
    line-height: normal !important;
    height: auto !important;
  }

  /deep/ .textArea,
  /deep/ .formItem-last {
    width: 100%;
  }

  .tips {
    bottom: 14px;
    left: 10px;
  }

  .containerSeven .content button {
    padding: 5px 15px;
    height: auto !important;
    line-height: normal !important;
    position: relative;
    left: auto;
    bottom: auto;
    transform: translateX(0%);
    margin: 15px 0 0 0;
    font-size: 14px !important;
  }


}
.formItem-last-box{
  margin-left: 10px;
}
.myNav{
  background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
}
</style>