<template>
  <div id="app">
    <router-view></router-view>
  </div>
  
</template>

<script>

export default {
  components:{
  },
  computed:{
  },
  created(){

  }
}
</script>
<style>
@import url('./css/.css');
button{
  outline: none !important;
}
html{
  /* font-size: 16; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*{
  /* font-size: 0; */
  margin: 0;
  padding: 0;
}
ul{
  list-style: none;
}
ol{
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

</style>
