<template>
  <div>
    <div ref="myNav" class="myNav">
      <navView @gotosection="gotobox" :suoyin="suoyin" />
    </div>

    <!-- 第一部分 -->
    <!-- <div
      class="ones bannBox"
      style="height: 2.8125rem; width: 100%; position: relative"
    >
      <div class="txt">
        <h3 style="color: black">{{ bannerTitle }}</h3>
        <p class="h5" v-if="bannerRemark != ''">{{ bannerRemark }}</p>
        <button
          @click="showForm"
          style="width: 0.8333rem; height: 0.2188rem; border-radius: 0.0208rem"
        >
          <p><span>立即咨询 </span></p>
        </button>
      </div>
      <img
        :src="bannerImage"
        style="
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 2.8125rem;
        "
        mode="heightFix"
        alt=""
      />
    </div> -->
    <div
      class="ones bannBox"
      :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }"
    >
      <div class="txt">
        <h3>{{ bannerTitle }}</h3>
        <p class="h5">{{ bannerRemark }}</p>
        <button @click="showForm">
          <p><span>立即咨询 </span></p>
        </button>
      </div>
      <!-- <img :src="bannerImage" style="position: absolute;left: 0;top: 0; width: 100%;height:100%;" alt="" /> -->
    </div>
    <!-- 侧边栏 -->
    <sliderView />
    <!-- 第二部分 -->
    <section class="twos">
      <p class="title" style="color: black; font-weight: bold">以人群为核心</p>
      <div class="content mobile">
        <el-carousel
          arrow="never"
          direction="horizontal"
          :autoplay="true"
          indicator-position="outside"
        >
          <el-carousel-item v-for="(item, index) in list1" :key="index">
            <div class="box">
              <img class="img" :src="item.banner" alt="" mode="widthFix" />
              <div class="text" style="height: 1.9063rem">
                <div
                  class="r-top"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.0938rem;
                  "
                >
                  <img
                    :src="item.logo"
                    style="
                      margin-right: 0.0521rem;
                      width: 0.3438rem;
                      height: 0.3438rem;
                    "
                    alt=""
                  />
                  <span
                    style="
                      font-size: 0.1458rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #313233;
                      line-height: 0.2083rem;
                    "
                  >
                    {{ item.title }}</span
                  >
                </div>
                <div
                  style="
                    width: 0.3229rem;
                    margin-bottom: 0.1094rem;
                    height: 0.026rem;
                    background: linear-gradient(
                      270deg,
                      #ff8c1f 0%,
                      #ff6a00 100%
                    );
                  "
                ></div>
                <div style="margin-bottom: 0.1563rem">
                  <div>
                    <span
                      class="wzzz"
                      style="
                        margin-right: 63%;
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #222222;
                        /* line-height: 0.125rem; */
                        float: left;
                      "
                      >{{ item.cel }} &nbsp;</span
                    >
                  </div>
                  <div
                    class="ziBox"
                    style="
                      display: flex;
                      width: 2.1rem;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: flex-start;
                      text-align: left;
                    "
                  >
                    <span
                      style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1354rem;
                        margin: 0.0833rem 0;
                      "
                      >{{ item.content[0] }}</span
                    >
                    <span
                      style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #626366;
                        line-height: 0.1354rem;
                      "
                      >{{ item.content[1] }}</span
                    >
                  </div>
                </div>
                <div class="allPrice">
                  <div
                    class="price-top"
                    style="height: 0.5125rem; text-align: center !important"
                  >
                    <p class="first">
                      {{ item.data1 }} <span>{{ item.rol1 }}</span>
                    </p>
                    <p
                      class="second"
                      style="
                        font-size: 0.0833rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #222222;
                        text-align: center !important;
                        line-height: 0.1146rem;
                      "
                    >
                      {{ item.con1 }}
                    </p>
                  </div>
                  <div
                    class="price-bot"
                    style="display: flex; height: 0.5125rem; text-align: left"
                  >
                    <p class="first">
                      {{ item.data2 }} <span>{{ item.rol2 }}</span>
                    </p>
                    <p class="second">{{ item.con2 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content pc">
        <el-carousel
          trigger="click"
          indicator-position="none"
          @change="changeItem"
          arrow="never"
          ref="carousel"
          :autoplay="true"
        >
          <el-carousel-item v-for="(item, index) in list1" :key="index">
            <div class="box">
              <img class="img" :src="item.banner" alt="" mode="widthFix" />
              <div class="text" style="height: 1.9063rem">
                <div
                  class="r-top"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.0938rem;
                  "
                >
                  <img
                    :src="item.logo"
                    style="
                      margin-right: 0.0521rem;
                      width: 0.3438rem;
                      height: 0.3438rem;
                    "
                    alt=""
                  />
                  <span
                    style="
                      font-size: 0.1458rem;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #313233;
                      line-height: 0.2083rem;
                    "
                  >
                    {{ item.title }}</span
                  >
                </div>
                <div
                  style="
                    width: 0.3229rem;
                    margin-bottom: 0.1094rem;
                    height: 0.026rem;
                    background: linear-gradient(
                      270deg,
                      #ff8c1f 0%,
                      #ff6a00 100%
                    );
                  "
                ></div>
                <div style="margin-bottom: 0.1563rem">
                  <div>
                    <span
                      class="wzzz"
                      style="
                        margin-right: 63%;
                        font-size: 0.125rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #222222;
                        /* line-height: 0.125rem; */
                        float: left;
                      "
                      >{{ item.cel }} &nbsp;</span
                    >
                  </div>
                  <div
                    class="ziBox"
                    style="
                      display: flex;
                      width: 2.1rem;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: flex-start;
                      text-align: left;
                    "
                  >
                    <span
                      style="
                        font-size: 0.1rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #202124;
                        line-height: 0.1354rem;
                        margin: 0.0833rem 0;
                      "
                      >{{ item.content[0] }}</span
                    >
                    <span
                      style="
                        font-size: 0.0938rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #202124;
                        line-height: 0.1354rem;
                      "
                      >{{ item.content[1] }}</span
                    >
                  </div>
                </div>
                <div class="allPrice">
                  <div class="price-top" style="height: 0.5125rem">
                    <p
                      class="first"
                      style="text-align: center !important; font-weight: 600"
                    >
                      {{ item.data1 }} <span>{{ item.rol1 }}</span>
                    </p>
                    <p
                      class="second"
                      style="
                        font-size: 0.0833rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #222222;
                        text-align: left;
                        line-height: 0.1146rem;
                      "
                    >
                      {{ item.con1 }}
                    </p>
                  </div>
                  <div
                    class="price-bot"
                    style="display: flex; height: 0.5125rem; text-align: left"
                  >
                    <p class="first">
                      {{ item.data2 }} <span>{{ item.rol2 }}</span>
                    </p>
                    <p class="second">{{ item.con2 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="btn left pc" @click="change('prev')">
          <!-- <img
            src="../assets/sence/swiper-left.png"
            style="width: 100%"
            alt=""
          /> -->
          <span> < </span>
        </div>
        <div class="btn right pc" @click="change('next')">
          <!-- <img src="../assets/sence/swiper-right.png" alt="" /> -->
          <span> ></span>
        </div>
      </div>
      <!-- 指示器 -->
      <ul class="idots pc">
        <li
          v-for="(a, i) in imgList.length"
          :style="{ background: active == i ? '#ED6C00' : '' }"
          :key="i"
          @click="changeSwiper(i)"
        ></li>
      </ul>
    </section>
    <!-- 第三部分 -->
    <section class="threes">
      <p class="title" style="color: black; font-weight: bold">{{ name2 }}</p>
      <!-- 轮播图 -->
      <div class="swipers pc">
        <el-carousel
          @change="swiperChanges"
          ref="carousels"
          direction="vertical"
          :interval="4000"
          :autoplay="true"
          indicator-position="none"
          :initial-index="hoverActive"
        >
          <el-carousel-item v-for="(a, index) in list2" :key="index">
            <main class="box" style="height: 100%; position: relative">
              <div
                class="left"
                style="
                  height: 1.7813rem;
                  width: 2.4583rem;
                  position: absolute;
                  left: 2.171rem;
                  top: 0.2688rem;
                "
              >
                <div
                  class="titles"
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.2083rem;
                    align-items: center;
                  "
                >
                  <!-- <p class="t-t-1">{{ item.txt }}</p> -->
                  <img
                    class="t-t-1"
                    style="width: 0.6438rem; height: 0.6438rem"
                    :src="a.logo"
                    alt=""
                  />
                  <h4 class="t-t-2">{{ a.title }}</h4>
                  <p class="t-t-3">{{ a.tag }}</p>
                </div>
                <div class="contents" style="margin-bottom: 0.2656rem">
                  <h4 style="text-align: left">{{ a.cel }}</h4>
                  <div
                    class="cBox"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: space-between;
                      text-align: left;
                      margin-top: 0.0833rem;
                    "
                  >
                    <span style="margin-bottom: 0.0833rem">{{
                      a.content[0]
                    }}</span>
                    <span>{{ a.content[1] }}</span>
                  </div>
                </div>
                <div class="allPrice">
                  <div class="price-top">
                    <!-- style="width: 0.7375rem" -->
                    <p class="first">
                      {{ a.data1 }} <span>{{ a.rol1 }}</span>
                    </p>
                    <p class="second">{{ a.con1 }}</p>
                  </div>
                  <div class="price-bot">
                    <p class="first">
                      {{ a.data2 }} <span>{{ a.rol2 }}</span>
                    </p>
                    <p class="second">{{ a.con2 }}</p>
                  </div>
                </div>
              </div>
            </main>
          </el-carousel-item>
        </el-carousel>

        <!-- 指示器 -->
        <ul class="idotss">
          <li
            @mouseenter="mouseEnter(i)"
            v-for="(c, i) in list2.length"
            :style="hoverActive == i ? swiperStyle : ''"
            :key="i"
          ></li>
        </ul>
        <!-- 右侧手机 -->
        <div class="right">
          <div class="rightbox">
            <img :src="list2[hoverActive].banner" alt="" />
          </div>
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="swipers mobile" style="position: relative">
        <!-- 遮罩层 -->
        <div
          style="
            width: 100%;
            height: 100%;
            background-color: #222222;
            z-index: 22 !important;
            position: absolute;
            opacity: 0;
          "
        ></div>
        <el-carousel
          direction="vertical"
          :autoplay="true"
          indicator-position="none"
          :interval="4000"
          :initial-index="hoverActive"
        >
          <el-carousel-item v-for="(a, index) in list2" :key="index">
            <main class="box" style="height: 100%; position: relative">
              <div
                class="left"
                style="
                  height: 1.7813rem;
                  width: 2.4583rem;
                  position: absolute;
                  left: 2.171rem;
                  top: 0.4688rem;
                "
              >
                <div
                  class="titles"
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.2083rem;
                    align-items: center;
                  "
                >
                  <!-- <p class="t-t-1">{{ item.txt }}</p> -->
                  <img
                    class="t-t-1"
                    style="width: 0.6438rem; height: 0.6438rem"
                    :src="a.logo"
                    alt=""
                  />
                  <h4 class="t-t-2">{{ a.title }}</h4>
                  <p class="t-t-3">{{ a.tag }}</p>
                </div>
                <div class="contents" style="margin-bottom: 0.2656rem">
                  <h4 style="text-align: left">{{ a.cel }}</h4>
                  <div
                    class="cBox"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: space-between;
                      text-align: left;
                      margin-top: 0.0833rem;
                    "
                  >
                    <span style="margin-bottom: 0.0833rem; width: 2.6rem">{{
                      a.content[0]
                    }}</span>
                    <span>{{ a.content[1] }}</span>
                  </div>
                </div>
                <div class="allPrice">
                  <div class="price-top">
                    <!-- style="width: 0.7375rem" -->
                    <p class="first">
                      {{ a.data1 }} <span>{{ a.rol1 }}</span>
                    </p>
                    <p class="second">{{ a.con1 }}</p>
                  </div>
                  <div class="price-bot">
                    <p class="first">
                      {{ a.data2 }} <span>{{ a.rol2 }}</span>
                    </p>
                    <p class="second">{{ a.con2 }}</p>
                  </div>
                </div>
              </div>
            </main>
          </el-carousel-item>
        </el-carousel>

        <!-- 指示器 -->
        <ul class="idotss">
          <li
            v-for="(c, i) in list2.length"
            :style="hoverActive == i ? swiperStyle : ''"
            :key="i"
          ></li>
        </ul>
        <!-- 右侧手机 -->
        <div class="right">
          <div class="rightbox">
            <img :src="list2[hoverActive].banner" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!-- 第四部分 -->
    <section class="fours">
      <h4 class="title" style="color: black; font-weight: bold">
        {{ name3 }}
      </h4>
      <ul style="height: 100%; width: 6rem; margin: 8% auto">
        <li
          style="
            width: 100%;
            height: 3.4rem;
            display: flex;
            justify-content: space-between;
            position: relative;
          "
          class="xz"
        >
          <div
            style="
              background-image: url(https://www.shuzijz.cn/img/sjk.8cf9e9a3.png);
              background-size: cover;
              width: 1.53rem;
              height: 2.75rem;
              position: relative;
              margin-left: 0.5rem;
            "
            class="pop"
          >
            <div
              class="imgbox"
              style="
                width: 80%;
                height: 88%;
                overflow: hidden;
                border-radius: 0.15rem;
                position: absolute;
                left: 0.03rem;
                top: 0.07rem;
              "
            >
              <img
                :src="list3[0].banner"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
          </div>
          <ul style="margin-top: 0.25rem; margin-right: 0.15rem" class="foul">
            <li style="display: flex; align-items: center">
              <img
                :src="list3[0].logo"
                style="width: 0.355rem; height: 0.355rem; margin-right: 0.15rem"
                alt=""
                class="loglo"
              />
              <span
                class="titl"
                style="
                  font-size: 0.1458rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: bold;
                  color: #000;
                "
                >{{ list3[0].title }}
              </span>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[0].icon"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[0].ctitle }}
              </span>
              <p
                style="color: #202124; font-size: 0.1rem; margin-left: 0.07rem"
              >
                {{ list3[0].ccontent }}
              </p>
            </li>
            <li style="display: flex; align-items: center">
              <img
                :src="list3[0].icon3"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[0].cel }}
              </span>
              <ul style="margin-top: 0.18rem" class="tlm">
                <li class="nu">
                  <p
                    style="color: #202124; font-size: 0.1rem; text-align: left"
                  >
                    {{ list3[0].content[0] }}
                  </p>
                </li>
                <li>
                  <p
                    style="
                      color: #202124;
                      font-size: 0.1rem;
                      margin-top: 0.05rem;
                      text-align: left;
                    "
                  >
                    {{ list3[0].content[1] }}
                  </p>
                </li>
              </ul>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[0].icon2"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[0].ztitle }}
              </span>
              <p
                style="
                  color: #202124;
                  font-size: 0.1rem;
                  margin-top: 0.02rem;
                  margin-left: 0.07rem;
                "
              >
                {{ list3[0].zcontent }}
              </p>
            </li>
            <li
              style="
                display: flex;
                margin-top: 0.3rem;
                justify-content: space-around;
                font-weight: bold;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[0].data1 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[0].rol1 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[0].con1 }}
                </p>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[0].data2 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[0].rol2 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[0].con2 }}
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li
          style="
            width: 100%;
            height: 3.4rem;
            display: flex;
            justify-content: space-between;
            position: relative;
          "
          class="xz"
        >
          <ul style="margin-top: 0.15rem" class="foul">
            <li style="display: flex; align-items: center">
              <img
                :src="list3[1].logo"
                style="width: 0.355rem; height: 0.355rem; margin-right: 0.15rem"
                alt=""
                class="loglo"
              />
              <span
                class="titl"
                style="
                  font-size: 0.1458rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: bold;
                  color: #000;
                "
                >{{ list3[1].title }}
              </span>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[1].icon"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
              >
                {{ list3[1].ctitle }}
              </span>
              <p
                style="
                  color: #202124;
                  font-size: 0.1rem;
                  margin-top: 0.02rem;
                  margin-left: 0.07rem;
                "
              >
                {{ list3[1].ccontent }}
              </p>
            </li>
            <li style="display: flex; align-items: center; margin-top: -0.1rem">
              <img
                :src="list3[1].icon3"
                style="
                  width: 0.15rem;
                  margin-top: -0.25rem;
                  height: 0.13rem;
                  margin-right: 0.05rem;
                "
                alt=""
              />
              <span
                style="
                  margin-top: -0.25rem;
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
              >
                {{ list3[1].cel }}
              </span>
              <ul style="line-height: 0.16rem; margin-top: 0.04rem" class="ull">
                <li
                  style="
                    color: #202124;
                    font-size: 0.1rem;
                    text-align: left;
                    width: 1.9rem;
                    padding-top: 0.2rem;
                  "
                >
                  <p
                    style="
                      color: #202124;
                      font-size: 0.1rem;
                      margin-top: 0.05rem;
                      width: 1.7rem;
                      text-align: left;
                    "
                  >
                    {{ list3[1].content[0] }}
                  </p>
                </li>
                <li>
                  <p
                    style="
                      color: #202124;
                      font-size: 0.1rem;
                      margin-top: 0.05rem;
                      width: 1.7rem;
                      text-align: left;
                    "
                  >
                    {{ list3[1].content[1] }}
                  </p>
                </li>
              </ul>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[0].icon2"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[0].ztitle }}
              </span>
              <p
                style="
                  color: #202124;
                  font-size: 0.1rem;
                  margin-top: 0.03rem;
                  margin-left: 0.07rem;
                "
              >
                {{ list3[0].zcontent }}
              </p>
            </li>
            <li
              style="
                display: flex;
                margin-top: 0.3rem;
                justify-content: space-around;
                font-weight: bold;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[1].data1 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[1].rol1 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[1].con1 }}
                </p>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[1].data2 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[1].rol2 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[1].con2 }}
                </p>
              </div>
            </li>
          </ul>
          <div
            style="
              background-image: url(https://www.shuzijz.cn/img/sjk.8cf9e9a3.png);
              background-size: cover;
              width: 1.53rem;
              height: 2.75rem;
              position: relative;
            "
            class="pon"
          >
       
            <div
              class="imgbox"
              style="
                width: 80%;
                height: 87%;
                overflow: hidden;
                border-radius: 0.15rem;
                position: absolute;
                left: 0.02rem;
                top: 0.08rem;
              "
            >
              <img
                :src="list3[1].banner"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
          </div>
        </li>
        <li
          style="
            width: 100%;
            height: 3.4rem;
            display: flex;
            justify-content: space-between;
          "
          class="xz"
        >
          <div
            style="
              background-image: url(https://www.shuzijz.cn/img/sjk.8cf9e9a3.png);
              background-size: cover;
              width: 1.53rem;
              height: 2.75rem;
              position: relative;
              margin-left: 0.5rem;
            "
            class="pom"
          >
         
            <div
              class="imgbox"
              style="
                width: 80%;
                height: 88%;
                overflow: hidden;
                border-radius: 0.15rem;
                position: absolute;
                left: 0.03rem;
                top: 0.07rem;
              "
            >
              <img
                :src="list3[2].banner"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
          </div>
          <ul style="margin-top: 0.05rem" class="foul">
            <li style="display: flex; align-items: center">
              <img
                :src="list3[2].logo"
                style="width: 0.355rem; height: 0.355rem; margin-right: 0.15rem"
                alt=""
                class="loglo"
              />
              <span
                class="titl"
                style="
                  font-size: 0.1458rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: bold;
                  color: #000;
                "
                >{{ list3[2].title }}
              </span>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[2].icon"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[2].ctitle }}
              </span>
              <p
                style="
                  color: #202124;
                  font-size: 0.1rem;
                  margin-top: 0.03rem;
                  margin-left: 0.07rem;
                "
              >
                {{ list3[2].ccontent }}
              </p>
            </li>
            <li style="display: flex; align-items: center; margin-top: -0.1rem">
              <img
                :src="list3[2].icon3"
                style="
                  margin-top: -0.25rem;
                  width: 0.15rem;
                  height: 0.13rem;
                  margin-right: 0.05rem;
                "
                alt=""
              />
              <span
                style="
                  margin-top: -0.25rem;
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[2].cel }}
              </span>
              <ul style="margin-top: 0.25rem; line-height: 0.16rem" class="ull">
                <li
                  style="
                    color: #202124;
                    font-size: 0.1rem;
                    text-align: left;
                    width: 1.9rem;
                  "
                >
                  <p
                    style="
                      color: #202124;
                      font-size: 0.1rem;
                      margin-top: 0.05rem;
                      width: 1.7rem;
                      text-align: left;
                    "
                  >
                    {{ list3[2].content[0] }}
                  </p>
                </li>
                <li>
                  <p
                    style="
                      color: #202124;
                      font-size: 0.1rem;
                      margin-top: 0.05rem;
                      width: 1.7rem;
                      text-align: left;
                    "
                  >
                    {{ list3[2].content[1] }}
                  </p>
                </li>
              </ul>
            </li>
            <li style="display: flex; align-items: center; margin-top: 0.15rem">
              <img
                :src="list3[2].icon2"
                style="width: 0.15rem; height: 0.13rem; margin-right: 0.05rem"
                alt=""
              />
              <span
                style="
                  font-size: 0.13rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  color: #000;
                "
                >{{ list3[2].ztitle }}
              </span>
              <p
                style="
                  color: #202124;
                  font-size: 0.1rem;
                  margin-top: 0.02rem;
                  margin-left: 0.07rem;
                "
              >
                {{ list3[2].zcontent }}
              </p>
            </li>
            <li
              style="
                display: flex;
                margin-top: 0.3rem;
                justify-content: space-around;
                font-weight: bold;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[2].data1 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-top: 0.1rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[2].rol1 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[2].con1 }}
                </p>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    color: #ed6200;
                    font-size: 0.215rem;
                    display: flex;
                    align-items: flex-end;
                  "
                >
                  {{ list3[2].data2 }}
                  <p
                    style="
                      color: #202124;
                      font-size: 0.09rem;
                      margin-top: 0.1rem;
                      margin-left: 0.02rem;
                      margin-bottom: 0.03rem;
                    "
                  >
                    {{ list3[2].rol2 }}
                  </p>
                </span>
                <p style="color: #222222; font-size: 0.09rem">
                  {{ list3[2].con2 }}
                </p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <!-- 联系我们 -->
    <section style="margin-top: 1.6rem" class="sev">
      <div class="containerSeven">
        <div class="content">
          <p class="title pc">联系我们</p>
          <p class="title mobile" style="padding-bottom: 10px">联系我们</p>
          <p class="txt pc">
            留下您的联系方式，您的专属顾问会在1个工作日内联系您
          </p>

          <el-form
            :inline="true"
            class="demo-form-inline pc"
            :model="ruleForm"
            :rules="rules"
            ref="ruleFormRef"
          >
            <div
              style="
                width: 94%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.0521rem;
              "
            >
              <el-form-item class="formItem" label=" " prop="name" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.name"
                  placeholder="联系人姓名"
                  label-width="0"
                ></el-input>
              </el-form-item>
              <el-form-item label=" " prop="mobile" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.mobile"
                  placeholder="手机号码"
                ></el-input>
              </el-form-item>
            </div>
            <div
              style="
                width: 94%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.0521rem;
              "
            >
              <el-form-item class="formItem" label=" " prop="company" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.company"
                  placeholder="企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                label=" "
                prop="zhiwei"
                style="margin-left: 0.0821rem"
              >
                <el-input
                  class="ipt"
                  v-model="ruleForm.zhiwei"
                  placeholder="职位"
                ></el-input>
              </el-form-item>
            </div>
            <div class="formItem-last-box">
              <el-form-item class="formItem-last" style="position: relative">
                <el-input
                  type="textarea"
                  class="textArea"
                  rows="5"
                  placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                  v-model="ruleForm.remark"
                >
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
          </el-form>
          <el-form
            :inline="true"
            class="demo-form-inline mobile"
            :model="ruleForm"
            :rules="rules"
            ref="ruleFormRef"
          >
            <div style="width: 94%; margin: 0 auto; margin-bottom: 0.0521rem">
              <el-form-item class="formItem" label=" " prop="name" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.name"
                  placeholder="联系人姓名"
                  label-width="0"
                ></el-input>
              </el-form-item>
            </div>
            <div style="width: 94%; margin: 0 auto; margin-bottom: 0.0521rem">
              <el-form-item label=" " prop="mobile" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.mobile"
                  placeholder="手机号码"
                ></el-input>
              </el-form-item>
            </div>
            <div style="width: 94%; margin: 0 auto">
              <el-form-item class="formItem" label=" " prop="company" required>
                <el-input
                  class="ipt"
                  v-model="ruleForm.company"
                  placeholder="企业名称"
                ></el-input>
              </el-form-item>
            </div>
            <div style="width: 94%; margin: 0 auto; margin-bottom: 0.0521rem">
              <el-form-item label=" " prop="zhiwei">
                <el-input
                  class="ipt"
                  v-model="ruleForm.zhiwei"
                  placeholder="职位"
                ></el-input>
              </el-form-item>
            </div>
            <div
              class="formItem-last-box mobile"
              style="
                height: 150px;
                width: 94%;
                margin: 0 auto;
                box-sizing: border-box;
                padding-left: 20px;
              "
            >
              <el-form-item
                class="formItem-last"
                style="position: relative; width: calc(100% - 3px) !important"
              >
                <el-input
                  style="padding-left: 10px; box-sizing: border-box"
                  type="textarea"
                  class="textArea"
                  rows="5"
                  placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                  v-model="ruleForm.remark"
                >
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
            <div class="formItem-last-box pc">
              <el-form-item class="formItem-last" style="position: relative">
                <el-input
                  type="textarea"
                  class="textArea"
                  rows="5"
                  placeholder="请写明您的具体需求，以便我们安排相应顾问与您联系（选填）"
                  v-model="ruleForm.remark"
                >
                </el-input>
                <ul class="tips">
                  <span @click="showTxt('影视宣发\n')">影视宣发</span>
                  <span @click="showTxt('品牌营销\n')">品牌营销</span>
                  <span @click="showTxt('精准PR\n')">精准PR</span>
                </ul>
              </el-form-item>
            </div>
          </el-form>

          <button @click="submitFormModal('ruleFormRef')">提交</button>
        </div>
      </div>
    </section>
    <!-- 第五部分 底部-->
    <bottomView @gotosection="gotobox" />
    <formView />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import swiperView from "../components/swiper.vue";
import bottomView from "../components/bottom.vue";
import navView from "../components/nav.vue";
import sliderView from "../components/slider.vue";
import formView from "../components/form.vue";
import { sences } from "../api/sence";
import axios from "axios";

export default {
  components: {
    swiperView,
    bottomView,
    navView,
    sliderView,
    formView,
  },
  data() {
    return {
      banner: "",
      ruleForm: {
        name: "",
        mobile: "",
        company: "",
        zhiwei: "",
        remark: "",
      },
      formRef: {},
      rules: {
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        company: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
      },
      testShow: false,
      testShow1: false,
      testShow2: false,
      testShow3: false,
      testShow4: false,
      testShow5: false,
      testShow6: false,
      testref: false,
      test3ref: false,
      test5ref: false,
      suoyin: 0,
      imgList: [
        {
          url: "https://img1.baidu.com/it/u=841611835,1038495225&fm=253&fmt=auto&app=138&f=JPEG?w=1000&h=500",
        },
        {
          url: "http://t14.baidu.com/it/u=2123284384,795117807&fm=224&app=112&f=JPEG?w=500&h=250",
        },
        {
          url: "https://img2.baidu.com/it/u=2243573419,589412055&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500",
        },
        {
          url: "https://img0.baidu.com/it/u=3032776730,2178451350&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800",
        },
      ],
      index: 0,
      active: 0,
      swiperList: [
        {
          txt: "美宜佳",
          black: "美宜佳连锁便利店",
          txt2: "推广周期: 15天",
          black2: "人群策略",
          gray1: " · 广东省1.7万家门店周边的白领，青年人群",
          gray2: " · 到访过门店3次以上的高粘度人群",
          price1: "38.3",
          num: "1000",
          priceTxt: "门店客流量环比增长",
          numTxt: "小程序涨粉",
          img: "https://img2.baidu.com/it/u=3161537230,2930255269&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
        },
        {
          txt: "美宜佳",
          black: "美宜佳连锁便利店",
          txt2: "推广周期:15天",
          black2: "人群策略",
          gray1: " · 广东省1.7万家门店周边的白领，青年人群",
          gray2: " · 到访过门店3次以上的高粘度人群",
          price1: "38.3",
          num: "1000",
          priceTxt: "门店客流量环比增长",
          numTxt: "小程序涨粉",
          img: "https://img2.baidu.com/it/u=2567390587,3799045948&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200",
        },
        {
          txt: "美宜佳",
          black: "美宜佳连锁便利店",
          txt2: "推广周期:15天",
          black2: "人群策略",
          gray1: " · 广东省1.7万家门店周边的白领，青年人群",
          gray2: " · 到访过门店3次以上的高粘度人群",
          price1: "38.3",
          num: "1000",
          priceTxt: "门店客流量环比增长",
          numTxt: "小程序涨粉",
          img: "https://img0.baidu.com/it/u=985639930,1461029341&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200",
        },
      ],
      swiperStyle: {
        height: ".625rem",
        background: "linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%)",
        "border-radius": ".0625rem",
      },
      hoverActive: 0,
      m: 0,
      name1: "",
      list1: [],
      name2: "",
      list2: [],
      name3: "",
      list3: [],
      bannerImage: "",
      bannerRemark: "",
      bannerTitle: "",
    };
  },
  watch: {
    "ruleForm.mobile": {
      handler(newVal) {
        if (!newVal) {
          this.ruleForm.mobile = "";
          return false;
        }
        // 实时把非数字的输入过滤掉
        this.ruleForm.mobile = newVal.match(/\d/gi)
          ? newVal.match(/\d/gi).join("")
          : "";
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["navList"]),
  },
  created() {
    this.getBannerList();
    this.getSencesList();
    this.suoyin = sessionStorage.getItem("i");
  },

  mounted() {
    this.handleScrollbox();
    window.addEventListener("scroll", this.handleScrollbox, true);
    // this.slideBanner()/*轮播手滑切换*/
    if (this.$route.params.i >= 0) {
      var s = document.getElementsByTagName("section");
      let m = this.$route.params.i;
      setTimeout(() => {
        s[m].scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    } else {
      var s =
        document.getElementsByClassName("container")[0].nextElementSibling;
      console.log(s, "s");
      s.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  methods: {
    getBannerList() {
      axios
        .get("https://admin.shuzijz.cn/api/index/banner?id=3")
        .then((res) => {
          console.log(res, "banner的数据");
          this.bannerTitle = res.data.data.title;
          this.bannerRemark = res.data.data.remark;
          this.bannerImage = res.data.data.image;
        });
    },
    async getSencesList() {
      const { data: res } = await sences();
      console.log(res, "res111111");
      this.name1 = res.data.content[0].name;
      this.list1 = res.data.content[0].main;
      this.name2 = res.data.content[1].name;
      this.list2 = res.data.content[1].main;
      this.name3 = res.data.content[2].name;
      this.list3 = res.data.content[2].main;
      console.log(this.name3, this.list3);
      this.banner = this.list2[0].banner;
      console.log(this.list1, "this.list3");
    },
    ...mapActions(["showDialog"]),
    showForm() {
      this.showDialog(true);
    },
    gotobox(i, b, index) {
      var s = document.getElementsByTagName("section");
      if (this.$route.name == b) {
        var top = s[i].offsetTop - this.$refs.myNav.offsetHeight;
        window.scrollTo({
          top: top,
          behavior: "smooth",
        });
        // s[i].scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        sessionStorage.setItem("i", index);
        this.$router.push({
          name: b,
          params: { i: i },
        });
      }
    },
    showTxt(t) {
      this.ruleForm.remark += t;
    },
    change(e) {
      // console.log(e);
      this.$refs["carousel"][e]();
    },
    changeSwiper(i) {
      this.$refs.carousel.setActiveItem(i); // 将tab的下标指定为走马灯的下标
      this.active = i; // tab切换的下标
    },
    changeItem(e) {
      this.active = e; // 将走马灯的 下标 指定为 tab 的下标
    },
    swiperChanges(e) {
      console.log(e, 111);
      this.hoverActive = e;
      this.mouseEnter(e);
    },
    mouseEnter(e) {
      var screenWidth = window.innerWidth;
      if (screenWidth > 1000) {
        console.log(e);
        this.$refs.carousels.setActiveItem(e);
        this.hoverActive = e;
        this.banner = this.list3[e].banner;
      }
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      this.$nextTick(() => {
        if (this.$refs.testref) {
          if (this.currentScroll >= this.$refs.testref.offsetTop - 1240) {
            //当前滚动位置到达testref的时候，显示div（100作为调整用）
            // this.testShow = true;
            this.testShow1 = true;
            this.testShow2 = true;
          }
        }

        if (this.currentScroll >= this.$refs.test3ref.offsetTop - 740) {
          this.testShow3 = true;
          this.testShow4 = true;
        }

        if (this.currentScroll >= this.$refs.test5ref.offsetTop - 740) {
          this.testShow5 = true;
          this.testShow6 = true;
        }
      });
    },
    submitFormModal(ruleFormRef) {
      this.$refs[ruleFormRef].validate(async (valid) => {
        if (valid) {
          // var params = new URLSearchParams();
          // params.append("name", this.ruleForm.name);
          // params.append("mobile", this.ruleForm.mobile);
          // params.append("company", this.ruleForm.company);
          // params.append("position", this.ruleForm.zhiwei);
          // params.append("remark", this.ruleForm.remark);
          // axios({
          //   method: "post",
          //   url: "http://admin.shuzijz.cn/api/edm/v1/sender/template",
          //   data: params,
          // })
          //   .then((res) => {
          //     console.log(res);
          //     if (res.data.code == 0) {
          //       this.$message({
          //         message: res.data.message,
          //         type: "error",
          //       });
          //     }
          //     if (res.data.code == 1) {
          //       this.$message({
          //         message: res.data.message,
          //         type: "success",
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err, "err");
          //   });
          var params = {
            options: {
              name: this.ruleForm.name,
              mobile: this.ruleForm.mobile,
              company: this.ruleForm.company,
              province: "",
              position: this.ruleForm.zhiwei,
              remark: this.ruleForm.remark,
            },
            key: "szjz_website_leads",
          };
          axios
            .post("https://www.shuzijz.cn/api/edm/v1/sender/template", params)
            // axios({
            //   method: "post",
            //   url: 'https://www.shuzijz.cn/api/edm/v1/sender/template',
            //   data: params
            // })
            .then((res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  message: res.data.message,
                  type: "error",
                });
              }
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.$refs.ruleFormRef.resetFields();
              }
            })
            .catch((err) => {
              console.log(err, "err");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 1273px) {
  .swipers .allPrice {
    margin-top: -0.3rem;
  }
  .swipers .allPrice .second {
    width: 1.5rem;
  }
  .t-t-2 {
    font-size: 0.125rem;
  }
}
.first {
  text-align: center !important;
}
@media screen and (max-width: 600px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  .class {
    background: #ccc;
  }
}

@media screen and (max-width: 900px) {
  .foul {
    margin-left: -0.5rem !important;
  }
  .myNav {
    background-color: #fff;
    height: auto !important;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
  }
  .mobile {
    display: block !important;
  }
  .el-textarea /deep/ .el-textarea__inner {
    font-size: 12px !important;
  }
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none;
  }
  .pc {
    display: block;
  }
  .myNav {
    background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
  }
}
/* 第一部分 */
.foul:nth-child(2) {
  margin-left: 0.5rem;
}
.foul li:nth-child(5) div span {
  margin-bottom: 10px;
}
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 1.875rem;
  position: absolute;
}

.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: 0.2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: 0.3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: 0.1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: 0.3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: 0.078125rem /* 15/192 */;
}

.bannBox .txt button {
  /* position: absolute; */
  border: 1px solid #ff6a00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #ff6a00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: 0.3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius: 5px;
  padding: 0.052083rem /* 10/192 */ 0.1875rem /* 36/192 */;
  margin-top: 0.3125rem;
}
.bannBox .txt button:hover {
  color: white;
  background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
}

.bannBox .txt button p {
  margin: 0;
  padding: 0 0.0521rem;
  font-size: 0.0833rem;
}

.bannBox .txt button p span {
  font-size: 0.0833rem;
}

/* 按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }
  to {
    top: 1.6302rem;
    opacity: 1;
  }
}
@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }
  to {
    top: 1.6302rem;
    opacity: 1;
  }
}
/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }
  to {
    top: 0.7292rem;
    opacity: 1;
  }
}
@keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }
  to {
    top: 0.7292rem;
    opacity: 1;
  }
}
/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }
  to {
    top: 1.1719rem;
    opacity: 1;
  }
}
@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }
  to {
    top: 1.1719rem;
    opacity: 1;
  }
}
.second {
  text-align: center !important;
}
.twos .txt button p {
  margin: 0;
  /* display: flex;
  justify-content: space-between; */
  padding: 0 0.0521rem;
  /* align-items: center; */
}
.bannBox .txt button p img {
  width: 0.0833rem;
  height: 0.0833rem;
}
/* 第二部分 */
.twos .allPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 0.2083rem; */
}
.twos .allPrice .price-top {
  /* width: 0.85rem; */
  height: 0.3125rem;
}
.twos .price-bot {
  width: 1.2rem;
  height: 0.3125rem;
  flex-direction: column;
}
.twos .allPrice .first {
  font-size: 0.2604rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ed6200;
  height: 0.3125rem;
  line-height: 0.3021rem;
  margin-bottom: 0.0573rem;
}
.twos .allPrice .first span {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
}
.twos .allPrice .second {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.1146rem;
}
.twos {
  height: 4.4271rem;
  position: relative;
  padding-top: 0.4167rem;
}
.twos .title {
  font-size: 0.1979rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.125rem;
  margin-bottom: 0.474rem;
}
.twos .content {
  width: 7.2917rem;
  margin: 0 auto;
  height: 2.4271rem;
  /* padding: 0 0.3125rem; */
  box-sizing: border-box;
  position: relative;
}
.twos .el-carousel {
  /* width: 6.25rem; */
  width: 7.317rem;
  height: 2.4271rem;
  background: #ffffff;
  box-shadow: 0px 0.0104rem 0.1458rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.0625rem;
}
.twos .el-carousel .box {
  display: flex;
  align-items: center;
}
.twos .el-carousel .img {
  /* width: 100%;
  height: 100%; */
  width: 3.4656rem;
  height: 2.4271rem;
  margin-right: 0.5rem;
}

.btn {
  width: 0.4115rem;
  height: 0.4115rem;
  background: #f2f2f2;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.btn span {
  font-size: 0.1667rem;
}
.btn:hover {
  background-color: #ff7a00;
  color: white;
}
.left {
  position: absolute;
  top: 50%;
  left: -0.4042rem;
  transform: translateX(-50%);
}
.right {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  right: -0.832rem;
}
.idots {
  width: 0.5729rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.6567rem;
}
.idots li {
  width: 0.0625rem;
  height: 0.0625rem;
  background: #d8d8d8;
  border-radius: 50%;
}
/* 第三部分 */
.threes {
  height: 5.0156rem;
  background: linear-gradient(134deg, #fdf8f7 0%, #fef3ea 48%, #fffcfc 100%);
  /* opacity: 0.45; */
  padding-top: 0.4167rem;
}
.threes .swipers {
  width: 6.25rem;
  height: 2.8229rem;
  box-shadow: 0px 0.0104rem 0.2135rem 0px rgba(237, 108, 0, 0.1);
  border-radius: 0.1042rem;
  margin: 0 auto;
  position: relative;
  background: #ffffff;
}
.threes .title {
  font-size: 0.1979rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
  line-height: 0.125rem;
  margin-bottom: 0.651rem;
}
/* 第三部分轮播图 */

.threes .idotss {
  width: 0.0521rem;
  height: 1.875rem;
  background: #eeeeee;
  border-radius: 0.0625rem;
  position: absolute;
  left: 0.5313rem;
  top: 0.474rem;
  z-index: 9;
}
.threes .idotss li {
  cursor: pointer;
  width: 100%;
  border-radius: 0.0625rem;
  height: 0.625rem;
}
.threes /deep/ .el-carousel-item {
  position: relative;
}

.threes /deep/ .el-carousel {
  height: 100% !important;
  opacity: 1;
}
.threes /deep/ .el-carousel__container {
  height: 100%;
}
.threes .right {
  background-image: url(../assets/sence/sjk.png);
  background-size: cover;
  width: 2.0781rem;
  height: 3.7396rem;
  position: absolute;
  right: -1.1521rem;
  top: -0.3088rem;
  /* background-color: red; */
}
.threes .right .rightbox {
  width: 1.6181rem;
  height: 3.2796rem;
  position: absolute;
  left: 0.05rem;
  top: 0.1rem;
  overflow: hidden;
  border-radius: 0.1821rem;
}
.threes .right .rightbox img {
  width: 100%;
  height: 100%;
}
.t-t-1 {
  width: 0.5438rem;
  height: 0.4342rem;
}
.t-t-2 {
  /* width: 1.1667rem; */
  height: 0.2083rem;
  font-size: 0.1458rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #313233;
  line-height: 0.2083rem;
}
.t-t-3 {
  /* width: 0.7302rem; */
  height: 0.1458rem;
  padding: 2px 5px;
  background: #ed6c00;
  border-radius: 0.0313rem;
  font-size: 0.0729rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 180%;
}
.titles {
  /* margin-bottom: 0.2083rem; */
  margin-bottom: 0.140625rem !important /* 27/192 */;
}
.contents h4 {
  font-size: 0.125rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.125rem;
}
.contents div span {
  font-size: 0.1rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202124;
}
.swipers .allPrice {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  align-items: center;
  height: 0.7rem;
  width: 100%;
  justify-content: space-between;
}
.swipers .allPrice .price-top {
  /* width: 0.99rem; */
  /* height: 0.6125rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.swipers .price-bot {
  width: 50%;

  /* height: 0.6125rem; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.swipers .allPrice .first {
  font-size: 0.2604rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ed6200;
  /* height: 0.2125rem; */
  /* line-height: 0.3021rem; */
  margin-bottom: 0.0573rem;
}
.swipers .allPrice .first span {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
}
.swipers .allPrice .second {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #222222;
  /* line-height: 0.1146rem; */
}

/* 第四部分 */
.fours {
  height: 10.0521rem;
  margin-top: 0.4167rem;
}
.fours .title {
  font-size: 0.1979rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313233;
}
.fours .cont {
  margin: 0 auto;
}
.fours .cont .r {
  width: 3.2rem !important;
  /* height: 2.2rem !important; */
}
.fours .cont .l {
  background-image: url(../assets/sence/sjk.png);
  background-size: cover;
  /* width: 2.0781rem;
  height: 3.7396rem; */
  /* border: 1px solid red; */
  position: relative;
  width: 1.4844rem;
  height: 2.6615rem;
  border-radius: 0.1521rem;
  overflow: hidden;
}
.fours .cont .l .imgbox {
  position: absolute;
  top: 0.076rem;
  left: 0.04rem;
  width: 1.1564rem;
  height: 2.34rem;
  border-radius: 0.13rem;
  overflow: hidden;
}
.fours .cont .l .imgbox img {
}
.fours .cont li img:nth-child(1) {
  margin-right: 0.1729rem;
}
/*  */

.fours .allPrice {
  display: flex;
  width: 2.5rem;
  /* margin: 0 auto; */
  align-items: center;
  justify-content: space-between;
}
.fours .allPrice .price-top {
  /* width: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 0.5125rem;
}
.fours .price-bot {
  width: 1.5rem;
  height: 0.5125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.fours .allPrice .first {
  font-size: 0.2604rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ed6200;
  height: 0.3125rem;
  line-height: 0.3021rem;
  margin-bottom: 0.0573rem;
}
.fours .allPrice .first span {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #626366;
  line-height: 0.1146rem;
}
.fours .allPrice .second {
  font-size: 0.0833rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.1146rem;
}
.boxleftTo20 {
  transform: translateX(-20%);
  opacity: 0;
}
/* //滚动到指定位置时回到原点 */
.boxtestenterX {
  transform: translateX(0%) !important;
  opacity: 1 !important;
  transition: all 1s ease;
}
/* //开始位置在右侧120%位置 */
.boxtranslateXright {
  transform: translateX(10%);
  opacity: 0;
}
/* 联系我们 */
.containerSeven {
  background-image: url(../assets/index/seven-bg.png);
  /* height: 3.0313rem; */
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  padding: 0.2083rem 2.3958rem;
  box-sizing: border-box;
}

.containerSeven .content {
  width: 5.2083rem;
  padding-top: 0.2083rem;
  /* width: 1001px; */
  /* height:2.6042rem; */
  background: #ffffff;
  z-index: 23;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.0417rem;
  position: relative;
  border: 1px solid rgba(212, 214, 218, 0.5);
}

.containerSeven .title {
  color: #222;
  font-size: 0.1458rem;
  line-height: 0.2083rem;
  margin-bottom: 0.0833rem;
}

.containerSeven .txt {
  color: #999;

  font-size: 0.0833rem;
  line-height: 0.1146rem;
  margin-bottom: 0.2083rem;
}

.containerSeven /deep/ .el-form-item__content {
  line-height: 0.2083rem;
  font-size: 0.0729rem;
}

.containerSeven /deep/ .el-input__inner {
  width: 2.3125rem;
  height: 0.2292rem;
  font-size: 0.0929rem;
}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0 !important;
}

/deep/ .textArea {
  width: 4.7917rem;
  font-size: 0.0729rem;
  position: relative !important;
  padding-bottom: 0.2rem;
  /* border: 1px solid red; */
  /* height: 0.56rem; */
}

/deep/ .ipt {
  width: 2.3125rem;
  height: 0.2292rem;
}

/deep/ .el-form-item__label {
  padding: 0 !important;
}

/deep/ .el-form-item {
  margin-bottom: 0.1146rem;
  /* width: 2.4716rem ; */
}

/deep/ .el-form-item__label {
  line-height: 0.2083rem !important;
}

/deep/ [data-v-64762e48] .el-form-item__label {
  line-height: 0.29rem !important;
}

/deep/ .el-form--inline .el-form-item__label {
  line-height: 0.29rem;
}

/deep/ .formItem {
  display: inline-block;
}

/deep/ [data-v-64762e48] .el-form-item {
  margin-left: 0.05rem !important;
}

.tips {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0.01rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 0.2rem;
  box-sizing: border-box;
}

.tips span {
  height: 0.0929rem;
  line-height: 0.0969rem;
  background-color: #bfbfbf;
  border-radius: 0.0521rem;
  color: white;
  padding: 0.0104rem 0.0417rem;
  font-size: 0.0625rem;
  margin: 0.02rem 0.05rem;
}

/deep/ .formItem-last {
  width: 94%;
  /* position: relative; */
  /* height: 1rem; */
  border: 1px solid #eee;
  border-radius: 0.0521rem;
}

/deep/ .containerSeven[data-v-7eb2bc79] .el-form-item__content {
  height: 100% !important;
}

/deep/ .el-form--inline .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-47a68dd8] .el-form-item__content {
  height: 100% !important;
}

/deep/ .containerSeven[data-v-21a084a6] .el-form-item__content {
  height: 100% !important;
}

/deep/ .el-textarea__inner {
  border: none !important;
  font-weight: 400;
  font-size: 0.0729rem !important;
  color: #999999 !important;
  letter-spacing: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0 0.05rem !important;
}

/* input框的字体样式 */
::v-deep .el-input__inner {
  font-size: 0.0729rem !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #999999;
  /* line-height: .1042rem; */
  letter-spacing: 0px;
  padding: 0 10px !important;

  /* font-weight: 600; */
  /* &::placeholder {
        color: #fff;
    } */

  /* &::-webkit-input-placeholder { */
  /* WebKit browsers 适配谷歌 */
  /* color: #fff;
    } */

  /* &:-moz-placeholder { */
  /* Mozilla Firefox 4 to 18 适配火狐 */
  /* color: #fff;
    } */

  /* &::-moz-placeholder { */
  /* Mozilla Firefox 19+ 适配火狐 */
  /* color: #fff;
    } */

  /* &:-ms-input-placeholder { */
  /* Internet Explorer 10+  适配ie*/
  /* color: #fff;
    } */
}

.containerSeven .content button {
  width: 2.0833rem;
  height: 0.25rem;
  border: 1px solid #ff6a00;
  color: #ff6a00;
  background-color: transparent;
  border-radius: 0.0208rem;
  font-size: 0.0833rem;
  font-family: MicrosoftYaHei;

  line-height: 0.125rem;
  position: absolute;
  bottom: 0.15rem;
  left: 50%;
  transform: translateX(-50%);
}
.containerSeven .content button:hover {
  border: none;
  background: #fd7d10 linear-gradient(270deg, #ff8c1f 0%, #ff6a00 100%);
  color: #ffffff;
}

.tips {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0.01rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 0.2rem;
  box-sizing: border-box;
  background: white;
}
.tips span {
  height: 0.0929rem;
  line-height: 0.0969rem;
  background-color: #bfbfbf;
  border-radius: 0.0521rem;
  color: white;
  padding: 0.0104rem 0.0417rem;
  font-size: 0.0625rem;
  margin: 0 0.05rem;
}

.bannBox {
  background-size: cover !important;
}

.twos /deep/ .el-carousel__container {
  height: 2.4115rem;
}

@media screen and (max-width: 767px) {
  .el-carousel__item {
    pointer-events: none;
  }
  .fours .swipers {
    width: 80%;
    background: #ffffff;
    box-shadow: 0px 0.0104rem 0.1458rem 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.0625rem;
    margin: 0 auto;
  }
  .threes .fours .swipers {
    background: red;
    height: 400px;
  }
  .threes .swipers {
    padding: 10px 0;
  }
  .idots {
    width: auto;
  }
  .idots li {
    width: 10px;
    height: 10px;
    background: #d8d8d8;
    border-radius: 50%;
    margin-right: 10px;
  }
  .container {
    height: auto;
    padding: 0 15px;
  }

  .bannBox {
    margin-top: 60px !important;
    min-height: 6rem !important;
    background-size: cover !important;
  }

  .bannBox .txt {
    left: 15px;
    width: 50%;
    margin-top: -0.55rem;
  }

  .bannBox .txt h3 {
    font-size: 18px;
    line-height: normal;
  }

  .bannBox .txt .h5,
  .bannBox .txt button p span {
    font-size: 14px !important;
    line-height: normal;
  }

  .twos,
  .fours {
    height: auto;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .twos .title,
  .threes .title,
  .fours .title {
    font-size: 24px;
    line-height: normal;
  }

  .twos .el-carousel .box,
  .cont {
    flex-direction: column;
    height: auto !important;
    padding: 10px 0;
  }

  .cont {
    width: 80%;
  }

  .fours {
    height: auto !important;
  }

  .fours .cont .r {
    width: 100% !important;
  }

  .fours .cont .r span {
    line-height: normal !important;
    font-size: 14px !important;
    width: auto;
  }

  .fours .cont .r li img {
    width: 20px !important;
    height: 20px !important;
  }

  .fours .cont .r li:first-child img {
    width: 30px !important;
    height: 30px !important;
  }
  .twos .content {
    width: 80%;
    margin: 0 auto;
    height: auto;
  }

  .twos .el-carousel {
    height: auto;
    width: 100%;
  }

  .twos .el-carousel .img {
    width: 90%;
    height: auto;
    margin: 15px auto;
  }
  .twos .el-carousel .box .text {
    width: 100% !important;
    height: auto !important;
    padding: 10px;
    box-sizing: border-box;
  }

  .twos .el-carousel .box .text img {
    width: 25px !important;
    height: 25px !important;
  }

  .twos .el-carousel .box .text span {
    line-height: normal !important;
    margin-top: 0.32rem !important;
  }
  .twos .el-carousel .box .text .r-top span {
    font-size: 14px !important;
  }
  .ziBox span {
    font-size: 14px !important;
  }
  .ziBox {
    width: auto !important;
  }

  .twos .allPrice {
    width: 100% !important;
    margin-top: 0.5rem;
  }

  .twos .allPrice > div {
    height: auto !important;
    width: 50%;
  }

  .twos .allPrice .second {
    font-size: 12px;
    line-height: normal;
    font-weight: 600 !important;
  }

  /* .twos /deep/ .el-carousel__container {
  height: 500p;
} */

  .cTitle {
    width: 70px !important;
    text-align: left;
  }

  .cCon {
    text-align: left !important;
    width: auto !important;
    flex: 1;
  }

  .allPrice {
    width: 100% !important;
  }

  .allPrice p span {
    font-size: 12px !important;
  }

  .allPrice .first {
    font-size: 16px !important;
    width: 100% !important;
    line-height: normal !important;
    height: auto !important;
  }

  .allPrice .second {
    font-size: 12px !important;
    width: 100% !important;
    line-height: normal !important;
  }

  .allPrice .first {
    line-height: normal !important;
    font-size: 20px !important;
  }

  .fours .price-bot,
  .fours .price-top,
  .fours .allPrice .first,
  .fours .allPrice .second {
    width: auto !important;
    height: auto !important;
    line-height: normal !important;
  }
  .foul {
    padding-left: 0.5rem;
  }
  .titl {
    font-size: 0.5rem !important;
    margin-left: 0.4rem;
  }
  .sev {
    margin-top: 10rem !important;
  }
  .fours ul li ul li:nth-child(3) {
    align-items: flex-start !important;
    margin-top: 0.5rem !important;
  }
  .fours ul li ul li:nth-child(5) {
    font-weight: bold !important;
  }
  .fours ul li ul li:nth-child(3) ul li {
    margin-bottom: 0.2rem !important;
    line-height: 23px;
    margin-top: -0.2rem;
  }
  .fours ul li ul li:nth-child(3) .nu {
    margin-top: 0.12rem !important;
  }
  .fours ul .xz {
    width: 152% !important;
    margin-left: -51px !important;
    height: 3.4rem;
    display: block !important;
  }
  .fours p,
  li {
    color: #000 !important;
  }

  .fours ul .xz .pop {
    width: 2.0781rem !important;
    height: 3.7396rem !important;
    transform: translateX(-50%);
    left: 4.5rem;
    top: 245% !important;
    position: absolute !important;
  }
  .fours ul .xz .pom {
    width: 2.0781rem !important;
    height: 3.7396rem !important;
    transform: translateX(-50%);
    left: 4rem;
    top: 274% !important;
    position: absolute !important;
  }
  .fours ul .xz .pom .imgbox {
    width: 78% !important;
    height: 89% !important;
    left: 0.05rem !important;
    top: 0.07rem !important;
    border-radius: 0.1821rem !important;
  }
  .fours ul .xz .pop .imgbox {
    width: 78% !important;
    height: 88% !important;
    left: 0.05rem !important;
    top: 0.1rem !important;
    border-radius: 0.1821rem !important;
  }
  .fours ul .xz ul li img {
    width: 0.7rem !important;
    height: 0.7rem !important;
  }
  .fours ul .xz ul li .loglo {
    width: 1.3rem !important;
    height: 1.3rem !important;
    margin-left: 0.1rem;
  }
  .foul li:not(:first-child) span {
    font-size: 0.4rem !important;
  }
  .foul li:not(:first-child) p {
    font-size: 14px !important;
  }
  .foul li:nth-child(3) span {
    margin-top: 0.1rem !important;
  }
  .foul li:nth-child(5) div span {
    font-size: 0.6rem !important;
  }
  /* .fours ul .xz ul{
 margin-top: -0.02rem !important;
} */
  .fours ul .xz:nth-child(2) {
    margin-top: 9.5rem;
  }
  .tlm {
    margin-top: 0 !important;
  }
  .fours ul li:first-child {
    margin-bottom: 0.5rem;
    margin-right: 0.3rem;
  }
  .fours ul li:first-child div {
    margin-left: 0rem !important;
  }
  .fours ul li:nth-child(2) .pon {
    width: 2.0781rem !important;
    height: 3.7396rem !important;
    transform: translateX(-50%);
    top: 23%;
    margin-left: 4.5rem !important;
  }
  .fours ul .xz .pon .imgbox {
    width: 78% !important;
    height: 88% !important;
    left: 0.05rem !important;
    top: 0.1rem !important;
    border-radius: 0.1821rem !important;
  }
  .fours ul .xz:nth-child(2) li:nth-child(2) {
    margin-right: 0.6rem !important;
  }

  .fours ul .xz:nth-child(2) > ul :nth-child(3) img {
    margin-top: 0rem !important;
  }
  .fours ul .xz:nth-child(3) > ul :nth-child(3) span {
    margin-bottom: 0.8rem !important;
  }
  .fours ul .xz:nth-child(3) > ul :nth-child(3) img {
    margin-top: 0rem !important;
  }

  .fours ul > li > ul > li > ul > li p {
    width: 6rem !important;
  }
  .fours ul > li > ul > li > ul > li {
    width: 5rem !important;
  }
  .fours ul .xz:nth-child(3) {
    position: relative;
    margin-top: 9.5rem;
  }
  .fours ul li ul li:nth-child(5) {
    margin-top: 0.5rem !important;
  }

  .containerSeven {
    margin-top: 9rem;
  }
  .containerSeven .title {
    font-size: 16px !important;
    line-height: normal !important;
  }

  .containerSeven .txt {
    font-size: 14px !important;
    line-height: normal !important;
  }

  /* .el-form>div{
    display: block!important;
  } */
  .containerSeven .content {
    width: 80%;
    margin: 0 auto;
  }

  .containerSeven /deep/ .el-form-item__content {
    flex: 1;
  }

  .containerSeven /deep/ .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .containerSeven /deep/ .el-input__inner {
    width: 100%;
    padding: 10px;
    height: 30px;
    font-size: 14px !important;
  }

  .containerSeven {
    padding: 15px 0 !important;
  }

  .ipt {
    width: 100%;
    height: auto !important;
  }

  /deep/ .el-form-item__label {
    width: 20px;
  }

  .tips span {
    font-size: 12px !important;
    line-height: normal !important;
    height: auto !important;
  }

  /deep/ .textArea,
  /deep/ .formItem-last {
    width: 100%;
  }

  .tips {
    bottom: 14px;
    left: 10px;
  }

  .containerSeven .content button {
    padding: 5px 15px;
    height: auto !important;
    line-height: normal !important;
    position: relative;
    left: auto;
    bottom: auto;
    transform: translateX(0%);
    margin: 15px 0 0 0;
    font-size: 14px !important;
  }

  /* .twos,.threes{
    display: none;
  } */

  .threes .swipers {
    width: 80% !important;
    min-height: 8.8229rem;
    margin-bottom: 10px;
  }

  .threes /deep/ .el-carousel {
    height: 4.8229rem;
  }

  .threes .swipers .right {
    right: 20%;
    top: 56%;
    transform: translateX(-50%);
  }

  .threes .swipers .left {
    width: 100% !important;
    position: relative !important;
    transform: initial;
    left: auto !important;
    top: auto !important;
    padding-left: 1rem;
    padding: 10px 10px 10px 24px;
    box-sizing: border-box;
  }

  .t-t-1 {
    width: 60px !important;
    height: 60px !important;
  }

  .t-t-2 {
    font-size: 14px !important;
    line-height: normal !important;
    height: auto !important;
  }

  .t-t-3 {
    font-size: 12px !important;
    height: 0.3rem !important;
    line-height: 100% !important;
  }

  .contents h4 {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .cBox {
    width: 7.8rme;
  }

  .cBox span {
    line-height: normal !important;
    height: auto !important;
  }

  .contents div span {
    font-size: 12px !important;
    width: 100% !important;
  }

  .threes {
    padding-bottom: 0.4167rem;
    height: auto !important;
  }

  .swipers .allPrice {
    height: auto !important;
  }

  .threes .idotss {
    width: 4px;
    height: auto !important;
    background: #eeeeee;
    border-radius: 0.0625rem;
    position: absolute;
    left: 10px;
    z-index: 9;
    bottom: 30px;
    top: 30px;
  }

  .threes .idotss li {
    height: 33.3% !important;
  }

  .twos /deep/ .el-carousel__container {
    height: 13.4115rem;
  }

  .twos .wzzz {
    font-size: 15px !important;
    margin-right: 65% !important;
  }
}

.formItem-last-box {
  margin-left: 10px;
}

.myNav {
  background-color: #fff;
  height: 0.375rem;
  position: sticky;
  top: 0;
  z-index: 99;
  left: 0;
  box-sizing: border-box;
}
</style>