<template>
  <div class="all">
    <ul style="
        width: 0.3021rem;
        height: 0.9167rem;
        /* position: absolute;
        right: 0.75rem;
        bottom: 0.5rem; */
        border-radius: 0.0417rem;
      ">
      <li class="li" @mouseenter="showActiveImg(0)" @mouseleave="hideActiveImg(0)">
        <img src="../assets/slider/wx.png" alt="" />
        <div class="box maBox" v-if="showwxModal">
          <p class="txt">扫码添加</p>
          <p class="txt">您的专属顾问</p>
          <div class="imgBox"><img src="../assets/index/ewm.png" alt=""></div>
          <div class="jiantou"></div>
        </div>
      </li>
      <li></li>
      <li class="li" @mouseenter="showActiveImg(1)" @mouseleave="hideActiveImg(1)">
        <img src="../assets/slider/tel.png" alt="" />
        <div class="box telBox" v-if="showTelModal" style="">
          <p class="txt">全国服务热线</p>
          <p class="tel">400-685-5515</p>
          <div class="jiantou"></div>
        </div>
      </li>
      <li></li>
      <li class="li" @mouseenter="showActiveImg(2)" @mouseleave="hideActiveImg(2)">
        <img src="../assets/slider/letter.png" alt="" />
        <div class="box emailBox" v-if="showEmailModal" style="">
          <p class="txt">公司邮箱</p>
          <p class="email">account@shuzijz.cn</p>
          <div class="jiantou"></div>
        </div>
      </li>

    </ul>
    <div class="top" @mouseenter="showColor" @mouseleave="hideColor" style="">
      <p style="" :style="{ color: active == '1' ? '#ff7a00' : '' }" @click="gotoTop">
        <span>返回</span>
        <span>顶部</span>

      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          i: 0,
          url: require("../assets/slider/wx.png"),
          aurl: require("../assets/slider/wx-active.png"),
        },
        {
          i: 1,
          url: require("../assets/slider/tel.png"),
          aurl: require("../assets/slider/tel-active.png"),
        },
        {
          i: 2,
          url: require("../assets/slider/letter.png"),
          aurl: require("../assets/slider/letter-active.png"),
        },
      ],
      active: 0,
      showEmailModal: false,
      showTelModal: false,
      showwxModal: false
    };
  },
  methods: {
    showActiveImg(i) {
      //鼠标移入
      var lis = document.getElementsByClassName("li");
      console.log(lis, "lis");
      lis[i].firstChild.src = this.imgList[i].aurl;
      if (i == 2) {
        this.showEmailModal = true
      }
      if (i == 1) {
        this.showTelModal = true
      }
      if (i == 0) {

        this.showwxModal = true
      }
    },
    hideActiveImg(i) {
      //鼠标移开
      var lis = document.getElementsByClassName("li");
      console.log(lis, "lis");
      lis[i].firstChild.src = this.imgList[i].url;
      if (i == 2) {
        this.showEmailModal = false
      }
      if (i == 1) {
        this.showTelModal = false
      }
      if (i == 0) {
        this.showwxModal = false
      }
    },
    showColor() {
      this.active = 1;
    },
    hideColor() {
      this.active = 0
    },
    gotoTop() {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      //document.documentElement.scrollTop = 0;
      // var s = document.getElementsByClassName('myNav').nextElementSibling
      // console.log(s, 's');
      // s.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
};
</script>
<style scoped>
.all {
  position: fixed;
  /* top: 3.3rem; */
  z-index: 95;
  /* left: 9rem; */
  width: 0.3021rem;
  height: 1.3125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  right: 0.75rem;
  bottom: 0.5rem;
  border-radius: 0.0417rem;
  z-index: 95;
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .all{
    right: 0.25rem;
  }
}

ul {
  background: #ffffff;
  box-shadow: 0px 0px 0.125rem 0px rgba(0, 0, 0, 0.08);
}

ul li {
  width: 0.1458rem;
  height: 0.1458rem;
  text-align: center;
  margin: 0.07rem auto;
}

ul li img {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  cursor: pointer;
}

ul li:nth-child(2n) {
  width: 0.2396rem;
  height: 0.0052rem;
  background: #5a5a68;
  opacity: 0.08;
  cursor: pointer;

}

.li {
  position: relative;
}

.top {
  box-shadow: 0px 0px 0.125rem 0px rgba(0, 0, 0, 0.08);
  height: 0.3021rem;
  width: 0.3021rem;
  background-color: #fff;
  border-radius: 0.0417rem;
  /* position: absolute;
  bottom: 0;
  left: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top p {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.0529rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: 0.0833rem; */

}

.top p {
  font-size: .0729rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: .0833rem; */
}

.box .txt {
  font-size: .0729rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: .1042rem; */
}

.email {
  font-size: .0833rem !important;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #222222;
  /* line-height: .099rem; */
}

.tel {
  font-size: .0938rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #FF6A00;
  /* line-height: .1094rem; */
}

.maBox,
.telBox,
.emailBox {
  border-radius: .0521rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: absolute;
  right: .3042rem;
  top: -0.5521rem;
  padding: .1042rem .1042rem;
  box-sizing: border-box;
  width: 1.0052rem;
  height: 1.2083rem;
  background: #FFFFFF;
  box-shadow: 0px 0px .125rem 0px rgba(0, 0, 0, 0.08);
  white-space: nowrap; 
}

.telBox,.emailBox {
  top: -0.1521rem;
  padding: .1042rem .1042rem;
  width: 1.0521rem;
  height: .4271rem;
}



.maBox .imgBox {
  width: 0.7552rem;
  height: 0.7552rem;
}

.imgBox img {
  width: 100%;
  height: 100%;

}

.jiantou {
  width: .1042rem;
  height: .1042rem;
  background-color: #fff;
  position: absolute;
  right: -0.0521rem;
  top: 50%;
  transform: translateY(-50%);
  transform: rotate(45deg);
}

/* 手机端适应代码 */
@media screen and (max-width: 767px) {

  .all,
  .all ul,
  .all ul li,
  .top {
    width: 35px !important;
    height: auto !important;
  }
  .top {
    height: 35px !important;
  }

  .top p {
    font-size: 12px;
  }

  ul li:nth-child(2n) {
    width: 35px !important;
    height: 1px !important;
  }

  .maBox,
  .telBox,
  .emailBox{
    right: 45px !important;
    width: auto;
    height: auto;
  }

  .maBox .imgBox {
    width: 100px;
    height: 100px;
  }

  .box .txt,.tel,.email {
    font-size: 12px !important;
  }


}</style>

