import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import serviceView from '../views/service.vue'
import senceView from '../views/sence.vue'
import goodsView from '../views/goods.vue'
import aboutView from '../views/about.vue'
import technologyView from '../views/technology.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path:'/index',
    name:'index',
    component:HomeView
  },
  {
    path:'/service',
    name:'service',
    component:serviceView
  },
  {
    path:'/sence',
    name:'sence',
    component:senceView
  },
  {
    path:'/technology',
    name:'technology',
    component:technologyView
  },
  {
    path:'/goods',
    name:'goods',
    component:goodsView
  },
  {
    path:'/about',
    name:'about',
    component:aboutView
  }
]
// 在重写push|replace方法时，先把VueRouter原型对象的push|replace保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 参数1：告诉原来的push | replace方法，你往那里跳转（传递那些参数）
// 参数2：成功回调
// 参数3：失败回调
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => { }, () => { })
    }
}

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
