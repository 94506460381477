<template>
  <div>
    <div ref="myNav" class="myNav">
      <navView @gotosection="gotobox" :suoyin="suoyin" />
    </div>
   
    <div class="ones bannBox" :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }">
      <div class="txt">
        <h3 id="hh">{{ bannerTitle }} </h3>
        <!-- <p class="h5">{{ bannerRemark }}</p> -->
		<p class="h5">&nbsp</p>
        <button @click="showForm">
          <p><span>立即咨询 </span></p>
        </button>
      </div>
    </div>
    <sliderView />
    <!-- 第二部分 -->
    <section class="twos">
      <div class="twosBox">
        <!-- 没有 -->
        <img :src="list1[0].image" alt="" class="pc"/>
        <div class="right">
          <p class="company" style="color: black; font-weight: bold">
            {{ name1 }}
          </p>
          <p class="txt">{{ list1[0].content }}</p>
        </div>
          <img :src="list1[0].image" alt="" class="mobile"/>

      </div>
    </section>
    <!-- 第三部分 -->
    <section class="threes">
      <p class="title" style="color: black; font-weight: bold">{{ name2 }}</p>
      <ul>
        <li v-for="(item, index) in list2" :key="index">
          <div class="top"><img :src="item.image" alt="" /></div>
          <div class="bot">
            <p class="bold">{{ item.title }}</p>
            <p class="txt">{{ item.content }}</p>
          </div>
        </li>
       
      </ul>
    </section>
    <!-- 第四部分 -->
    <section class="fours pc" style="width: 6.25rem; margin: 0 auto; padding-top: 0.4167rem">
      <p class="title" style="
          font-size: 0.2083rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: black;
          line-height: 0.2917rem;
          margin-bottom: 0.2604rem;
        ">
        {{ name3 }}
      </p>
      <p class="time" style="
          height: 0.1146rem;
          font-size: 0.0833rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 0.1146rem;
          text-align: right;
          margin-bottom: 0.1042rem;
        ">
        【本次更新时间:2023年6月6日】
      </p>
      <div class="content" style="
          font-size:16px;
          width: 6.25rem;
          height: 5.2396rem;
          overflow-y: scroll;
          text-align: left;
        " v-html="list3[0].content"></div>
    </section>

    <section class="fours mobile" style="width: 7.2917rem; margin: 0 auto; padding: 0.4167rem 0;height: 500px;">
      <p class="title" style="
          font-size: 0.2083rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: black;
          line-height: 30px;
          margin-bottom: 0.2604rem;
        ">
        {{ name3 }}
      </p>
      <p class="time" style="
          font-size: 0.0833rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 20px;
          text-align: right;
          margin-bottom: 0.1042rem;
        ">
        【本次更新时间:2023年6月6日】
      </p>
      <div class="content" style="
          font-size: 12px;
          padding: 10px 0;
          height: 500px;
          overflow-y: scroll;
          text-align: left;
        " v-html="list3[0].content"></div>
    </section>
    <!-- 底部 -->
    <bottomView @gotosection="gotobox" />
    <!-- 立即咨询表单 -->
    <formView />
  </div>
</template>

<script>
import { about } from "@/api/about";
import { mapState, mapActions, mapMutations } from "vuex";
import swiperView from "../components/swiper.vue";
import sliderView from "../components/slider.vue";
import navView from "../components/nav.vue";
import bottomView from "../components/bottom.vue";
import formView from "../components/form.vue";
import axios from "axios";
export default {
  components: {
    swiperView,
    sliderView,
    navView,
    bottomView,
    formView,
  },
  data() {
    return {
      index: 0,
      show: false,
      suoyin: 0,
      name1: "",
      list1: [],
      name2: "",
      list2: [],
      name3: "",
      list3: [],
      content: "1111",
      bannerTitle: "",
      bannerImage: "",
      bannerRemark: "",
    };
  },
  computed: {
    ...mapState(["navList", "active"]),
  },
  created() {
    this.getAboutList();

    this.getBannerList();
    // console.log(this.$route.path.slice(1),'path');
    this.suoyin = sessionStorage.getItem("i");
    this.index = this.$route.params.index;
    this.show = true;
    // this.d=this.index
    console.log(this.$route, "this.$route.params.i");
  },
  mounted() {
    if (this.$route.params.i) {
      setTimeout(() => {
        var s = document.getElementsByTagName("section");
        let m = this.$route.params.i;
        s[m].scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    }
  },
  beforeDestroy() {
    // var s =document.getElementsByTagName('section')
    //     s[0].scrollIntoView({ behavior: "smooth",block: "start", });
    // var s=document.getElementsByClassName('container')[0].nextElementSibling
    //  console.log(s,'s');
    //  s.scrollIntoView({ behavior: "smooth",block: "start"});
  },
  methods: {
    getBannerList() {
      axios.get("https://admin.shuzijz.cn/api/index/banner?id=6").then((res) => {
        console.log(res, "banner的数据");
        this.bannerTitle = res.data.data.title;
        this.bannerRemark = res.data.data.remark;
        this.bannerImage = res.data.data.image;
      });
    },
    ...mapActions(["showDialog"]),
    showForm() {
      this.showDialog(true);
    },
    async getAboutList() {
      const { data: res } = await about();
      console.log(res, "anout的数据");
      this.name1 = res.data.content[0].name;
      this.list1 = res.data.content[0].main;
      this.name2 = res.data.content[1].name;
      this.list2 = res.data.content[1].main;
      this.name3 = res.data.content[2].name;
      this.list3 = res.data.content[2].main;
      console.log(this.list3[0].time, "list3");
    },
    // closepdf () {
    //     this.isshowpdf = false
    //   },
    //   pdferr (errurl) {
    //     console.log(errurl)
    //   },
    //   openpdf () {
    //     this.isshowpdf = true
    //   }
    gotobox(i, b, index) {
      console.log(i, b, index, "about页的i,b,index");
      var s = document.getElementsByTagName("section");

      if (b == this.$route.path.slice(1)) {
        // s[i].scrollIntoView({ behavior: "smooth", block: "start" });
        var top=s[i].offsetTop-this.$refs.myNav.offsetHeight;
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
      } else {
        sessionStorage.setItem("i", index);
        this.$router.push({
          name: b,
          params: { i: i },
        });
      }
    },
    // handleenter() {
    //     // this.show = true
    //     this.showDialog(true);
    //   },
    //   handleleave() {
    //     // this.show = false
    //     this.showDialog(false);
    //   }
  },
};
</script>

<style scoped>
/* @media screen and (max-width:1000px){
  .ones   .txt .h5{
    font-size: 1rem !important;  
  }
 
} */


@media screen and (max-width: 900px) {
  #hh{
    font-size: 18px !important;
  }
  .myNav {
    background-color: #fff;
    height: auto !important;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
  }
  .mobile{
    display: block;

  }
  .pc{
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .myNav {
    background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
  }
  .mobile{
    display: none;
  }
  .pc{
    display: block;
  }
}

/* 第一部分 */
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 1.875rem;
  position: absolute;
}

.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: .2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: .1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: .3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: .078125rem
    /* 15/192 */
  ;
  min-height: 0.1042rem;
}

.bannBox .txt button {
  /* position: absolute; */
  border: 1px solid #FF6A00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius: 5px;
  padding: 0.052083rem
    /* 10/192 */
    0.1875rem
    /* 36/192 */
  ;
  margin-top: .3125rem;
}

.bannBox .txt button:hover {
  background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
  color: white;
}

/* 立即咨询按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }

  to {
    top: 1.6302rem;
    opacity: 1;
  }
}

/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }

  to {
    top: 0.7292rem;
    opacity: 1;
  }
}

@keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }

  to {
    top: 0.7292rem;
    opacity: 1;
  }
}

/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    opacity: 1;
  }
}

@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }

  to {
    top: 1.1719rem;
    opacity: 1;
  }
}

.twos .txt button p {
  margin: 0;
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 0 0.0521rem;
  /* align-items: center; */
}

.ones .txt button p img {
  width: 0.0833rem;
  height: 0.0833rem;
}

/* 第二部分 */
.twos {
  margin: 0 auto;
  /* height: 2.9115rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twosBox {
  width: 7.2917rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twos img {
  width: 4.3125rem;
  height: 2.6615rem;
}

.right {}

.company {
  font-size: 0.2083rem;
  /* line-height: 0.2917rem; */
  margin-bottom: 0.1563rem;
  text-align: left;
  font-weight: 600;
}

.right .txt {
  color: #666;
  font-size: 0.0938rem;
  font-size: 0.0938rem;
  /* line-height: .1667rem; */
  width: 3.0729rem;
  height: 0.6667rem;
  /* line-height: 0.1667rem; */
  text-align: left;
}

.threes {
  height: 3.9896rem;
  background: #f9fafa;
  padding-top: 0.4167rem;
  box-sizing: border-box;
}

.threes .title {
  font-size: 0.2083rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 0.2917rem;
  margin-bottom: 0.2604rem;
}

.threes ul {
  width: 6.25rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.threes ul li {
  width: 2rem;
  height: 2.6042rem;
  background: #ffffff;
  cursor: pointer;
}

.threes ul li:hover {
  color: white;
  background: linear-gradient(133deg, #ed6d00 0%, #f39800 100%),
    linear-gradient(315deg, #ff8c1f 0%, #ff6a00 100%);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.threes ul li:hover img {
  transform: scale(1.2);
}

.threes ul li:hover .bot p {
  color: white;
}

.threes ul li .top {
  background-image: url(../assets/about/five-3-1.png);
  background-size: contain;
  width: 2rem;
  height: 1.3021rem;
  box-sizing: border-box;
}

.threes ul li .top img {
  width: 0.7292rem;
  height: 0.7292rem;
  /* line-height: 100%; */
  margin-top: 0.3281rem;
  transition: all 0.6s;
}

.threes ul li .bot {
  height: 1.3021rem;
  padding: 0.1458rem 0.125rem 0;
  box-sizing: border-box;
}

.threes ul li .bot .bold {
  font-size: 0.125rem;
  /* line-height: 0.1719rem; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #040505;
  margin-bottom: 0.099rem;
}

.threes ul li .bot .txt {
  width: 1.75rem;
  font-size: 0.0833rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: 0.1458rem; */
  text-align: left;
}

.fours {
  height: 6.4271rem;
  background: #ffffff;
}

.bannBox {
  background-size: cover !important;
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    padding: 0 15px;
  }

  .bannBox {
    min-height: 6rem !important;
    background-size: cover !important;
    margin-top: 60px;
  }

  .bannBox .txt {
    left: 15px;
    width: 50%;
    
  }

  .bannBox .txt h3,.company {
    font-size: 18px;
    line-height: normal;
  }

  .company,
  .threes .title,
  .fours .title{
    font-size: 16px!important;
    line-height: normal;
  }

  .bannBox .txt .h5,
  .bannBox .txt button p span,
  .right .txt {
    font-size: 14px !important;
    line-height: normal;
  }

  .twos img {
    width: 80%;
    height: auto
  }


  .twosBox {
    flex-direction: column;
    padding: 15px 0;
  }

  .threes ul{
    flex-direction: column;
  }

  .twosBox .right .txt,
  .threes ul li .top,
  .threes{
    width:100%;
    height: auto!important;;
  }

  .threes ul li{
    width: 100%;
    margin: 15px 0 0 0;
    padding-bottom: 15px;
  }

  .threes{
    padding: 15px 0;
  }

  .threes ul{
    width: 7.2917rem;
  }

  .threes ul li .top img{
    width: 45px;
    height: 45px;
  }

  .threes ul li .bot .bold{
    font-size: 14px;
  }

  .threes ul li .bot .txt{
    width: 100%;
    font-size: 12px;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .threes ul li,.threes ul li .bot{
    height: auto;
  }

  .time{
    font-size: 12px !important;
    line-height: normal;
  }


}

.myNav{
  background-color: #fff;
    height: 0.375rem;
    position: sticky;
    top: 0;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
}

</style>