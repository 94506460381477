<template>
  <div>
    <div ref="myNav" class="myNav">
      <navView @gotosection="gotobox" :suoyin="suoyin" />
    </div>

    <div class="ones bannBox" :style="{ background: 'url(' + bannerImage + ') no-repeat top center' }">
      <div class="txt">
        <h3>{{ bannerTitle }} </h3>
        <p class="h5" v-if="bannerRemark!=''">{{bannerRemark}}</p>
        <button @click="showForm">
          <p><span>立即咨询 </span></p>
        </button>
      </div>
    </div>
    <!-- 侧边导航 -->
    <sliderView />
    <!-- 第二部分 -->
    <section class="box twos">
      <h4
        class="title"
        style="font-weight: bold; color: black; font-weight: bold"
      >
        {{ name1 }}
      </h4>
      <div class="content">
        <ul class="pc">
          <li v-for="(item, index) in list1" :key="index" >
            <img :src="item.image" alt="" />
            <p class="black">{{ item.title }}</p>
            <p class="txt">{{ item.content }}</p>
          </li>
          
        </ul>
        <ul class="mobile ul" style="height: 360px !important;">
          <li v-for="(item, index) in list1" :key="index" style="height: 180px !important;width: 50%;float: left;overflow: hidden;box-sizing: border-box;transition: none;padding: 0;margin: 0">
            <img :src="item.image" alt="" />
            <p class="black" style="padding: 0;margin: 0">{{ item.title }}</p>
            <p class="txt" style="padding: 0;margin: 0">{{ item.content }}</p>
          </li>
          </ul>
      </div>
    </section>
    <!-- 第三部分 -->
    <div class="threes">
      <img :src="img[0].image" alt="" />
    </div>
    <!-- 第四部分 -->
    <section class="box four">
      <h4
        class="title"
        style="font-weight: bold; color: black; font-weight: bold"
      >
        {{ name2 }}
      </h4>
      <!-- 卡片式轮播图 -->
      <div style="width: 80%; height: 3.8rem; margin: 0 auto">
        <swiperCardView :list2="list2" />
      </div>
    </section>
    <!-- 底部 -->
    <bottomrView @gotosection="gotobox" />
    <formView />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import swiperView from "../components/swiper.vue";
import bottomrView from "../components/bottom.vue";
import navView from "../components/nav.vue";
import swiperCardView from "../components/swiperCard.vue";
import sliderView from "../components/slider.vue";
import formView from "../components/form.vue";
import { goods } from "@/api/goods";
import axios from "axios";
export default {
  data() {
    return {
      bannerImage: "",
      bannerRemark: "",
      bannerTitle: "",
      arr: ["one", "two", "three", "four", "five"],
      default: {
        //默认值
        classArray: ["one", "two", "three", "four", "five"],
        leftSlider: true,
      },
      bannerWrap: null,
      time: null,
      lisArray: null, //卡片的集合
      classlist: null,
      leftBtn: null,
      rightBtn: null,

      index: 0,
      // swiperList:[
      //   {
      //     id:0,
      //     url:'https://img0.baidu.com/it/u=188211079,3502845261&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=702'
      //   },
      //   {
      //     id:1,
      //     url:'https://img1.baidu.com/it/u=2989715183,1366455786&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=768'
      //   },
      //   {
      //     id:2,
      //     url:'https://bpic.51yuansu.com/pic3/cover/03/16/91/5b5bb385c62a8_610.jpg'
      //   },
      //   {
      //     id:3,
      //     url:'https://img2.baidu.com/it/u=2780511291,1959442016&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=684'
      //   },
      //   {
      //     id:4,
      //     url:'https://bpic.51yuansu.com/pic3/cover/01/69/71/595f636e68d74_610.jpg'
      //   }
      // ],
      suoyin: 0,
      list1: [],
      name1: "",
      list2: [],
      name2: "",
      img: "",
    };
  },
  components: {
    swiperView,
    bottomrView,
    navView,
    swiperCardView,
    sliderView,
    formView,
  },
  computed: {
    ...mapState(["navList"]),
  },
  created() {
    // console.log(this.$route,'this.$route.name');

    this.getBannerList();
    this.getGoodsList();
    this.suoyin = sessionStorage.getItem("i");
  },

  mounted() {
    // this.constructor();
    if (this.$route.params.i >= 0) {

      setTimeout(() => {
        var s = document.getElementsByTagName("section");
        let m = this.$route.params.i;
        s[m].scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    } else {
      var s =
        document.getElementsByClassName("container")[0].nextElementSibling;
      console.log(s, "s");
      s.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  methods: {
    getBannerList() {
      axios.get("https://admin.shuzijz.cn/api/index/banner?id=5").then((res) => {
        console.log(res, "banner的数据");
        this.bannerTitle = res.data.data.title;
        this.bannerRemark = res.data.data.remark;
        this.bannerImage = res.data.data.image;
      });
    },
    ...mapActions(["showDialog"]),
    showForm() {
      this.showDialog(true);
    },
    async getGoodsList() {
      const { data: res } = await goods();
      console.log(res, "goods的数据");
      if (res.code == 1) {
        this.list1 = res.data.content[0].main.slice(0, -1);
        this.img = res.data.content[0].main.slice(4);

        this.name1 = res.data.content[0].name;
        this.name2 = res.data.content[1].name;
        this.list2 = res.data.content[1].main[0].image;
        console.log(this.list2, "list2goods");
      }
    },
    gotobox(i, b, index) {
      // console.log(this.$route.name,'this.$route.name');
      console.log(i, b, index, "service的i,b,index");
      var s = document.getElementsByTagName("section");

      if (this.$route.name == b) {
        // s[i].scrollIntoView({ behavior: "smooth", block: "start" });
        var top=s[i].offsetTop-this.$refs.myNav.offsetHeight;
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
      } else {
        sessionStorage.setItem("i", index);
        this.$router.push({
          name: b,
          params: { i: i },
        });
      }
    },
  },
};
</script>

<style scoped>

  @media screen and (max-width: 900px) {

    .twos .content{
      width: 8.95rem !important;
    }
    .myNav{
  background-color: #fff;
    height: auto !important;
    z-index: 99;
    left: 0;
    box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: #fff;
    margin-top: 0 !important;
    width: 100%;
}
      .mobile{
          display: block !important;
          margin: auto !important
          
          
          
          
          ;
      }
      .pc{
          display: none !important;
      }
  }

  @media screen and (min-width: 900px) {
    .myNav {
      background-color: #fff;
      height: 0.375rem;
      position: sticky;
      top: 0;
      z-index: 99;
      left: 0;
      box-sizing: border-box;
    }
    .mobile{
      display: none !important;
    }
    .pc{
      display: block;
    }
  }


/* 第一部分 */
.bannBox {
  height: 3.125rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
}

.bannBox .txt {
  /* position: absolute;
  left: 1.875rem;
  z-index: 21; */
  width: 4.1667rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 1.875rem;
  position: absolute;
}
.bannBox .txt h3 {
  /* position: absolute; */
  /* top: .7292rem; */
  opacity: 0;
  color: black;
  font-size: .2396rem;
  /* line-height: .1302rem; */
  /* font-weight: bold; */
  animation-name: swiperH3;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* animation-delay: .5s;   页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* 执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
}

.bannBox .txt .h5 {
  /* position: absolute; */
  font-size: .1042rem;
  /* line-height: .1458rem; */
  color: rgb(34, 34, 34);
  opacity: 0;
  animation-name: swiperH5;
  /*执行动画的名字 */
  animation-duration: .3s;
  /* */
  /*  animation-delay: .3s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /* ; 执行速率 */
  animation-iteration-count: 1;
  /* 动画播放次数 */
  animation-fill-mode: forwards;
  margin-top: .078125rem /* 15/192 */;
}
.content ul li{
  transition: all 0.3s !important; /* 添加过渡效果 */
}
.content ul li:hover{
  box-shadow: 0 0.33333rem 1.25rem #00000026; /* 鼠标移入时添加边框 */
}
.content ul li:hover .black{
  color: #FFA500 !important;
}
.content ul li:hover img{
  transform: scale(1.2); /* 鼠标移入时放大 10% */
}
.bannBox .txt button {
  /* position: absolute; */
  border: 1px solid #FF6A00;
  opacity: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 0.083rem;
  color: #FF6A00;
  animation-name: swiperBtn;
  /* 执行动画的名字 */
  animation-duration: .3s;
  /* */
  /* animation-delay: .7s;页面加载完成后动画执行前的延迟时间 */
  animation-timing-function: ease-in;
  /*执行速率 */
  animation-iteration-count: 1;
  /*动画播放次数 */
  animation-fill-mode: forwards;
  border-radius:5px;
  padding: 0.052083rem /* 10/192 */ 0.1875rem /* 36/192 */;
  margin-top: .3125rem;
}
.bannBox .txt button:hover {
  background-image: linear-gradient(to right, #ff6a00, #ff8c1f);
  color: white;
}
/* 立即咨询按钮 */
@-webkit-keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }
  to {
    top: 1.6302rem;
    opacity: 1;
  }
}
@keyframes swiperBtn {
  from {
    top: 1.9302rem;
    opacity: 0.5;
  }
  to {
    top: 1.6302rem;
    opacity: 1;
  }
}
/* h3标签 */
@-webkit-keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }
  to {
    top: 0.7292rem;
    opacity: 1;
  }
}
@keyframes swiperH3 {
  from {
    top: 0.9292rem;
    opacity: 0.5;
  }
  to {
    top: 0.7292rem;
    opacity: 1;
  }
}
/* h5标签 */
@-webkit-keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }
  to {
    top: 1.1719rem;
    opacity: 1;
  }
}
@keyframes swiperH5 {
  from {
    top: 1.3302rem;
    opacity: 0.5;
  }
  to {
    top: 1.1719rem;
    opacity: 1;
  }
}
.twos .txt button p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 0.0521rem;
  align-items: center;
}
.bannBox .txt button p img {
  width: 0.0833rem;
  height: 0.0833rem;
}
/*  第二部分*/
.container {
  box-sizing: border-box;
}
.twos {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-sizing: border-box;
  padding-top: 0.7167rem;
}
.twos .title {
  font-size: 0.1979rem;
  line-height: 0.125rem;
  /* color: #313233 ; */
  font-weight: Medium;
  margin: 0;
  margin-bottom: 0.2813rem;
}
.twos .content {
  box-sizing: border-box;
  width: 7.2917rem;
  height: 2.1406rem;
  margin: 0 auto;
  padding-top: 0.1042rem;
}
.twos .content ul {
  padding: 0;
  padding: 0 0.2396rem;
  height: 1.9792rem;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}
.twos .content ul li {
  /* border: 2px solid red; */
  height: 2rem;
  width: 1.5625rem;
  transition: transform 0.5s linear;
  padding-top: 0.2rem;
  cursor: pointer;
}

.twos .content ul li img {
  width: 0.4792rem;
  height: 0.4792rem;
  margin-bottom: 0.2604rem;
  transition: transform 0.3s;
}
@keyframes top {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.twos .content ul li .black {
  font-size: 0.1146rem;
  /* line-height: 0.1146rem; */
  color: #000;
  /* font-size: Medium; */
  font-size: 0.1146rem;
  margin-bottom: 0.2669rem;
}
.twos .content ul li .txt {
  width: 1.1927rem;
  height: 0.5729rem;
  font-size: 0.0938rem;
  color: #8a8c8c;
  /* text-align: left; */
  line-height: 0.1146rem;
  padding: 0 0.17rem;
  text-align: center;
  /* letter-spacing: 0.0204rem; */
}
/* 第三部分 */
.threes {
  width: 5.8229rem;
  height: 3.3594rem;
  /* background-size: contain; */
  margin: 0.2813rem auto;
  /* background-image: url(../assets/goods/goods-5.png); */
}
.threes img {
  width: 100%;
  height: 100%;
}
/* 第四部分 */
.four {
  margin-bottom: 0.5208rem;
  padding-top: 0.7rem;
  /* margin-top: .7rem; */
}
.four .title {
  margin: 0;
  font-size: 0.1979rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  /* color: #313233; */
  line-height: 0.125rem;
  margin-bottom: 0.4167rem;
}

.bannBox {
  background-size: cover !important;
}

@media screen and (max-width: 767px) {

  .container  {
    height: auto;
    padding: 0 15px;
  }

  .bannBox {
    min-height: 6rem !important;
    background-size: cover !important;
    margin-top: 60px;
  }

  .bannBox .txt {
    left: 15px;
    width: 50%;
   
  }

  .bannBox .txt h3{
    font-size: 18px;
    line-height: normal;
  }

  .bannBox .txt .h5,
  .bannBox .txt button p span,
  .twos .content ul li .black{
    font-size: 14px!important;
    line-height: normal;
  }

  .twos .title,
  .four .title{
    font-size: 16px!important;
    line-height: normal;
  }

  .twos .content ul{
    flex-direction: column;
  }

  .twos .content ul li,
  .twos .content ul,
  .twos .content ul li .txt{
    width: 95%;
    height: auto!important;
    line-height: normal;
  }

  .twos .content ul{
    padding: 0!important;
  }
  .twos .content{
    height: auto!important;;
  }

  .twos .content ul li img{
    width: 45px;
    height: 45px;
  }

  .twos .content ul li .txt{
    font-size: 12px!important;
    line-height: normal;
  }

  .threes{
    width: 7.2917rem;
  }
  
  .twos .content ul li{
    box-shadow: none;
  }
  .content ul li:hover img{
    transform: none;
  }
  .content ul li:hover .black{
    color: #000 !important;
  }
}




</style>