<template>
    <div class="swiper" >

        <swiper  ref="mySwiper" :options="swiperOption">
          <swiper-slide v-for="(item, index) in swiperList"
            :key="index">
            <img :src="item" />
          </swiper-slide>
   
        </swiper>
     
     
    <!-- 分页器 -->
   <!-- <div class="swiper-pagination" id="pagination" slot="pagination"></div> -->
    
      <!-- 左右箭头 -->
    <div class="swiper-button-next" slot="button-next" @click="next"></div>
    <div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
   
    </div>
  </template>
  <script>
  import { Swiper , SwiperSlide , Navigation , Pagination} from 'vue-awesome-swiper'
  import '../../node_modules/swiper/css/swiper.min.css'
  Swiper.use([Navigation, Pagination])
  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    props:['swiperList','list2'],
    data () {
      return {
 
        swiperOption: {
            // pagination:'.swiper-pagination',
            navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
          // 衔接滑动
          loop: true,
          // autoplay:false,
          // 自动滑动
          autoplay: {
            delay: 1500,
            // 如果设置为true，当切换到最后一个slide时停止自动切换。
            stopOnLastSlide: true,
            // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
            disableOnInteraction: false
          },
          // 切换效果 "coverflow"（3d流）
          centeredSlides:true,
          effect: 'coverflow',
          // 设置slider容器能够同时显示的slides数量
          slidesPerView: 4,
          // 居中幻灯片。设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
          centeredSlides: true,
          // 设置为true则点击slide会过渡到这个slide。
        //   slideToClickedSlide: true,
          coverflowEffect: {
            // slide做3d旋转时Y轴的旋转角度
            rotate: 36,
            // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
            stretch:7,
            // slide的位置深度。值越大z轴距离越远，看起来越小。
            // depth: 100,
            // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
            // modifier: 1,
         
            // 是否开启slide阴影
            slideShadows: false
          },
         
          spaceBetween:10,
          // pagination: {
          // el: ".swiper-pagination",
          // clickable: true,
          // },
        },
      }
    },
    computed: {
      mySwiper () {
        // mySwiper 是要绑定到标签中的ref属性
        return this.$refs.mySwiper.$swiper
      }
    },
    methods:{
      // enter () {
      //   this.mySwiper.autoplay.stop()
      // },
      // leave () {
      //   this.mySwiper.autoplay.start()
      // },
      prev(){
        this.mySwiper.slidePrev();
      },
      next(){
        this.mySwiper.slideNext();
      },
    }
  }
  </script>
  <style scoped >
  .swiper{
    margin: 0 auto;
    width: 100%;
    height: 100%;
  
    position: relative;
  }
  /* .swiper-button-prev{
    color: #ccc;
  }
  .swiper-button-prev:hover{
    color: #FF8C1F;
  }
  .swiper-button-next{
    color: #ccc;
  }
  .swiper-button-next:hover{
    color: #FF8C1F;
  } */
  img{
    width: 1.8333rem;
    height: auto!important;
    border-radius: .0938rem;
    /* padding: 1rem 0 ; */
    max-width: 476px;
  }
  .swiper-slide{
   
  }
  .swiper-container{
   /* padding: 2rem 0; */
  }
.swiper-pagination {
    /* width: 1.3021rem; */
    height: .23rem;
    display: flex;
    justify-content: space-around;
    padding-top: .1042rem;
    box-sizing: border-box;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
#pagination >>> .swiper-pagination-bullet {
  /* background: #fff;
  opacity: 0.8;
  height: .0729rem;
  border-radius: 0.06rem;
  width: 0.08rem;
  transition: all 0.2s;  */
  background: linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%) !important;
  border-radius: .0833rem !important;
  opacity: 0.2 !important;
  width: .0781rem !important;
  height: .0781rem !important;
  border-radius: 50% !important;
  margin: 0 2px;

}
#pagination >>>  .swiper-pagination-bullet-active{
    
      width: .4323rem !important;
height: .0781rem !important;
background:linear-gradient(270deg, #FF8C1F 0%, #FF6A00 100%) !important;
border-radius: .0833rem !important;
opacity:1 !important;
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: -60px;
    right: auto;
    background: #f5f7fa;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: -60px;
    left: auto;
    background: #f5f7fa;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
}

.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 24px;
  color: black;
}

.swiper-button-prev:hover,.swiper-button-next:hover{
  background: #FF6A00;
}
.swiper-button-prev:hover:after,.swiper-button-next:hover:after{
  color:white;
}
        
  </style>