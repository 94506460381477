<template>
       <!-- <div id="box">
          <div class="item">
            <img v-for="(item,index) in imageData.slice(11,19)" :key="index" :src="item" alt="">
        </div>
          <div class="item">
            <img v-for="(item,index) in imageData.slice(19,27)" :key="index" :src="item" alt="">
        </div>
        <div class="item" >
            <img v-for="(item,index) in imageData.slice(0,8)" :key="index" :src="item" alt="">
        </div>
        <div class="item" style="">
            <img v-for="(item,index) in imageData.slice(8,16)" :key="index" :src="item" alt="">
        </div>
    </div> -->
	<div id="box">
	      <div class="item">
	        <img v-for="(item,index) in imageData.slice(0,8)" :key="index" :src="item" alt="">
	    </div>
	      <div class="item">
	        <img v-for="(item,index) in imageData.slice(9,17)" :key="index" :src="item" alt="">
	    </div>
	    <div class="item" >
	        <img v-for="(item,index) in imageData.slice(18,26)" :key="index" :src="item" alt="">
	    </div>
	    <div class="item" style="">
	        <img v-for="(item,index) in imageData.slice(26,34)" :key="index" :src="item" alt="">
	    </div>
	    
	    
	</div>
    <!-- <section>
  <div class="img-wrapper" v-for="(item, index) in imageData" :key="index">
    <img class="img" :src="item" />
    <div class="info">
      <span class="size">helloworld</span>
    </div>
  </div>
</section> -->
  </template>
  <script>

  export default {
    name: 'Waterfall',
    props: {
      imageData: { // 瀑布流的图片数组
        type: Array,
        required: true,
        default: () => {
          return []
        }
      },
    //   columnCount: { // 瀑布流要划分的列数
    //     type: Number,
    //     default: 3
    //   },
    //   columnGap: { // 瀑布流各列之间的间隙
    //     type: Number,
    //     default: 30
    //   },
    //   totalWidth: { // 瀑布流区域的总宽度
    //     type: Number,
    //     default: 1200
    //   },
    //   backgroundColor: { // 瀑布流区域背景填充色
    //     type: String,
    //     default: '#F2F4F8'
    //   }
    },
    computed: {
      width () {
        return this.totalWidth - 2 * this.columnGap
      }
    }
  }
  </script>
  <style  scoped>
	  @media screen and (max-width: 767px) {
		  #box {
			  height: 3rem !important;
		  }
	  }
  #box {
  display: grid;
  z-index: 99;
  height: 1.5625rem;
  grid-gap: .1083rem; /*复合属性，行和列间距*/
  grid-template-columns: repeat(3, 1fr);/*设定3列，每个列高度相同*/
  grid-auto-rows: minmax(10px, auto);/*为网格中的行设置默认大小*/
  grid-column: 4;
  width: 7.2917rem;
  display: grid;
  margin: 0 auto;
}

.item img{
    width: 0.85rem;
    height: .3563rem;
    box-shadow: 0 0 .005rem  #ccc;
}

.item:nth-of-type(1){
  grid-row: 1/3;
  grid-auto-flow: row dense;
  display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 7.2917rem;
    margin-top: 10px;
}
.item:nth-of-type(2) {
    grid-row:3/5;
    margin-left: 100px;
    display: flex;
    align-items: center;
    grid-auto-flow: row dense;
    justify-content: space-evenly;
    width: 7.2917rem;
    margin-top: 10px;
}
.item:nth-of-type(3) {
    grid-row: 5/7;
    display: flex;
    align-items: center;
    grid-auto-flow: row dense;
    justify-content: space-evenly;
    width: 7.2917rem;
    margin-top: 10px;
}
.item:nth-of-type(4) {
    grid-row: 7/9;
    margin-left: 100px;
    grid-auto-flow: row dense;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 7.2917rem;
    margin-top: 10px;
}
/* 其他项，逐个添加 */
section ::after{ 
    /* // 用于最后一行最后的位置显示空白 */
    content: '';
    flex-grow: 99999;
    /* // 放到最大 */
  }
section {
  width: 7.2917rem;
  height: 1.9844rem;
  display: flex; 
  /* // 这里用到flex布局 */
  flex-wrap: wrap;
   /* // flex中换行的属性 */
  padding: 15px 5px;


}
.img-wrapper {
    flex-grow: 1;
     /* // 根据比例计算每个图片多长等分剩余空间（好难解释，下面用图解释） */
    margin: 5px;
    position: relative;
    overflow: hidden; 
    /* // 把多余的部分隐藏 */
   
 
  }
  .img-wrapper .img {
      height: 170px;
      min-width: 100%; 
      /* // 要让左右的图片横向是充满他父级标签的宽度 */
      object-fit: cover; 
      /* // 让图片不要压缩 */
    }
    .info{ 
      /* // 选中时底部提示框 */
        position: absolute; 
        bottom: 4px;
        color: #ffffff;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.3);
        line-height: 44px;
        height: 0px;
        
    }.info :hover .info{ 
      /* // 选中时底部弹出提示框动画 */
        height: 44px;
        transition: all 0.5s;
    }

    @media screen and (max-width: 767px) {
      .item img{
        width: 2rem;
		  height: auto;
      }
    }
  </style>